// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AutopilotResponse,
  IShopStore, PurchasesResponse, ShopResponse,
} from '../../../types';
import {
  finishAutopilot, getAutopilot, getPurchases, getShopItems, startAutopilot,
} from './asyncActions';

// Types

const initialState: IShopStore = {
  shopItems: null,
  purchases: null,
  autopilot: null,
  error: null,
  loading: true,
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getShopItems.fulfilled,
        (state, action: PayloadAction<ShopResponse>) => {
          state.shopItems = action.payload;
          state.error = null;
          state.loading = false;
        },
      )
      .addCase(
        getPurchases.fulfilled,
        (state, action: PayloadAction<PurchasesResponse>) => {
          state.purchases = action.payload;
          state.error = null;
          state.loading = false;
        },
      )
      .addCase(
        getAutopilot.fulfilled,
        (state, action: PayloadAction<AutopilotResponse>) => {
          if (action.payload?.autopilot) {
            state.autopilot = action.payload?.autopilot;
          } else {
            state.autopilot = null;
          }
          state.error = null;
          state.loading = false;
        },
      )
      .addCase(
        startAutopilot.fulfilled,
        (state, action: PayloadAction<AutopilotResponse>) => {
          if (action.payload?.autopilot) {
            state.autopilot = action.payload?.autopilot;
          }
          state.error = null;
          state.loading = false;
        },
      )
      .addCase(
        finishAutopilot.fulfilled,
        (state, action: PayloadAction<AutopilotResponse>) => {
          if (action.payload?.autopilot) {
            state.autopilot = action.payload?.autopilot;
          }
          state.error = null;
          state.loading = false;
        },
      );
  },
});

export const ShopReducer = shopSlice.reducer;
