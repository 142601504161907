function MachineScreenBgSVG() {
  return (
    <svg width="374" height="381" xmlns="http://www.w3.org/2000/svg" className="mt-[-8px]">
      <g className="layer">
        <title>Layer 1</title>
        <g id="svg_11">
          <svg height="260" id="svg_59" viewBox="0 0 337 260" width="337" x="20" xmlns="http://www.w3.org/2000/svg" y="54">
            <g clipPath="url(#svg_60)" id="svg_63">
              <path d="m266.4,259.15l-195.9,0c-38.66,0 -69.97,-31.31 -69.97,-69.96l0,-118.36c0,-38.65 31.31,-69.96 69.97,-69.96l195.9,0c38.65,0 69.96,31.31 69.96,69.96l0,118.36c0,38.65 -31.31,69.96 -69.96,69.96z" fill="url(#svg_62)" id="svg_64" opacity="0.64" />
            </g>
            <defs>
              <radialGradient cx="0" cy="0" gradientTransform="translate(168.059 105.536) scale(179.977 128.636)" gradientUnits="userSpaceOnUse" id="svg_62" r="1">
                <stop offset="0.06" stopColor="white" stopOpacity="0.8" />
                <stop offset="0.14" stopColor="#F8F8FB" stopOpacity="0.82" />
                <stop offset="0.27" stopColor="#E3E3EE" stopOpacity="0.84" />
                <stop offset="0.41" stopColor="#C2C2DA" stopOpacity="0.87" />
                <stop offset="0.58" stopColor="#9494BE" stopOpacity="0.91" />
                <stop offset="0.76" stopColor="#59599A" stopOpacity="0.95" />
                <stop offset="0.95" stopColor="#12126F" stopOpacity="0.99" />
                <stop offset="1" stopColor="#000064" />
              </radialGradient>
              <clipPath id="svg_60">
                <rect fill="white" height="258.39" id="svg_61" transform="translate(0.534546 0.8125)" width="335.89" />
              </clipPath>
            </defs>
          </svg>
        </g>
        <g id="svg_66" transform="matrix(1 0 0 1.0591 0 -3.46395)">
          <svg height="238" id="svg_116" viewBox="0 0 309 238" width="309" x="37" xmlns="http://www.w3.org/2000/svg" y="58.94">
            <g clipPath="url(#svg_65)" id="svg_117">
              <path d="m103.61,0.61l-1.02,0l0,236.88l1.02,0l0,-236.88z" fill="#8392DD" id="svg_118" opacity="0.64" />
              <path d="m206.47,0.61l-1.01,0l0,236.88l1.01,0l0,-236.88z" fill="#8392DD" id="svg_119" opacity="0.64" />
            </g>
            <defs>
              <clipPath id="svg_65">
                <rect fill="white" height="236.88" id="svg_120" transform="translate(0.468689 0.612305)" width="308.02" />
              </clipPath>
            </defs>
          </svg>
        </g>
        <g id="svg_17" transform="matrix(1 0 0 1 0 0)">
          <svg height="259" id="svg_67" viewBox="0 0 337 259" width="337" x="22" xmlns="http://www.w3.org/2000/svg" y="53">
            <g clipPath="url(#svg_68)" id="svg_72">
              <path d="m336.18,87.09l-335.34,0l0,-15.66c0,-38.93 31.61,-70.52 70.56,-70.52l194.27,0c38.95,0 70.57,31.59 70.57,70.52l0,15.66l-0.06,0z" fill="url(#svg_71)" id="svg_74" opacity="0.64" />
              <path d="m336.18,172.45l-335.34,0l0,16.94c0,38.29 31.03,69.3 69.34,69.3l196.72,0c38.3,0 69.34,-31.01 69.34,-69.3l0,-16.94l-0.06,0z" fill="url(#svg_70)" id="svg_73" opacity="0.64" />
            </g>
            <defs>
              <linearGradient id="svg_71" x1="0.5" x2="0.5" y1="1" y2="0">
                <stop stopColor="#41DEFF" stopOpacity="0.3" />
                <stop offset="0.11" stopColor="#39C2EB" stopOpacity="0.37" />
                <stop offset="0.43" stopColor="#216FB2" stopOpacity="0.6" />
                <stop offset="0.7" stopColor="#0F3388" stopOpacity="0.79" />
                <stop offset="0.9" stopColor="#040E6E" stopOpacity="0.93" />
                <stop offset="1" stopColor="#000064" />
              </linearGradient>
              <linearGradient id="svg_70" x1="0.5" x2="0.5" y1="0" y2="1">
                <stop stopColor="#41DEFF" stopOpacity="0.3" />
                <stop offset="0.11" stopColor="#39C2EB" stopOpacity="0.37" />
                <stop offset="0.43" stopColor="#216FB2" stopOpacity="0.6" />
                <stop offset="0.7" stopColor="#0F3388" stopOpacity="0.79" />
                <stop offset="0.9" stopColor="#040E6E" stopOpacity="0.93" />
                <stop offset="1" stopColor="#000064" />
              </linearGradient>
              <clipPath id="svg_68">
                <rect fill="white" height="257.9" id="svg_69" transform="translate(0.778992 0.789551)" width="335.4" />
              </clipPath>
            </defs>
          </svg>
        </g>
        <g
          id="svg_32"
          transform="matrix(0.996985 0 0 1 6.95807 0) translate(100px, 0)"
          style={{
            // transform: 'matrix(0.996985 0 0 1 6.95807 0) translate(100px, 0)',
            transform: 'translate(12px, 0)',
          }}
        >
          <svg height="110" id="svg_75" viewBox="0 0 329 110" width="329" x="16.38" xmlns="http://www.w3.org/2000/svg" y="130">
            <g clipPath="url(#svg_76)" id="svg_95">
              <mask height="105" id="svg_97" maskUnits="userSpaceOnUse" width="324" x="2" y="0">
                <path d="m325.46,0.06l-322.74,0l0,104.79l322.74,0l0,-104.79z" fill="white" id="svg_115" />
              </mask>
              <g id="svg_96" mask="url(#svg_97)">
                <g filter="url(#svg_94)" id="svg_98">
                  <g filter="url(#svg_93)" id="svg_113">
                    <path d="m310.76,94.04l-292.8,0l0,1.18l292.8,0l0,-1.18z" fill="url(#svg_91)" id="svg_114" />
                  </g>
                  <g filter="url(#svg_92)" id="svg_111">
                    <path d="m316.19,9.83l-292.8,0l0,1.18l292.8,0l0,-1.18z" fill="url(#svg_90)" id="svg_112" />
                  </g>
                  <path d="m37.65,8.98l-7.52,0l0,2.69l7.52,0l0,-2.69z" fill="url(#svg_89)" id="svg_110" />
                  <path d="m31.7,5.11l-19.85,0l0,10.37l19.85,0l0,-10.37z" fill="url(#svg_88)" id="svg_109" />
                  <path d="m37.65,93.28l-7.52,0l0,2.69l7.52,0l0,-2.69z" fill="url(#svg_86)" id="svg_107" />
                  <path d="m31.7,89.43l-19.85,0l0,10.38l19.85,0l0,-10.38z" fill="url(#svg_85)" id="svg_106" />
                  <path d="m12.74,104.66c5.54,0 10.03,-4.49 10.03,-10.04c0,-5.54 -4.49,-10.03 -10.03,-10.03c-5.53,0 -10.02,4.49 -10.02,10.03c0,5.55 4.49,10.04 10.02,10.04z" fill="url(#svg_84)" id="svg_105" />
                  <path d="m290.58,96.17l7.52,0l0,-2.69l-7.52,0l0,2.69z" fill="url(#svg_83)" id="svg_104" />
                  <path d="m296.5,100.01l19.84,0l0,-10.37l-19.84,0l0,10.37z" fill="url(#svg_82)" id="svg_103" />
                  <path d="m315.45,104.85c5.53,0 10.02,-4.49 10.02,-10.03c0,-5.54 -4.49,-10.03 -10.02,-10.03c-5.54,0 -10.03,4.49 -10.03,10.03c0,5.54 4.49,10.03 10.03,10.03z" fill="url(#svg_81)" id="svg_102" />
                  <path d="m290.35,11.95l7.52,0l0,-2.69l-7.52,0l0,2.69z" fill="url(#svg_80)" id="svg_101" />
                  <path d="m296.5,15.28l19.84,0l0,-10.37l-19.84,0l0,10.37z" fill="url(#svg_79)" id="svg_100" />
                  <path d="m315.45,20.13c5.53,0 10.02,-4.49 10.02,-10.03c0,-5.55 -4.49,-10.04 -10.02,-10.04c-5.54,0 -10.03,4.49 -10.03,10.04c0,5.54 4.49,10.03 10.03,10.03z" fill="url(#svg_78)" id="svg_99" />
                  <path d="m12.74,20.32c5.54,0 10.03,-4.49 10.03,-10.03c0,-5.54 -4.49,-10.03 -10.03,-10.03c-5.53,0 -10.02,4.49 -10.02,10.03c0,5.54 4.49,10.03 10.02,10.03z" fill="url(#svg_87)" id="svg_108" />
                </g>
              </g>
            </g>
            <defs>
              <filter colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="106.75" id="svg_94" width="324.71" x="1.74" y="0.06">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="0.98" />
                <feGaussianBlur stdDeviation="0.49" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend in2="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_1143_131487" />
              </filter>
              <filter colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="4.11" id="svg_93" width="295.73" x="16.5" y="92.58">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset />
                <feGaussianBlur stdDeviation="0.73" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.929412 0 0 0 0 1 0 0 0 0.75 0" />
                <feBlend in2="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_1143_131487" />
              </filter>
              <filter colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="4.11" id="svg_92" width="295.73" x="21.92" y="8.36">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset />
                <feGaussianBlur stdDeviation="0.73" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.929412 0 0 0 0 1 0 0 0 0.75 0" />
                <feBlend in2="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_1143_131487" />
              </filter>
              <linearGradient id="svg_91" x1="0" x2="1" y1="0.5" y2="0.5">
                <stop stopColor="#502EEF" />
                <stop offset="0.09" stopColor="#5B57F2" />
                <stop offset="0.21" stopColor="#698CF6" />
                <stop offset="0.32" stopColor="#74B6FA" />
                <stop offset="0.42" stopColor="#7CD4FC" />
                <stop offset="0.51" stopColor="#81E6FE" />
                <stop offset="0.57" stopColor="#83EDFF" />
                <stop offset="0.62" stopColor="#81E6FE" />
                <stop offset="0.68" stopColor="#7CD5FD" />
                <stop offset="0.76" stopColor="#75B9FA" />
                <stop offset="0.84" stopColor="#6A91F7" />
                <stop offset="0.92" stopColor="#5D5FF3" />
                <stop offset="1" stopColor="#502EEF" />
              </linearGradient>
              <linearGradient id="svg_90" x1="0" x2="1" y1="0.5" y2="0.5">
                <stop stopColor="#502EEF" />
                <stop offset="0.09" stopColor="#5B57F2" />
                <stop offset="0.21" stopColor="#698CF6" />
                <stop offset="0.32" stopColor="#74B6FA" />
                <stop offset="0.42" stopColor="#7CD4FC" />
                <stop offset="0.51" stopColor="#81E6FE" />
                <stop offset="0.57" stopColor="#83EDFF" />
                <stop offset="0.62" stopColor="#81E6FE" />
                <stop offset="0.68" stopColor="#7CD5FD" />
                <stop offset="0.76" stopColor="#75B9FA" />
                <stop offset="0.84" stopColor="#6A91F7" />
                <stop offset="0.92" stopColor="#5D5FF3" />
                <stop offset="1" stopColor="#502EEF" />
              </linearGradient>
              <linearGradient id="svg_89" x1="-3.87" x2="-2.85" y1="0.5" y2="0.5">
                <stop offset="0.08" stopColor="#00007C" />
                <stop offset="0.45" stopColor="#0A4094" />
                <stop offset="0.6" stopColor="#104A9C" />
                <stop offset="0.86" stopColor="#2164B3" />
                <stop offset="1" stopColor="#2D76C2" />
              </linearGradient>
              <radialGradient cx="0" cy="0" gradientTransform="translate(28.8021 8.53378) scale(14.0543 5.20514)" gradientUnits="userSpaceOnUse" id="svg_88" r="1">
                <stop stopColor="#2D76C2" />
                <stop offset="0.14" stopColor="#2164B3" />
                <stop offset="0.4" stopColor="#104A9C" />
                <stop offset="0.55" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </radialGradient>
              <radialGradient cx="0" cy="0" gradientTransform="translate(16.1659 7.21659) scale(6.1258 6.13173)" gradientUnits="userSpaceOnUse" id="svg_87" r="1">
                <stop stopColor="#1E60AF" />
                <stop offset="0.23" stopColor="#104A9C" />
                <stop offset="0.39" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </radialGradient>
              <linearGradient id="svg_86" x1="0" x2="1" y1="0.5" y2="0.5">
                <stop offset="0.08" stopColor="#00007C" />
                <stop offset="0.45" stopColor="#0A4094" />
                <stop offset="0.6" stopColor="#104A9C" />
                <stop offset="0.86" stopColor="#2164B3" />
                <stop offset="1" stopColor="#2D76C2" />
              </linearGradient>
              <radialGradient cx="0" cy="0" gradientTransform="translate(30.3694 92.6108) scale(13.2161 5.68843)" gradientUnits="userSpaceOnUse" id="svg_85" r="1">
                <stop stopColor="#2D76C2" />
                <stop offset="0.14" stopColor="#2164B3" />
                <stop offset="0.4" stopColor="#104A9C" />
                <stop offset="0.55" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </radialGradient>
              <radialGradient cx="0" cy="0" gradientTransform="translate(17.1706 92.5725) scale(7.40034 7.4075)" gradientUnits="userSpaceOnUse" id="svg_84" r="1">
                <stop stopColor="#1E60AF" />
                <stop offset="0.23" stopColor="#104A9C" />
                <stop offset="0.39" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </radialGradient>
              <linearGradient id="svg_83" x1="0.26" x2="0.61" y1="-0.37" y2="0.9">
                <stop stopColor="#2D76C2" />
                <stop offset="0.17" stopColor="#2164B3" />
                <stop offset="0.47" stopColor="#104A9C" />
                <stop offset="0.66" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </linearGradient>
              <radialGradient cx="0" cy="0" gradientTransform="translate(296.661 92.7326) scale(15.0533 5.12307)" gradientUnits="userSpaceOnUse" id="svg_82" r="1">
                <stop stopColor="#2D76C2" />
                <stop offset="0.14" stopColor="#286EBB" />
                <stop offset="0.35" stopColor="#1B5AAA" />
                <stop offset="0.55" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </radialGradient>
              <linearGradient id="svg_81" x1="0.1" x2="0.9" y1="0.2" y2="0.8">
                <stop stopColor="#795DD4" />
                <stop offset="0.09" stopColor="#5A55C2" />
                <stop offset="0.23" stopColor="#2F49A9" />
                <stop offset="0.34" stopColor="#144299" />
                <stop offset="0.4" stopColor="#0A4094" />
                <stop offset="1" stopColor="#00007C" />
              </linearGradient>
              <linearGradient id="svg_80" x1="0.15" x2="0.82" y1="-0.44" y2="1.34">
                <stop stopColor="#559BE6" />
                <stop offset="0.09" stopColor="#4F94E0" />
                <stop offset="0.22" stopColor="#4081CF" />
                <stop offset="0.36" stopColor="#2763B3" />
                <stop offset="0.5" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </linearGradient>
              <radialGradient cx="0" cy="0" gradientTransform="translate(297.224 7.8758) scale(16.7986 5.21261)" gradientUnits="userSpaceOnUse" id="svg_79" r="1">
                <stop stopColor="#0099FF" />
                <stop offset="0.25" stopColor="#2D76C2" />
                <stop offset="0.33" stopColor="#286EBB" />
                <stop offset="0.44" stopColor="#1B5AAA" />
                <stop offset="0.55" stopColor="#0A4094" />
                <stop offset="0.92" stopColor="#00007C" />
              </radialGradient>
              <linearGradient id="svg_78" x1="0.01" x2="0.99" y1="0.42" y2="0.58">
                <stop stopColor="#5D96D4" />
                <stop offset="0.02" stopColor="#5790D0" />
                <stop offset="0.19" stopColor="#2D65AF" />
                <stop offset="0.32" stopColor="#134A9B" />
                <stop offset="0.4" stopColor="#0A4094" />
                <stop offset="1" stopColor="#00007C" />
              </linearGradient>
              <clipPath id="svg_76">
                <rect fill="white" height="109.76" id="svg_77" transform="translate(0.423706 0.0610352)" width="327.82" />
              </clipPath>
            </defs>
          </svg>
        </g>
      </g>
    </svg>
  );
}
export default MachineScreenBgSVG;
