// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { axiosInstance } from '../../index';
import {
  AutopilotResponse, PurchasesResponse, ShopItemBuyResponse, ShopResponse,
} from '../../../types';
import { updateProfileCoins } from '../profile/asyncActions';
// Types

export const getShopItems = createAsyncThunk<ShopResponse>(
  'shop/all',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('store');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const getPurchases = createAsyncThunk<PurchasesResponse>(
  'shop/purchases',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('store/purchases');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const buyStoreItem = createAsyncThunk<
    ShopItemBuyResponse,
    { id: string | number, level: string | number }
>(
  'shop/buy',
  async ({ id, level }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(`/store/${id}/buy`, { level });
      dispatch(getShopItems());
      dispatch(getPurchases());
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const getAutopilot = createAsyncThunk<AutopilotResponse>(
  'shop/getAutopilot',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('autopilot');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const startAutopilot = createAsyncThunk<AutopilotResponse>(
  'shop/startAutopilot',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch('autopilot/start');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const finishAutopilot = createAsyncThunk<AutopilotResponse>(
  'shop/finishAutopilot',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.patch('autopilot/finish');
      dispatch(updateProfileCoins({ coins: data.autopilot.balance, replace: false }));
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const selectSkin = createAsyncThunk<AutopilotResponse, {skinLevelId: number}>(
  'shop/selectSkin',
  async ({ skinLevelId }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.patch('users/skin', { skinLevelId });
      dispatch(getPurchases());
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
