type ProgressBarProps = {
  size?: 'small' | 'default'
  currentValue: number
  goal: number
  elementWidth?: string
  type?: 'main' | 'energy'
  hideLine?: boolean
  legend?: string[] | number[]
}
function ProgressBar({
  size = 'default', currentValue, goal, elementWidth, type = 'main', hideLine, legend,
}: ProgressBarProps) {
  const currentPercentage = (currentValue / goal) * 100;
  const completedPercentage = Math.floor(currentPercentage > 100 ? 100 : currentPercentage); // maximum value should be 100
  const positionClass = `before:left-[${completedPercentage}%]`;
  return (
    <div>
      <div className={`flex gap-[1px] border mt-[0.1rem]
    ${type === 'main' ? 'border-green-main border-opacity-10 rounded' : 'border-blue-progressbar-border border-opacity-15 rounded-sm'}
    p-[1px] pt-[2px] pl-0
    ${!hideLine && `before:content[''] before:${completedPercentage === 100 ? 'w-0' : 'w-0.5'}`} 
    ${size === 'small' ? 'before:h-[8px]' : 'before:h-[12px]'} before:relative before:bg-gray-200 ${positionClass}
    `}
      >
        {Array.from({ length: 5 }, (_, i) => i + 1).map((el) => (
          <div
            key={el}
            className={`w-1/5 ${size === 'small' ? 'h-[7px]' : 'h-[12px]'} 
        ${type === 'main' ? 'bg-green-main bg-opacity-10 rounded' : 'bg-blue-progressbar-bg bg-opacity-10 rounded-sm'}
         `}
            style={{
              background: (completedPercentage >= ((100 / 5) * el)) ? completedCellBGStyles[type] : undefined,
              width: elementWidth,
            }}
          />
        ))}
      </div>
      {legend
      && (
      <div className="flex gap-[1px]">
        {/* eslint-disable-next-line react/no-array-index-key */}
          {legend.map((legendItem, index) => <div className={`w-1/5 text-center text-[10px] ${completedPercentage >= ((100 / 5) * (index + 1)) ? 'text-white' : 'text-gray-300'}`} key={`${legendItem}_${index}`}>{legendItem}</div>)}
      </div>
      )}
    </div>
  );
}

export default ProgressBar;
const completedCellBGStyles = {
  main: `repeating-linear-gradient(
                -55deg,
                #14D8A6,
                #24C29A 2px,
                #24C29A 2px,
                #24C29A 5px
              )`,
  energy: `repeating-linear-gradient(
                -55deg,
                #00AFE6,
                #00AFE6 2px,
                #0BC5FF 2px,
                #0BC5FF 5px
              )`,
} as const;
