import * as React from 'react';
import Drawer from '../Drawer';
import { ReactComponent as Char } from '../../assets/svg/aviatorCharacter.svg';

type AviatorLoseModalProps = {
  open: boolean
  onClose: () => void
}
function AviatorLoseModal({
  open, onClose,
}: AviatorLoseModalProps) {
  return (
    <Drawer
      side="bottom"
      open={open}
      onClose={onClose}
      closeStroke="gray-10"
      hideClose
      wrapperClassName="flex flex-col overflow-y-scroll h-60 shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#39608D] to-[#2F415C] z-[9] relative stroke-gray-10"
    >
      <div className="bg-aviatorBgStarLose bg-centerXPosition bg-no-repeat h-full stroke-[none]">
        <div className="flex justify-center mt-4" />
        <div className="flex justify-center mt-4">
          <div className="text-[26px] flex justify-center items-center gap-2">
            <span />
            <Char width={100} height={100} />
          </div>
        </div>
        <div className="text-lg flex justify-center items-center gap-2 mt-4">
          Better luck next time!
        </div>
        <div className="text-sm flex justify-center items-center gap-2 text-gray-2">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Don't worry. The alien will return!
        </div>
      </div>
    </Drawer>
  );
}

export default AviatorLoseModal;
