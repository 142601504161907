export enum SlotTypes {
  coin_bag = 'coin_bag',
  treasure = 'treasure',
  multiplier_x2 = 'multiplier_x2',
  multiplier_x3 = 'multiplier_x3',
  multiplier_x5 = 'multiplier_x5',
  energy_refill = 'energy_refill',
  revive = 'revive',
  aviator = 'aviator',
}
