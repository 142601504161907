import React, { useMemo, useState } from 'react';
import { QuestT, TQuestsResponse } from '../../types';
import { useAppDispatch, useAppSelector } from '../../store';
import { finishQuest, startQuest } from '../../store/reducers/quests/asyncActions';
import { ReactComponent as CircleCheck } from '../../assets/svg/circleCheck.svg';
import QuestDrawerContent from './QuestDrawerContent';
import Drawer from '../../Components/Drawer';
import DailyQuest from '../../Components/DailyQuest';
import Button from '../../Components/Button';
import { ReactComponent as CoinSize } from '../../assets/images/coin-size.svg';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import RefillCard from './RefillCard';
import EnergyDrawerContent from './EnergyDrawerContent';
import DailyCard from './DailyCard';
import { CheckButton, ClaimedButton, CompleteTheTaskButton } from '../../Components/QuestButtons';
import { useGetEnergyRefillTimer } from '../../hooks/useGetEnergyRefillTimer';

const tg = window?.Telegram.WebApp;

function QuestPage() {
  const dispatch = useAppDispatch();
  const { questsList } = useAppSelector(
    ({ quests }) => quests,
  );
  const {
    completedEnergyQuests, totalEnergyQuests,
  } = useGetEnergyRefillTimer();

  const remainEnergyRefill = useMemo(() => (totalEnergyQuests || 0) - (completedEnergyQuests || 0), [totalEnergyQuests, completedEnergyQuests]);

  const [selectedQuest, setSelectedQuest] = useState<null | QuestT>(null);
  const [invalidQuests, setInvalidQuests] = useState<number[]>([]);

  const [openDailyDrawer, setOpenDailyDrawer] = useState<boolean>(false);
  const [openEnergyDrawer, setOpenEnergyDrawer] = useState<boolean>(false);
  const sortedQuests = useMemo(() => {
    const tempList: TQuestsResponse = {
      daily: [],
      social: [],
      referral: [],
      daily_energy_refill: [],
    };
    Object.keys(questsList || []).forEach((key: keyof TQuestsResponse) => {
      const sortedList = [...(questsList as TQuestsResponse)[key]].sort((el) => (el.progress?.completed_at ? 0 : -1)) || [];
      Object.assign(tempList, { [key]: sortedList });
    });
    return tempList;
  }, [questsList]);
  const completedDailyQuests = questsList?.daily?.filter((el) => el.progress?.completed_at).length;
  const isTodayQuestCompleted = useMemo(() => ![...(questsList?.daily || [])].filter((quest) => quest.progress?.started_at && !quest.progress.completed_at).length, [questsList?.daily]);
  const handleDailyClick = () => {
    setOpenDailyDrawer(true);
  };
  const handleEnergyClick = () => {
    setOpenEnergyDrawer(true);
  };
  const handleDailyClaim = () => {
    const dailyQuestId = questsList?.daily.find((dailyQuest) => dailyQuest.progress?.started_at && !dailyQuest.progress?.completed_at)?.id;
    if (dailyQuestId) {
      dispatch(finishQuest({ id: dailyQuestId }));
    }
  };
  const isDailyQuestAvailable = questsList?.daily.find((dailyQuest) => dailyQuest.progress?.started_at && !dailyQuest.progress?.completed_at);
  const handleEnergyFill = () => {
    const questId = questsList?.daily_energy_refill.find((dailyQuest) => dailyQuest.progress?.started_at && !dailyQuest.progress?.completed_at)?.id;
    if (questId) {
      dispatch(finishQuest({ id: questId }));
    }
  };
  const handleQuestStart = async (quest: QuestT) => {
    if (quest.url) {
      // window.open(quest.url, '_blank', 'noopener,noreferrer,resizable');
      tg.openLink(quest.url);
    }
    const resp = await dispatch(startQuest({ id: quest.id }));
    if (resp?.payload?.quest) {
      setSelectedQuest(resp.payload.quest);
    }
  };
  const handleQuestCheck = async (quest: QuestT) => {
    const resp = await dispatch(finishQuest({ id: quest.id }));
    if (resp?.error) {
      const temp = [...invalidQuests, quest.id];
      setInvalidQuests(temp);
    } else {
      const tempIds = [...invalidQuests];
      tempIds.filter((id) => id !== quest.id);
      setInvalidQuests(tempIds);
      setSelectedQuest(null);
    }
  };
  return (
    <div className="px-2 h-full flex flex-col pt-[.4rem]">
      <div className="flex gap-2 justify-between px-2">
        <DailyCard handleDailyClick={handleDailyClick} completedDailyQuests={completedDailyQuests || 0} totalDailyQuests={sortedQuests.daily.length} isTodayQuestCompleted={isTodayQuestCompleted} />
        <RefillCard handleEnergyClick={handleEnergyClick} />
      </div>
      {remainEnergyRefill === 0 && openEnergyDrawer && (
        <div className="fixed z-10 top-[3.7rem] left-0 w-[100vw] px-4">
          <div className="py-3 bg-gradient-to-r from-blue-gradient-no-energy-1 to-blue-gradient-no-energy-2 rounded-10 border border-blue-200">
            <div className="text-14 font-bold">No More Energy Refills Today!</div>
            <div className="text-12 leading-4 px-4 pt-[.125rem]">Come back tomorrow to recharge and continue your adventure!</div>
          </div>
        </div>
      )}
      {(completedDailyQuests === sortedQuests.daily.length) && openDailyDrawer && (
        <div className="fixed z-10 top-[4.5rem] left-0 w-[100vw] px-4">
          <div className="py-3 bg-gradient-to-r from-orange-gradient-no-daily-1 to-orange-gradient-no-daily-2 rounded-10 border border-orange-500">
            <div className="text-14 px-5 leading-4  font-bold">Congratulations! You`ve successfully claimed your final reward for this streak!</div>
            <div className="text-12 px-4 leading-4 px-4 pt-2">Come back tomorrow to start your next streak and unlock even bigger rewards!</div>
          </div>
        </div>
      )}
      <div
        className="w-full overflow-y-scroll text-white mt-[.9rem] mb-[6rem] h-full"
      >
        <div className="overflow-hidden">
          <div className="px-2 py-3 h-full rounded-t-[0]">
            <QuestDrawerContent
              quests={sortedQuests}
              invalidQuests={invalidQuests}
              setSelectedQuest={setSelectedQuest}
              handleQuestCheck={handleQuestCheck}
              handleQuestStart={handleQuestStart}
            />
          </div>
        </div>
      </div>
      <Drawer
        side="bottom"
        open={openDailyDrawer}
        onClose={() => setOpenDailyDrawer(false)}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col overflow-y-scroll h-fit pb-8 shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#B73B20] to-[#821220] z-[9] relative stroke-gray-10"
      >
        <div className="bg-dailyBgStar bg-no-repeat h-full">
          <div className="mt-6 px-4">
            <div className="text-start">
              <div className="text-lg font-bold">
                Daily Rewards
              </div>
              <div className="text-sm leading-4">
                Log in every day to stack up even more coins and exclusive items!
              </div>
            </div>
            <div className="flex gap-[0.45rem] flex-wrap mt-6">
              {[...(questsList?.daily || [])].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })).map((dailyQuest) => <DailyQuest quest={dailyQuest} key={dailyQuest.id} />)}
            </div>
            {isDailyQuestAvailable
              ? <Button className="mt-[1.7rem] w-full py-2" onClick={handleDailyClaim}>Claim</Button>
              : (
                <Button className="mt-[1.7rem] w-full py-2 bg-green-text bg-opacity-15 rounded-10 text-green-text flex items-center justify-center gap-1" pureClass>
                  <CircleCheck stroke="#1FD969" />
                  Claimed
                </Button>
              )}
          </div>
        </div>
      </Drawer>
      <Drawer
        side="bottom"
        open={!!selectedQuest}
        onClose={() => setSelectedQuest(null)}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col pb-6 shadow-xl rounded-t-[20px] bg-black-modal-bg z-[9] relative"
      >
        <div className="text-start px-4 mt-2 h-full">
          <div className="text-lg font-bold pt-4">{selectedQuest?.name}</div>
          <div className="text-sm text-gray-2" style={{ lineHeight: '1.05rem' }}>{selectedQuest?.description}</div>
          <div className="text-sm text-gray-2 flex gap-2 bg-black-light-bg rounded-10 p-4 mt-[1.45rem] items-center">
            Reward
            <span className="text-white font-bold">
              {selectedQuest?.rewards}
            </span>
            <div className="ml-[-.3rem]"><CoinSize width={18} /></div>
          </div>
          <div className="my-4">
            {selectedQuest?.type !== 'referral'
              && (
              <div className="text-sm text-gray-2 flex gap-2 ">
                <Clock />
                {'Completion time: <1 minutes'}
              </div>
              )}
          </div>
          {invalidQuests.includes(selectedQuest?.id as number)
              && (
              <div className="text-start text-xs min-w-[200px] text-red-error pb-3">
                The task was not completed.
                <br />
                {selectedQuest?.type === 'referral' ? 'The goal was not achieved' : 'Try again in 2 minutes.'}
              </div>
              )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedQuest?.progress?.completed_at
            ? (
              <div className="mt-[1.3rem]">
                <ClaimedButton isPopupButton />
              </div>
            )// eslint-disable-next-line no-nested-ternary
            : selectedQuest?.type === 'referral' ? (
              <CheckButton onClick={async () => { await handleQuestCheck(selectedQuest); }} checkDisable={false} isPopupButton />
            )
              : selectedQuest?.progress?.started_at
                ? (<CheckButton onClick={async () => { await handleQuestCheck(selectedQuest); }} checkDisable={false} isPopupButton />)
                : (<CompleteTheTaskButton isPopupButton onClick={() => handleQuestStart(selectedQuest as QuestT)} />)}
        </div>
      </Drawer>
      <Drawer
        side="bottom"
        open={openEnergyDrawer}
        onClose={() => setOpenEnergyDrawer(false)}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col overflow-y-scroll h-80 shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#00387A] to-[#002864] z-[9] relative stroke-gray-10"
      >
        <EnergyDrawerContent handleEnergyFill={handleEnergyFill} />
      </Drawer>
    </div>
  );
}

export default QuestPage;
