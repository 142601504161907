import React from 'react';
import Button from '../Button';

interface ModalProps {
    message?: string | null;
    onReload: () => void;
}

function Modal({ message, onReload }: ModalProps) {
  return (
    <div className="fixed inset-0 p-6 bg-black bg-opacity-60 flex justify-center items-center z-[5]">
      <div className="bg-white p-6 rounded-md shadow-md text-center">
        <p className="mb-6">
          {' '}
          {message}
        </p>
        <Button onClick={onReload}>
          Reload
        </Button>
      </div>
    </div>
  );
}

export default Modal;
