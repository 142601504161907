import { Outlet } from 'react-router-dom';
import Header from '../../Components/Header';
import Menu from '../../Components/Menu';

function MainLayout() {
  return (
    <div className="bg-black-main text-white h-screen justify-between">
      <div className="h-screen flex flex-col">
        <Header />
        <div className="overflow-auto mt-4 h-screen">
          <Outlet />
        </div>
        <Menu />
      </div>
    </div>
  );
}

export default MainLayout;
