import React, {
  useEffect, useRef, useState,
} from 'react';
import { initUtils } from '@telegram-apps/sdk';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/Button';
import { useAppDispatch, useAppSelector } from '../../store';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { ReactComponent as LongArrow } from '../../assets/svg/longArrow.svg';
import { ReactComponent as Copy } from '../../assets/svg/copy.svg';
import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg';
import FriendCard from './FriendCard';
import { getReferrals } from '../../store/reducers/referrals/asyncActions';
import PilotsImg from '../../assets/images/pilots.svg';
import CoinImg from '../../assets/images/coin-size-42x42.svg';
import QuestTasksImg from '../../assets/images/quest-tasks.svg';
import Pages from '../../Router/pages';
import { formatNumbers } from '../../helpers';

function FriendsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const bonusRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const personRef = useRef<HTMLDivElement>(null);
  const [copiedTooltip, setCopiedTooltip] = useState<boolean>(false);
  const { referrals: referralsData } = useAppSelector(({ referrals }) => referrals);
  const referralLink = `${process.env.REACT_APP_BOT_URL}?startapp=${userData?.telegram_id}`;

  const handeSendInvite = () => {
    const utils = initUtils();
    const shareText = 'Join me on RocketCrash and let\'s earn together! Use my invite link to join the fun.';
    utils.openTelegramLink(`https://t.me/share/url?url=${referralLink}&text=${shareText}`);
  };
  const handeCopyInvite = () => {
    navigator.clipboard.writeText(referralLink);
    setCopiedTooltip(true);
    setTimeout(() => {
      setCopiedTooltip(false);
    }, 2000);
  };
  const handleBonusClick = () => {
    navigate(Pages.quests);
  };
  useEffect(() => {
    dispatch(getReferrals());
  }, []);

  useEffect(() => {
    if (infoRef?.current && personRef.current) {
      const contentRect = infoRef.current.getClientRects()[0];
      const parent = personRef.current;

      const offset = 190;
      const topPosition = contentRect.top - offset;

      parent.style.backgroundPosition = `right 0 top ${topPosition}px`;
    }
  }, [personRef, infoRef]);

  const handleScroll = () => {
    const topPosition = personRef?.current?.scrollTop;
    const header = document.getElementById('header');
    if (header && topPosition) {
      header.style.backgroundColor = `rgba(13, 13, 15, ${topPosition / 50})`;
    }
  };

  return (
    <div className="absolute w-screen h-screen top-0 overflow-hidden">
      <div ref={personRef} className="h-full flex flex-col z-[2] bg-no-repeat overflow-y-scroll" onScroll={handleScroll}>
        <div className="px-3 bg-friendsRacoonBg bg-no-repeat bg-racoonPosition bg-racoonSize">
          <div className="flex flex-col items-start mt-[3.8rem] px-1">
            <h3 className="text-lg font-bold">
              Invite friend and earn
            </h3>
            <h2 className="text-2xl flex items-center gap-1 leading-none tracking-wider">
              <div className="font-bold">
                5,000
              </div>
              <Coin width={25} height={25} />
              <span className="tracking-tighter">for</span>
            </h2>
            <h3 className="text-2xl" style={{ letterSpacing: '-.05rem' }}>
              everyone
            </h3>
            <p className="text-gray-2 text-sm max-w-[46%] text-start mt-[.7rem] leading-[1.2]">
              Receive up to 20% from each invited user from each of your referrals
            </p>
          </div>
          <div ref={infoRef} className="bg-black-2 mt-12 ml-1 mr-[.2rem] rounded-10 pb-4 pt-3 border-black-light-bg border-2 max-w-[400px]">
            <div className="flex flex-col items-start justify-around gap-2 pl-[1.15rem]">
              <div className="flex text-start gap-3">
                <img
                  className="w-[40px] h-auto object-contain"
                  src={PilotsImg}
                  alt="pilots"
                />
                <div className="ml-1">
                  <div className="text-sm" style={{ letterSpacing: '.01rem' }}>
                    Refer your friends
                  </div>
                  <p className="text-gray-2 text-xs mt-[.4rem]">
                    Earn 5000 coins each
                  </p>
                </div>
              </div>
              <div className="flex text-start gap-[.35rem]">
                <img
                  className="w-[42px] h-auto object-contain"
                  src={CoinImg}
                  alt="coin"
                />

                <div className="ml-2">
                  <div className="text-sm" style={{ letterSpacing: '.01rem' }}>
                    Bonus points
                  </div>
                  <p className="text-gray-2 text-xs mt-2 pr-4 leading-tight">
                    {'Earn 20% of your friend\'s in-game earnings, up to a maximum of 10,000 coins per friend.'}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 px-4 mt-[1.3rem]" style={{ letterSpacing: '-.05rem' }}>
              <Button
                type="button"
                onClick={handeSendInvite}
                className="px-4 py-[.6rem] bg-white text-black-text font-bold rounded-10 w-full flex items-center justify-center gap-[.6rem] focus:ring-4 transform active:scale-75 transition-transform"
                pureClass
              >
                Invite friends
                <LongArrow />
              </Button>
              <Button
                type="button"
                onClick={handeCopyInvite}
                className="py-[.9rem] w-fit flex items-center justify-center gap-2 focus:ring-4 transform active:scale-75 transition-transform relative"
              >
                <Copy />
                <div
                  role="tooltip"
                  className={`absolute ${!copiedTooltip && 'invisible'} bottom-10 right-0 z-[6] w-[100px] px-3 py-2 text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700`}
                >
                  <span id="default-tooltip-message">Copied</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div ref={bonusRef} className="flex gap-2 pl-[.8rem] pr-[1.3rem] py-2 mt-6 mx-4 bg-quests-block rounded-10 h-[60px] cursor-pointer max-w-[400px]" onClick={handleBonusClick}>
          <img src={QuestTasksImg} alt="quests" className="max-h-[60px]" />
          <div className="flex items-center justify-between w-full">
            <div className="text-start pl-[2px]">
              <div className="text-base mt-[-.3rem] font-bold">
                Bonus points
              </div>
              <div className="text-xs pt-[2px]">
                More income in our quests
              </div>
            </div>
            <Arrow className="scale-150" />
          </div>
        </div>
        <div className="px-4 mt-10 mb-[7rem]">
          <div className="flex justify-between">
            <div className="text-lg font-bold">
              {referralsData?.referrals.length || 0}
              {' '}
              friends
            </div>
            <div className="text-2xl font-bold flex items-center gap-2" style={{ lineHeight: '1.6rem', letterSpacing: '-0.05rem' }}>
              {formatNumbers(referralsData?.all_time_rewards || 0, 3, false)}
              <Coin width={24} height={24} />
            </div>
          </div>
          <div className="mt-[.9rem] grid grid-cols-2 gap-2">
            {referralsData?.referrals.map((referral) => <FriendCard key={referral.avatar_url} referral={referral} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FriendsPage;
