import { ReactComponent as CircleCheck } from '../../assets/images/circle-check.svg';
import { ReactComponent as Coin } from '../../assets/images/coin-size-26x26.svg';
import { QuestT } from '../../types';
import { numberWithCommas } from '../../helpers';

type DailyQuestProps = {
    quest: QuestT
}
function DailyQuest({ quest }: DailyQuestProps) {
  const isCompleted = !!quest.progress?.completed_at;
  const isFuture = !quest.progress;

  return (
    <div className={`
    ${isCompleted && 'border-yellow-1 border'} 
    ${(!isCompleted && !isFuture) && 'bg-dailyCoinBgStar bg-dailyCoinBgStarPosition bg-no-repeat'}
    ${(!isCompleted && isFuture) && 'opacity-60 bg-dailyCoinBgStar bg-dailyCoinBgStarPosition bg-no-repeat'}
    rounded-10 flex-[18%] flex flex-col items-center justify-between gap-0 bg-opacity-15 bg-white relative overflow-hidden px-1`}
    >
      {isFuture
       && <div className="bg-white bg-opacity-10 absolute h-full w-full" />}
      <span className="mt-2 text-xs">
        {quest.name}
      </span>
      <div className="mt-1">
        {isCompleted
          ? <CircleCheck stroke="#FCE33A" />
          : (
            <Coin stroke="none" />
          )}
      </div>
      <span className="mt-1 mb-1 text-[10px]">{numberWithCommas(quest.rewards)}</span>
    </div>
  );
}

export default DailyQuest;
