function MachineSvgDefs() {
  return (
    <>
      <radialGradient id="paint0_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.5403 319.421) rotate(-144.678) scale(111.474 38.0091)">
        <stop offset="0" stopColor="#EEEEEE" />
        <stop offset="0.3578" stopColor="#6AD1F7" />
        <stop offset="0.7914" stopColor="#5D2FB3" />
        <stop offset="1" stopColor="#06295E" />
      </radialGradient>
      <radialGradient id="paint1_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.3365 325.11) rotate(35.8847) scale(90.2716 27.4614)">
        <stop stopColor="#4937C2" />
        <stop offset="0.2189" stopColor="#402FA6" />
        <stop offset="0.5575" stopColor="#332380" />
        <stop offset="0.8299" stopColor="#2C1D69" />
        <stop offset="1" stopColor="#291A60" />
      </radialGradient>
      <linearGradient id="paint2_linear_1415_146431" x1="0.4373" y1="0.60374" x2="0.5404" y2="0.40363">
        <stop offset="0.275" stopColor="#8688F8" />
        <stop offset="0.3926" stopColor="#6864D6" />
        <stop offset="0.5522" stopColor="#4439AF" />
        <stop offset="0.6961" stopColor="#2A1A92" />
        <stop offset="0.8177" stopColor="#1B0780" />
        <stop offset="0.9025" stopColor="#15007A" />
      </linearGradient>
      <radialGradient id="paint3_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.2472 284.603) rotate(-144.51) scale(71.0439 20.1798)">
        <stop stopColor="#A8EEFF" />
        <stop offset="0.5093" stopColor="#B19BF1" />
        <stop offset="1" stopColor="#00277C" />
      </radialGradient>
      <linearGradient id="paint4_linear_1415_146431" x1="0.08951" y1="0.90725" x2="1.05538" y2="0.17618">
        <stop stopColor="#33057C" />
        <stop offset="0.147" stopColor="#291482" />
        <stop offset="0.4292" stopColor="#0D3B92" />
        <stop offset="0.4595" stopColor="#0A4094" />
        <stop offset="0.6135" stopColor="#0E67B7" />
        <stop offset="0.8199" stopColor="#15A2EA" />
        <stop offset="0.9255" stopColor="#B9D9EA" />
      </linearGradient>
      <radialGradient id="paint5_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(81.7197 39.3334) rotate(-27.8106) scale(92.8778 26.5746)">
        <stop stopColor="#C4EBFF" />
        <stop offset="0.1464" stopColor="#C1E9FE" stopOpacity="0.8923" />
        <stop offset="0.272" stopColor="#B6E4FC" stopOpacity="0.7999" />
        <stop offset="0.3896" stopColor="#A4DBF8" stopOpacity="0.7133" />
        <stop offset="0.4078" stopColor="#A1D9F7" stopOpacity="0.7" />
        <stop offset="0.7916" stopColor="#43B4EE" stopOpacity="0.2463" />
        <stop offset="1" stopColor="#15A2EA" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint6_linear_1415_146431" x1="0.4995" y1="1.00107" x2="0.4995" y2="-0.00062">
        <stop offset="0.1855" stopColor="#1B69F8" />
        <stop offset="0.8622" stopColor="#2E1877" />
        <stop offset="1" stopColor="#221157" />
      </linearGradient>
      <radialGradient id="paint7_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.4535 172.439) scale(45.7027 105.089)">
        <stop stopColor="#1E429C" />
        <stop offset="0.2515" stopColor="#223487" />
        <stop offset="0.7669" stopColor="#2B1152" />
        <stop offset="1" stopColor="#300038" />
      </radialGradient>
      <linearGradient id="paint8_linear_1415_146431" x1="0.49997" y1="1.00057" x2="0.49997" y2="0">
        <stop offset="0" stopColor="#FF0089" />
        <stop offset="0.1677" stopColor="#FF2093" />
        <stop offset="0.5339" stopColor="#FF71AB" />
        <stop offset="0.6946" stopColor="#FF97B6" />
        <stop offset="1" stopColor="#FFCBF3" />
      </linearGradient>
      <linearGradient id="paint9_linear_1415_146431" x1="0.50039" y1="0.99859" x2="0.50039" y2="-0.00706">
        <stop stopColor="#FDFB6A" />
        <stop offset="0.2044" stopColor="#FDFB6D" />
        <stop offset="0.3673" stopColor="#FDFB77" />
        <stop offset="0.5161" stopColor="#FDFC88" />
        <stop offset="0.6563" stopColor="#FEFCA0" />
        <stop offset="0.7905" stopColor="#FEFDBE" />
        <stop offset="0.9185" stopColor="#FFFEE3" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint10_linear_1415_146431" x1="0.50039" y1="1.00035" x2="0.50039" y2="-0.00529">
        <stop stopColor="#009DC8" />
        <stop offset="0.1198" stopColor="#05A0C9" />
        <stop offset="0.2664" stopColor="#14A7CD" />
        <stop offset="0.427" stopColor="#2DB4D4" />
        <stop offset="0.5977" stopColor="#50C6DC" />
        <stop offset="0.7762" stopColor="#7CDDE8" />
        <stop offset="0.9588" stopColor="#B2F8F6" />
        <stop offset="1" stopColor="#BFFFF9" />
      </linearGradient>
      <radialGradient id="paint11_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.8654 302.223) rotate(35.6095) scale(89.9664 42.6243)">
        <stop stopColor="#42329C" />
        <stop offset="0.393" stopColor="#392A87" />
        <stop offset="1" stopColor="#291A60" />
      </radialGradient>
      <linearGradient id="paint12_linear_1415_146431" x1="-0.67933" y1="0.4987" x2="1.56656" y2="0.50285">
        <stop offset="0.01441" stopColor="#4F72BD" />
        <stop offset="0.4118" stopColor="#323B99" />
        <stop offset="0.7619" stopColor="#1C107E" />
        <stop offset="0.9404" stopColor="#130073" />
      </linearGradient>
      <linearGradient id="paint13_linear_1415_146431" x1="0.0558" y1="0.49896" x2="0.80995" y2="0.49982">
        <stop offset="0" stopColor="#130073" />
        <stop offset="0.04703" stopColor="#3E219D" />
        <stop offset="0.09836" stopColor="#6540C4" />
        <stop offset="0.1455" stopColor="#8257E1" />
        <stop offset="0.1864" stopColor="#9364F2" />
        <stop offset="0.2166" stopColor="#9969F8" />
        <stop offset="0.2309" stopColor="#9B70F8" />
        <stop offset="0.3211" stopColor="#A996F6" />
        <stop offset="0.3935" stopColor="#B2ADF5" />
        <stop offset="0.4385" stopColor="#B5B6F5" />
        <stop offset="0.5351" stopColor="#A4ABF5" />
        <stop offset="0.8463" stopColor="#728AF3" />
        <stop offset="1" stopColor="#5F7DF3" />
      </linearGradient>
      <linearGradient id="paint14_linear_1415_146431" x1="-0.23405" y1="0.8626" x2="0.79747" y2="0.05228">
        <stop offset="0" stopColor="#2E1877" />
        <stop offset="0.453" stopColor="#2B3282" />
        <stop offset="0.6313" stopColor="#2A3E87" />
        <stop offset="0.6849" stopColor="#38519B" />
        <stop offset="0.7944" stopColor="#5C82D0" />
        <stop offset="0.8712" stopColor="#77A8F8" />
        <stop offset="0.8975" stopColor="#7EADF8" />
        <stop offset="0.9369" stopColor="#93BAF8" />
        <stop offset="0.9843" stopColor="#B4CFF8" />
        <stop offset="1" stopColor="#C1D7F8" />
      </linearGradient>
      <radialGradient id="paint15_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.6219 96.9482) rotate(12.6302) scale(27.2171 51.8078)">
        <stop stopColor="#021277" />
        <stop offset="1" stopColor="#021277" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint16_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(94.9309 50.6024) rotate(-110.711) scale(25.3534 48.1205)">
        <stop stopColor="#9E00F8" stopOpacity="0.2" />
        <stop offset="1" stopColor="#9E00F8" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint17_linear_1415_146431" x1="0.00022" y1="0.49995" x2="1.00003" y2="0.49995">
        <stop stopColor="#66CBFF" />
        <stop offset="0.1235" stopColor="#6FCEFF" />
        <stop offset="0.3275" stopColor="#88D7FF" />
        <stop offset="0.5866" stopColor="#B1E5FF" />
        <stop offset="0.8878" stopColor="#E9F8FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint18_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.0217 294.441) rotate(34.3945) scale(16.7387 7.48771)">
        <stop stopColor="#7BC1FC" />
        <stop offset="0.2405" stopColor="#78BDFA" />
        <stop offset="0.4763" stopColor="#6FB1F3" />
        <stop offset="0.7102" stopColor="#619EE8" />
        <stop offset="0.9417" stopColor="#4C82D9" />
        <stop offset="1" stopColor="#467AD4" />
      </radialGradient>
      <radialGradient id="paint19_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.6072 274.005) rotate(26.7897) scale(15.5068 6.56361)">
        <stop stopColor="#6B79DC" />
        <stop offset="0.1516" stopColor="#6A72D8" />
        <stop offset="0.3671" stopColor="#6660CE" />
        <stop offset="0.6198" stopColor="#6041BD" />
        <stop offset="0.7474" stopColor="#5D2FB3" />
        <stop offset="1" stopColor="#23087A" />
      </radialGradient>
      <linearGradient id="paint20_linear_1415_146431" x1="0.08337" y1="0.39653" x2="1.0269" y2="0.63957">
        <stop offset="0" stopColor="#ED00AE" />
        <stop offset="0.08112" stopColor="#F01BB5" />
        <stop offset="0.2477" stopColor="#F44CC2" />
        <stop offset="0.4128" stopColor="#F876CD" />
        <stop offset="0.5731" stopColor="#FB96D5" />
        <stop offset="0.7272" stopColor="#FDADDB" />
        <stop offset="0.8726" stopColor="#FFBADF" />
        <stop offset="1" stopColor="#FFBFBE" />
      </linearGradient>
      <linearGradient id="paint21_linear_1415_146431" x1="0.08939" y1="0.39226" x2="1.0254" y2="0.63764">
        <stop stopColor="#FFA100" />
        <stop offset="0.1452" stopColor="#FFAB15" />
        <stop offset="0.4463" stopColor="#FFC54C" />
        <stop offset="0.8741" stopColor="#FFEFA4" />
        <stop offset="1" stopColor="#FFFCBF" />
      </linearGradient>
      <linearGradient id="paint22_linear_1415_146431" x1="0.36811" y1="0.13273" x2="0.68374" y2="0.97905">
        <stop stopColor="#BFFFF9" />
        <stop offset="0.213" stopColor="#91F6ED" />
        <stop offset="0.5939" stopColor="#43E6D8" />
        <stop offset="0.8657" stopColor="#13DDCB" />
        <stop offset="1" stopColor="#00D9C6" />
      </linearGradient>
      <linearGradient id="paint23_linear_1415_146431" x1="0.30757" y1="0.1331" x2="0.75623" y2="0.9415">
        <stop stopColor="#3BDBFF" />
        <stop offset="0.1931" stopColor="#35D2FF" />
        <stop offset="0.5076" stopColor="#23BAFF" />
        <stop offset="0.9025" stopColor="#0792FF" />
        <stop offset="0.9963" stopColor="#0088FF" />
      </linearGradient>
      <radialGradient id="paint24_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.774 323.637) rotate(23.1654) scale(50.8355 26.787)">
        <stop stopColor="#0095D1" />
        <stop offset="0.4693" stopColor="#1E429C" />
        <stop offset="0.56" stopColor="#203C93" />
        <stop offset="0.7112" stopColor="#242B79" />
        <stop offset="0.903" stopColor="#2C1050" />
        <stop offset="1" stopColor="#300038" />
      </radialGradient>
      <radialGradient id="paint25_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(78.5504 318.104) rotate(5.51332) scale(65.1819 30.7772)">
        <stop stopColor="#D3F6FF" />
        <stop offset="0.06486" stopColor="#C5ECFE" />
        <stop offset="0.2281" stopColor="#A6D7FD" />
        <stop offset="0.3194" stopColor="#9ACFFC" />
        <stop offset="0.3997" stopColor="#96C2F9" />
        <stop offset="0.5486" stopColor="#8C9FF1" />
        <stop offset="0.6017" stopColor="#8891EE" />
        <stop offset="1" stopColor="#003375" />
      </radialGradient>
      <linearGradient id="paint26_linear_1415_146431" x1="0.49377" y1="1.42797" x2="0.50435" y2="-0.19012">
        <stop offset="0.1819" stopColor="#7594E6" />
        <stop offset="0.3015" stopColor="#6C85DB" />
        <stop offset="0.5298" stopColor="#535FC0" />
        <stop offset="0.8406" stopColor="#2B2293" />
        <stop offset="1" stopColor="#15007A" />
      </linearGradient>
      <radialGradient id="paint27_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(84.3791 283.588) rotate(-0.665014) scale(69.6244 13.6157)">
        <stop offset="0.2384" stopColor="#CFDCFF" />
        <stop offset="0.3192" stopColor="#C5D5FC" />
        <stop offset="0.4582" stopColor="#A9C1F4" />
        <stop offset="0.6372" stopColor="#7DA2E7" />
        <stop offset="0.784" stopColor="#5385DB" />
        <stop offset="1" stopColor="#4148AD" />
      </radialGradient>
      <radialGradient id="paint28_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.634 304.633) rotate(-0.38539) scale(20.9301 4.48724)">
        <stop offset="0.05063" stopColor="#0A4094" />
        <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint29_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(106.945 323.454) rotate(95.6552) scale(20.9663 4.47838)">
        <stop offset="0.05063" stopColor="#0A4094" />
        <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint30_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(95.5498 334.772) rotate(97.9313) scale(20.9651 4.47907)">
        <stop offset="0.1814" stopColor="#002F78" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint31_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.376 313.391) rotate(-0.327308) scale(29.5729 5.3843)">
        <stop offset="0.05063" stopColor="#0A4094" />
        <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint32_linear_1415_146431" x1="0.5021" y1="1.00052" x2="0.5021" y2="0.94614">
        <stop offset="0.1198" stopColor="#000067" stopOpacity="0.6" />
        <stop offset="0.3961" stopColor="#06126A" stopOpacity="0.4117" />
        <stop offset="0.7219" stopColor="#0B206D" stopOpacity="0.1896" />
        <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint33_linear_1415_146431" x1="0.5021" y1="1.00052" x2="0.5021" y2="0.4366">
        <stop stopColor="#000067" stopOpacity="0.6" />
        <stop offset="0.06499" stopColor="#010368" stopOpacity="0.561" />
        <stop offset="0.6457" stopColor="#0A1C6C" stopOpacity="0.2126" />
        <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
      </linearGradient>
      <radialGradient id="paint34_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(351.242 324.711) rotate(-35.1037) scale(86.5267 33.1891)">
        <stop stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <radialGradient id="paint35_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(320.215 307.991) rotate(144.67) scale(93.5635 43.8437)">
        <stop stopColor="#E6E4EE" />
        <stop offset="0.1457" stopColor="#28F7F7" />
        <stop offset="0.5139" stopColor="#15A2EA" />
        <stop offset="0.6289" stopColor="#117AC7" />
        <stop offset="0.7335" stopColor="#0D59AA" />
        <stop offset="0.7371" stopColor="#0D57A9" />
        <stop offset="0.8565" stopColor="#062891" />
        <stop offset="0.9479" stopColor="#020B82" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <linearGradient id="paint36_linear_1415_146431" x1="0.54866" y1="0.60231" x2="0.43974" y2="0.40614">
        <stop offset="0.275" stopColor="#6CB1FF" />
        <stop offset="0.3676" stopColor="#5890DC" />
        <stop offset="0.5277" stopColor="#3A5DA5" />
        <stop offset="0.6767" stopColor="#223579" />
        <stop offset="0.8097" stopColor="#10185A" />
        <stop offset="0.922" stopColor="#060647" />
        <stop offset="1" stopColor="#020040" />
      </linearGradient>
      <linearGradient id="paint37_linear_1415_146431" x1="-0.00021" y1="0.49967" x2="0.99956" y2="0.49967">
        <stop stopColor="#3C67ED" />
        <stop offset="0.2955" stopColor="#7E99F5" />
        <stop offset="0.6397" stopColor="#CFD6FF" />
        <stop offset="0.6759" stopColor="#CBD2FF" />
        <stop offset="0.7117" stopColor="#BFC7FE" />
        <stop offset="0.7473" stopColor="#ABB5FD" />
        <stop offset="0.7828" stopColor="#909BFB" />
        <stop offset="0.8179" stopColor="#6C7AF8" />
        <stop offset="0.8207" stopColor="#6977F8" />
        <stop offset="0.9851" stopColor="#130073" />
      </linearGradient>
      <linearGradient id="paint38_linear_1415_146431" x1="0.91091" y1="0.90725" x2="-0.05495" y2="0.17618">
        <stop stopColor="#33057C" />
        <stop offset="0.147" stopColor="#291482" />
        <stop offset="0.4292" stopColor="#0D3B92" />
        <stop offset="0.4595" stopColor="#0A4094" />
        <stop offset="0.6135" stopColor="#0E67B7" />
        <stop offset="0.8199" stopColor="#15A2EA" />
        <stop offset="0.9255" stopColor="#B9D9EA" />
      </linearGradient>
      <radialGradient id="paint39_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(297.163 35.3496) rotate(-145.218) scale(68.0714 23.6497)">
        <stop offset="0" stopColor="#F7F7F7" stopOpacity="0.9" />
        <stop offset="1" stopColor="#EEC8F7" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint40_linear_1415_146431" x1="0.50891" y1="0.99961" x2="0.48932" y2="-0.00035">
        <stop offset="0.275" stopColor="#0379FF" />
        <stop offset="0.3676" stopColor="#0363DC" />
        <stop offset="0.5277" stopColor="#0340A5" />
        <stop offset="0.6767" stopColor="#022479" />
        <stop offset="0.8097" stopColor="#02105A" />
        <stop offset="0.922" stopColor="#020447" />
        <stop offset="1" stopColor="#020040" />
      </linearGradient>
      <radialGradient id="paint41_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(354.641 170.366) scale(51.9403 105.42)">
        <stop stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint42_linear_1415_146431" x1="0.50021" y1="1.00062" x2="0.50021" y2="-0.00503">
        <stop stopColor="#FD3390" />
        <stop offset="0.1181" stopColor="#FD3C96" />
        <stop offset="0.315" stopColor="#FD56A8" />
        <stop offset="0.5664" stopColor="#FE80C4" />
        <stop offset="0.8595" stopColor="#FFB9EB" />
        <stop offset="1" stopColor="#FFD7FF" />
      </linearGradient>
      <linearGradient id="paint43_linear_1415_146431" x1="-0.00062" y1="0.49819" x2="1.00114" y2="0.49819">
        <stop stopColor="#FDFB6A" />
        <stop offset="0.2044" stopColor="#FDFB6D" />
        <stop offset="0.3673" stopColor="#FDFB77" />
        <stop offset="0.5161" stopColor="#FDFC88" />
        <stop offset="0.6563" stopColor="#FEFCA0" />
        <stop offset="0.7905" stopColor="#FEFDBE" />
        <stop offset="0.9185" stopColor="#FFFEE3" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint44_linear_1415_146431" x1="0.50021" y1="1.00071" x2="0.50021" y2="-0.00494">
        <stop stopColor="#00D9C6" />
        <stop offset="0.1298" stopColor="#24E0D0" />
        <stop offset="0.3499" stopColor="#5BEBDE" />
        <stop offset="0.5546" stopColor="#86F4EA" />
        <stop offset="0.7376" stopColor="#A5FAF2" />
        <stop offset="0.8923" stopColor="#B8FEF7" />
        <stop offset="1" stopColor="#BFFFF9" />
      </linearGradient>
      <radialGradient id="paint45_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(313.284 281.034) rotate(143.697) scale(68.9908 28.6169)">
        <stop stopColor="#A1EFFF" />
        <stop offset="0.2272" stopColor="#4EA8EE" />
        <stop offset="0.6417" stopColor="#0A4094" />
        <stop offset="0.9244" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint46_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(321.735 308.412) rotate(-35.0497) scale(114.493 57.4569)">
        <stop stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint47_linear_1415_146431" x1="-0.32173" y1="0.50157" x2="1.43177" y2="0.49939">
        <stop offset="0.05959" stopColor="#130073" />
        <stop offset="0.273" stopColor="#171D81" />
        <stop offset="0.7325" stopColor="#1F66A3" />
        <stop offset="1" stopColor="#2593B8" />
      </linearGradient>
      <linearGradient id="paint48_linear_1415_146431" x1="1.29299" y1="0.81435" x2="0.07279" y2="0.14232">
        <stop offset="0" stopColor="#210C63" />
        <stop offset="0.1568" stopColor="#201066" />
        <stop offset="0.3124" stopColor="#1B1C70" />
        <stop offset="0.4677" stopColor="#153081" />
        <stop offset="0.6217" stopColor="#0B4C99" />
        <stop offset="0.7591" stopColor="#006BB3" />
        <stop offset="0.7995" stopColor="#0D73B7" />
        <stop offset="0.8746" stopColor="#308AC2" />
        <stop offset="0.9755" stopColor="#68AED4" />
        <stop offset="1" stopColor="#77B7D9" />
      </linearGradient>
      <radialGradient id="paint49_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(324.561 82.5819) rotate(141.113) scale(25.7534 70.3222)">
        <stop stopColor="#021277" />
        <stop offset="0.7854" stopColor="#021277" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint50_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(337.314 234.209) rotate(178.82) scale(45.2457 114.567)">
        <stop stopColor="#FF9EEC" stopOpacity="0.3" />
        <stop offset="0.7542" stopColor="#FF9EEC" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint51_linear_1415_146431" x1="0.99907" y1="0.49935" x2="-0.00049" y2="0.49935">
        <stop stopColor="#66CBFF" stopOpacity="0" />
        <stop offset="0.2322" stopColor="#85D6FF" stopOpacity="0.2331" />
        <stop offset="0.7374" stopColor="#D4F0FF" stopOpacity="0.7402" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint52_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(317.51 294.395) rotate(-6.64173) scale(8.90798 5.64536)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint53_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(334.661 281.97) rotate(-9.99082) scale(8.80099 5.61103)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint54_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(351.869 268.88) rotate(-9.30962) scale(8.69999 5.57524)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint55_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(315.447 293.041) scale(8.49774 5.11725)">
        <stop stopColor="#BFFFF9" />
        <stop offset="0.09361" stopColor="#B7FBF8" />
        <stop offset="0.2353" stopColor="#A3F0F4" />
        <stop offset="0.4074" stopColor="#80DDEF" />
        <stop offset="0.6034" stopColor="#51C4E7" />
        <stop offset="0.8167" stopColor="#15A3DD" />
        <stop offset="0.886" stopColor="#0098D9" />
        <stop offset="1" stopColor="#005CA8" />
      </radialGradient>
      <radialGradient id="paint56_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(333.151 280.84) rotate(-6.64092) scale(8.15585 5.169)">
        <stop offset="0" stopColor="#FFBFDD" />
        <stop offset="0.1284" stopColor="#FFBBDB" />
        <stop offset="0.255" stopColor="#FFAFD6" />
        <stop offset="0.3811" stopColor="#FF9BCE" />
        <stop offset="0.5068" stopColor="#FF80C2" />
        <stop offset="0.6323" stopColor="#FF5CB3" />
        <stop offset="0.7559" stopColor="#FF30A1" />
        <stop offset="0.8734" stopColor="#FF008C" />
        <stop offset="1" stopColor="#A400AD" />
      </radialGradient>
      <radialGradient id="paint57_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(350.342 267.847) rotate(-6.64092) scale(8.15585 5.169)">
        <stop stopColor="#FFFCBF" />
        <stop offset="0.1278" stopColor="#FFFABB" />
        <stop offset="0.254" stopColor="#FFF4AF" />
        <stop offset="0.3795" stopColor="#FFEB9B" />
        <stop offset="0.5047" stopColor="#FFDE80" />
        <stop offset="0.6296" stopColor="#FFCD5C" />
        <stop offset="0.7527" stopColor="#FFB830" />
        <stop offset="0.8696" stopColor="#FFA100" />
        <stop offset="1" stopColor="#AD6800" />
      </radialGradient>
      <linearGradient id="paint58_linear_1415_146431" x1="0.50019" y1="0.99979" x2="0.50019" y2="-0.00006">
        <stop offset="0" stopColor="#006E8C" />
        <stop offset="1" stopColor="#006E8C" stopOpacity="0" />
      </linearGradient>
      <radialGradient id="paint59_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.037 301.752) rotate(69.5752) scale(3.83202 5.14587)">
        <stop stopColor="#FCFEFF" />
        <stop offset="0.5536" stopColor="#5D96D4" />
        <stop offset="0.6858" stopColor="#437CC0" />
        <stop offset="1" stopColor="#0A4094" />
      </radialGradient>
      <radialGradient id="paint60_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(112.475 343.959) scale(3.64522 3.65157)">
        <stop stopColor="#5D96D4" />
        <stop offset="0.174" stopColor="#538BCA" />
        <stop offset="0.4813" stopColor="#386DAF" />
        <stop offset="0.8831" stopColor="#0E3D83" />
        <stop offset="1" stopColor="#002E75" />
      </radialGradient>
      <radialGradient id="paint61_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(126.772 310.432) scale(4.03387 4.05461)">
        <stop stopColor="#FCFEFF" />
        <stop offset="0.5536" stopColor="#5D96D4" />
        <stop offset="0.6858" stopColor="#437CC0" />
        <stop offset="1" stopColor="#0A4094" />
      </radialGradient>
      <radialGradient id="paint62_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(158.473 362.898) scale(4.23469 2.73062)">
        <stop stopColor="#5D96D4" />
        <stop offset="0.174" stopColor="#538BCA" />
        <stop offset="0.4813" stopColor="#386DAF" />
        <stop offset="0.8831" stopColor="#0E3D83" />
        <stop offset="1" stopColor="#002E75" />
      </radialGradient>
      <radialGradient id="paint63_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(293.947 360.481) scale(241.069 32.9149)">
        <stop stopColor="#66ABFF" />
        <stop offset="1" stopColor="#162E6F" />
      </radialGradient>
      <linearGradient id="paint64_linear_1415_146431" x1="-0.00075" y1="0.49977" x2="0.99976" y2="0.49977">
        <stop offset="0" stopColor="#4F6AD6" />
        <stop offset="0.3007" stopColor="#5684E1" />
        <stop offset="0.9419" stopColor="#69C8FC" />
        <stop offset="1" stopColor="#6BCEFF" />
      </linearGradient>
      <linearGradient id="paint65_linear_1415_146431" x1="0.77869" y1="0.13158" x2="0.38794" y2="0.86784">
        <stop stopColor="#CFEFFF" />
        <stop offset="1" stopColor="#3C88FC" />
      </linearGradient>
      <linearGradient id="paint66_linear_1415_146431" x1="0.00186" y1="0.49665" x2="1.00161" y2="0.49665">
        <stop offset="0.1819" stopColor="#7594E6" />
        <stop offset="0.3015" stopColor="#6C85DB" />
        <stop offset="0.5298" stopColor="#535FC0" />
        <stop offset="0.8406" stopColor="#2B2293" />
        <stop offset="1" stopColor="#15007A" />
      </linearGradient>
      <linearGradient id="paint67_linear_1415_146431" x1="0.09694" y1="0.22629" x2="0.91382" y2="0.74118">
        <stop offset="0.01441" stopColor="#FFC4FE" />
        <stop offset="0.1324" stopColor="#F3A7F1" />
        <stop offset="0.4419" stopColor="#D460D3" />
        <stop offset="0.6998" stopColor="#BE2CBC" />
        <stop offset="0.8932" stopColor="#B00CAE" />
        <stop offset="1" stopColor="#AB00A9" />
      </linearGradient>
      <linearGradient id="paint68_linear_1415_146431" x1="0.21561" y1="0.16398" x2="0.79915" y2="0.86618">
        <stop stopColor="#FED2FD" />
        <stop offset="0.1403" stopColor="#FAC8FD" />
        <stop offset="0.3761" stopColor="#EEAEFC" />
        <stop offset="0.6786" stopColor="#DC84FA" />
        <stop offset="1" stopColor="#C54FF8" />
      </linearGradient>
      <linearGradient id="paint69_linear_1415_146431" x1="0.00028" y1="0.50035" x2="0.99978" y2="0.50035">
        <stop offset="0" stopColor="#002673" />
        <stop offset="0.04235" stopColor="#104288" />
        <stop offset="0.1333" stopColor="#3079B2" />
        <stop offset="0.2178" stopColor="#48A5D3" />
        <stop offset="0.2933" stopColor="#5AC4EB" />
        <stop offset="0.357" stopColor="#65D7FA" />
        <stop offset="0.4013" stopColor="#69DEFF" />
        <stop offset="0.5893" stopColor="#8CE5FF" />
        <stop offset="0.8627" stopColor="#BAEFFF" />
        <stop offset="1" stopColor="#CCF3FF" />
      </linearGradient>
      <linearGradient id="paint70_linear_1415_146431" x1="0.27904" y1="0.20113" x2="0.87433" y2="0.99945">
        <stop stopColor="#CCF3FF" />
        <stop offset="0.1232" stopColor="#B0EDFF" />
        <stop offset="0.3864" stopColor="#69DEFF" />
        <stop offset="0.4693" stopColor="#64D8F9" />
        <stop offset="0.5824" stopColor="#57C7E7" />
        <stop offset="0.713" stopColor="#42AACB" />
        <stop offset="0.8565" stopColor="#2483A3" />
        <stop offset="1" stopColor="#005473" />
      </linearGradient>
      <linearGradient id="paint71_linear_1415_146431" x1="0.06512" y1="0.25915" x2="0.93695" y2="0.74064">
        <stop stopColor="#CCF3FF" />
        <stop offset="0.1232" stopColor="#B0EDFF" />
        <stop offset="0.3864" stopColor="#69DEFF" />
        <stop offset="0.4653" stopColor="#65D8FB" />
        <stop offset="0.5731" stopColor="#59C6EF" />
        <stop offset="0.6976" stopColor="#44AADD" />
        <stop offset="0.8345" stopColor="#2981C2" />
        <stop offset="0.9801" stopColor="#054FA1" />
        <stop offset="1" stopColor="#00479C" />
      </linearGradient>
      <radialGradient id="paint72_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(284.086 315.685) scale(4.25115 19.6205)">
        <stop stopColor="#AE5AE6" />
        <stop offset="0.1811" stopColor="#944FCC" />
        <stop offset="0.57" stopColor="#513288" />
        <stop offset="1" stopColor="#000F36" />
      </radialGradient>
      <radialGradient id="paint73_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.654 315.673) scale(3.98514 16.9799)">
        <stop offset="0" stopColor="#00A4DB" />
        <stop offset="0.1147" stopColor="#029ED7" />
        <stop offset="0.2633" stopColor="#078ECD" />
        <stop offset="0.4304" stopColor="#0F73BC" />
        <stop offset="0.61" stopColor="#1A4FA4" />
        <stop offset="0.6637" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint74_linear_1415_146431" x1="-0.09025" y1="0.30802" x2="1.09427" y2="0.69919">
        <stop offset="0.01441" stopColor="#FFC4FE" />
        <stop offset="0.04985" stopColor="#FFBEFE" />
        <stop offset="0.7069" stopColor="#FA56F7" />
        <stop offset="1" stopColor="#F82DF5" />
      </linearGradient>
      <linearGradient id="paint75_linear_1415_146431" x1="-0.10148" y1="0.34883" x2="1.0838" y2="0.65071">
        <stop offset="0.01441" stopColor="#D6FDFF" />
        <stop offset="0.1408" stopColor="#C2FBFE" />
        <stop offset="0.4009" stopColor="#8DF7FD" />
        <stop offset="0.7685" stopColor="#38F0FA" />
        <stop offset="1" stopColor="#00ECF8" />
      </linearGradient>
      <radialGradient id="paint76_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(274.141 286.538) scale(63.7565 10.6403)">
        <stop offset="0.01441" stopColor="#D1E9F3" />
        <stop offset="0.4998" stopColor="#69CAF8" />
        <stop offset="1" stopColor="#27277D" />
      </radialGradient>
      <radialGradient id="paint77_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(186.927 328.905) rotate(-0.656864) scale(92.4846 33.7895)">
        <stop offset="0.05063" stopColor="#0A4094" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint78_linear_1415_146431" x1="0.50028" y1="1.13937" x2="0.49888" y2="-0.11433">
        <stop offset="0" stopColor="#69AAFF" />
        <stop offset="0.06479" stopColor="#7BB5FF" />
        <stop offset="0.2341" stopColor="#A3CDFF" />
        <stop offset="0.3999" stopColor="#C4E1FF" />
        <stop offset="0.5594" stopColor="#DAEFFF" />
        <stop offset="0.7095" stopColor="#E8F7FF" />
        <stop offset="0.8408" stopColor="#EDFAFF" />
        <stop offset="0.8506" stopColor="#E8F9FF" />
        <stop offset="0.8906" stopColor="#D8F6FF" />
        <stop offset="0.9364" stopColor="#CFF4FF" />
        <stop offset="1" stopColor="#CCF3FF" />
      </linearGradient>
      <linearGradient id="paint79_linear_1415_146431" x1="0.50069" y1="1.09135" x2="0.49278" y2="-0.08856">
        <stop offset="0" stopColor="#2E1877" />
        <stop offset="0.06145" stopColor="#30207C" />
        <stop offset="0.1544" stopColor="#37348C" />
        <stop offset="0.2672" stopColor="#4356A4" />
        <stop offset="0.3956" stopColor="#5286C7" />
        <stop offset="0.5355" stopColor="#66C2F2" />
        <stop offset="0.5538" stopColor="#69CAF8" />
        <stop offset="0.9856" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint80_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(149.248 303.017) scale(14.8375 4.01607)">
        <stop stopColor="#BA87FF" />
        <stop offset="0.1721" stopColor="#AB78EF" />
        <stop offset="0.5094" stopColor="#8451C5" />
        <stop offset="0.9753" stopColor="#451281" />
        <stop offset="1" stopColor="#420F7D" />
      </radialGradient>
      <linearGradient id="paint81_linear_1415_146431" x1="0.50049" y1="1.00097" x2="0.50049" y2="-0.00737">
        <stop offset="0" stopColor="#67A3FC" />
        <stop offset="0.1558" stopColor="#6CA7FC" />
        <stop offset="0.3518" stopColor="#7CB1FD" />
        <stop offset="0.5694" stopColor="#96C2FD" />
        <stop offset="0.8008" stopColor="#B9DAFE" />
        <stop offset="0.9856" stopColor="#DBF1FF" />
      </linearGradient>
      <radialGradient id="paint82_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(188.084 303.041) scale(16.5995 4.22859)">
        <stop offset="0" stopColor="#00A4DB" />
        <stop offset="0.1147" stopColor="#029ED7" />
        <stop offset="0.2633" stopColor="#078ECD" />
        <stop offset="0.4304" stopColor="#0F73BC" />
        <stop offset="0.61" stopColor="#1A4FA4" />
        <stop offset="0.6637" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <radialGradient id="paint83_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(226.92 303.047) scale(14.676 3.80623)">
        <stop stopColor="#BA87FF" />
        <stop offset="0.1721" stopColor="#AB78EF" />
        <stop offset="0.5094" stopColor="#8451C5" />
        <stop offset="0.9753" stopColor="#451281" />
        <stop offset="1" stopColor="#420F7D" />
      </radialGradient>
      <linearGradient id="paint84_linear_1415_146431" x1="0.49954" y1="1.00097" x2="0.49954" y2="-0.00737">
        <stop offset="0" stopColor="#67A3FC" />
        <stop offset="0.1558" stopColor="#6CA7FC" />
        <stop offset="0.3518" stopColor="#7CB1FD" />
        <stop offset="0.5694" stopColor="#96C2FD" />
        <stop offset="0.8008" stopColor="#B9DAFE" />
        <stop offset="0.9856" stopColor="#DBF1FF" />
      </linearGradient>
      <radialGradient id="paint85_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(188.889 303.586) rotate(31.2831) scale(4.93828 4.65753)">
        <stop offset="0.4126" stopColor="#001959" />
        <stop offset="0.8768" stopColor="#001959" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint86_linear_1415_146431" x1="0.09487" y1="0.20593" x2="0.90266" y2="0.79089">
        <stop offset="0.01441" stopColor="white" />
        <stop offset="0.1866" stopColor="#EBF2FE" />
        <stop offset="0.5409" stopColor="#B6D2FC" />
        <stop offset="1" stopColor="#69A2F8" />
      </linearGradient>
      <radialGradient id="paint87_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(180.695 266.842) scale(23.7967 4.54118)">
        <stop stopColor="#BEE0EE" />
        <stop offset="0.1263" stopColor="#B4D7E8" />
        <stop offset="0.3432" stopColor="#98BEDA" />
        <stop offset="0.6247" stopColor="#6B95C2" />
        <stop offset="0.9565" stopColor="#2E5EA1" />
        <stop offset="1" stopColor="#25569C" />
      </radialGradient>
      <radialGradient id="paint88_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.928 294.669) rotate(1.89587) scale(45.2919 9.80445)">
        <stop stopColor="#0A4094" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint89_linear_1415_146431" x1="0.00084" y1="0.50004" x2="0.99949" y2="0.50004">
        <stop stopColor="#66CBFF" />
        <stop offset="0.0938" stopColor="#77D1FF" />
        <stop offset="0.5283" stopColor="#C0E9FF" />
        <stop offset="0.8401" stopColor="#EDF9FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint90_linear_1415_146431" x1="0.53154" y1="0.21757" x2="0.45133" y2="0.94198">
        <stop stopColor="#66CBFF" />
        <stop offset="0.0938" stopColor="#77D1FF" />
        <stop offset="0.5283" stopColor="#C0E9FF" />
        <stop offset="0.8401" stopColor="#EDF9FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint91_linear_1415_146431" x1="0.08572" y1="1.47934" x2="0.86567" y2="-0.13518">
        <stop stopColor="#6BB4F3" />
        <stop offset="1" stopColor="#00356E" />
      </linearGradient>
      <linearGradient id="paint92_linear_1415_146431" x1="0.26422" y1="-0.36033" x2="0.70987" y2="1.75113">
        <stop offset="0.01441" stopColor="white" />
        <stop offset="0.2348" stopColor="#FCFDFF" />
        <stop offset="0.3949" stopColor="#F3F7FE" />
        <stop offset="0.5359" stopColor="#E3EEFE" />
        <stop offset="0.6658" stopColor="#CDE0FD" />
        <stop offset="0.7883" stopColor="#B0CEFB" />
        <stop offset="0.9034" stopColor="#8DB8FA" />
        <stop offset="1" stopColor="#69A2F8" />
      </linearGradient>
      <linearGradient id="paint93_linear_1415_146431" x1="0.52334" y1="1.13023" x2="0.49601" y2="-0.18311">
        <stop stopColor="#66CBFF" />
        <stop offset="0.2751" stopColor="#92D4F9" />
        <stop offset="0.6" stopColor="#BFDCF3" />
        <stop offset="0.8512" stopColor="#DBE2EF" />
        <stop offset="0.9963" stopColor="#E6E4EE" />
      </linearGradient>
      <linearGradient id="paint94_linear_1415_146431" x1="0.53421" y1="0.93526" x2="0.49585" y2="-0.10308">
        <stop stopColor="#66CBFF" />
        <stop offset="0.1422" stopColor="#8AD7FF" />
        <stop offset="0.3306" stopColor="#B4E6FF" />
        <stop offset="0.5146" stopColor="#D5F1FF" />
        <stop offset="0.6906" stopColor="#ECF9FF" />
        <stop offset="0.855" stopColor="#FAFDFF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint95_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(82.8979 293.69) rotate(-0.665007) scale(31.716 6.20231)">
        <stop stopColor="#CFDCFF" />
        <stop offset="0.1292" stopColor="#C9D6FB" />
        <stop offset="0.309" stopColor="#B8C4F1" />
        <stop offset="0.5185" stopColor="#9BA6E1" />
        <stop offset="0.7504" stopColor="#747DCA" />
        <stop offset="0.9982" stopColor="#4148AD" />
        <stop offset="1" stopColor="#4148AD" />
      </radialGradient>
      <linearGradient id="paint96_linear_1415_146431" x1="0.49146" y1="-0.01841" x2="0.54168" y2="1.54196">
        <stop offset="0" stopColor="#2E1877" />
        <stop offset="0.2066" stopColor="#4153A2" />
        <stop offset="0.5239" stopColor="#5EA8DF" />
        <stop offset="0.6685" stopColor="#69CAF8" />
        <stop offset="0.7443" stopColor="#7ED1F8" />
        <stop offset="0.8995" stopColor="#B2E2F8" />
        <stop offset="1" stopColor="#D8EEF8" />
      </linearGradient>
      <linearGradient id="paint97_linear_1415_146431" x1="0.50543" y1="1.63184" x2="0.49742" y2="-0.71456">
        <stop stopColor="#B6D7F3" />
        <stop offset="0.2304" stopColor="#6BB4F3" />
        <stop offset="1" stopColor="#00356E" />
      </linearGradient>
      <linearGradient id="paint98_linear_1415_146431" x1="0.50025" y1="1.00323" x2="0.50025" y2="0.00753">
        <stop offset="0" stopColor="#FF63B6" />
        <stop offset="0.1947" stopColor="#FF5FB4" />
        <stop offset="0.3982" stopColor="#FF52AE" />
        <stop offset="0.6059" stopColor="#FF3DA5" />
        <stop offset="0.815" stopColor="#FF2098" />
        <stop offset="1" stopColor="#FF0089" />
      </linearGradient>
      <linearGradient id="paint99_linear_1415_146431" x1="0.49986" y1="1.0038" x2="0.49986" y2="0.0095">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient id="paint100_linear_1415_146431" x1="0.50094" y1="1.00323" x2="0.50094" y2="0.00753">
        <stop stopColor="#FEFFEB" />
        <stop offset="0.3836" stopColor="#E7F148" />
        <stop offset="0.7478" stopColor="#F6CF1B" />
        <stop offset="1" stopColor="#FFBB00" />
      </linearGradient>
      <linearGradient id="paint101_linear_1415_146431" x1="0.49971" y1="1.00323" x2="0.49971" y2="0.00753">
        <stop stopColor="#00FFEA" />
        <stop offset="0.1191" stopColor="#00F2E6" />
        <stop offset="0.3374" stopColor="#00D1DA" />
        <stop offset="0.6307" stopColor="#009BC8" />
        <stop offset="0.9844" stopColor="#0051AE" />
        <stop offset="1" stopColor="#004EAD" />
      </linearGradient>
      <linearGradient id="paint102_linear_1415_146431" x1="0.49986" y1="1.0038" x2="0.49986" y2="0.0095">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="0.9963" stopColor="white" stopOpacity="0.9" />
      </linearGradient>
      <linearGradient id="paint103_linear_1415_146431" x1="0.49978" y1="1.0038" x2="0.49978" y2="0.0095">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient id="paint104_linear_1415_146431" x1="-0.00005" y1="0.49677" x2="0.99988" y2="0.49677">
        <stop offset="0.00372" stopColor="#E6E4EE" />
        <stop offset="0.21" stopColor="#E3E3EE" />
        <stop offset="0.3889" stopColor="#D8DFF0" />
        <stop offset="0.5576" stopColor="#C6D8F3" />
        <stop offset="0.72" stopColor="#ADCFF6" />
        <stop offset="0.8766" stopColor="#8DC3FB" />
        <stop offset="1" stopColor="#6EB7FF" />
      </linearGradient>
      <linearGradient id="paint105_linear_1415_146431" x1="0.51198" y1="1.36939" x2="0.46727" y2="-0.26858">
        <stop offset="0" stopColor="#CEDFF8" />
        <stop offset="0.1885" stopColor="#E3EDFB" />
        <stop offset="0.4034" stopColor="#F3F7FD" />
        <stop offset="0.6483" stopColor="#FCFDFF" />
        <stop offset="0.9856" stopColor="white" />
      </linearGradient>
      <linearGradient id="svg_3" x1="1.00394" y1="-0.75975" x2="0.01856" y2="1.89893">
        <stop stopColor="#001764" />
        <stop offset="0.4525" stopColor="#404A9F" stopOpacity="0.7315" />
        <stop offset="1" stopColor="#A69CFF" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient id="svg_2" x1="0.51209" y1="1.36875" x2="0.46738" y2="-0.26919">
        <stop offset="0" stopColor="#CEDFF8" />
        <stop offset="0.1885" stopColor="#E3EDFB" />
        <stop offset="0.4034" stopColor="#F3F7FD" />
        <stop offset="0.6483" stopColor="#FCFDFF" />
        <stop offset="0.9856" stopColor="white" />
      </linearGradient>
      <linearGradient id="svg_8" x1="0.29156" y1="1.75478" x2="0.74357" y2="-0.36117">
        <stop offset="0" stopColor="#41DEFF" stopOpacity="0.3" />
        <stop offset="0.09363" stopColor="#37BDE8" stopOpacity="0.3655" />
        <stop offset="0.2719" stopColor="#2784C0" stopOpacity="0.4903" />
        <stop offset="0.4449" stopColor="#19559F" stopOpacity="0.6114" />
        <stop offset="0.6082" stopColor="#0E3086" stopOpacity="0.7258" />
        <stop offset="0.7597" stopColor="#061673" stopOpacity="0.8318" />
        <stop offset="0.8948" stopColor="#020668" stopOpacity="0.9263" />
        <stop offset="1" stopColor="#000064" />
      </linearGradient>
      <linearGradient id="svg_7" x1="0.35772" y1="1.72673" x2="0.69671" y2="-0.41642">
        <stop stopColor="#41DEFF" stopOpacity="0.2" />
        <stop offset="1" stopColor="#000064" />
      </linearGradient>
      <linearGradient id="svg_6" x1="0.26458" y1="-0.36354" x2="0.71439" y2="1.74158">
        <stop offset="0.01441" stopColor="white" />
        <stop offset="0.2348" stopColor="#FCFDFF" />
        <stop offset="0.3949" stopColor="#F3F7FE" />
        <stop offset="0.5359" stopColor="#E3EEFE" />
        <stop offset="0.6658" stopColor="#CDE0FD" />
        <stop offset="0.7883" stopColor="#B0CEFB" />
        <stop offset="0.9034" stopColor="#8DB8FA" />
        <stop offset="1" stopColor="#69A2F8" />
      </linearGradient>
      <linearGradient id="svg_11" x1="0.59278" y1="1.34739" x2="0.46184" y2="-0.55183">
        <stop stopColor="#000064" />
        <stop offset="0.1033" stopColor="#040E6E" stopOpacity="0.9277" />
        <stop offset="0.2992" stopColor="#0F3388" stopOpacity="0.7906" />
        <stop offset="0.567" stopColor="#216FB2" stopOpacity="0.6031" />
        <stop offset="0.8931" stopColor="#39C2EB" stopOpacity="0.3748" />
        <stop offset="1" stopColor="#41DEFF" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient id="svg_10" x1="0.00002" y1="0.50024" x2="1.00009" y2="0.50024">
        <stop offset="0" stopColor="#A7BEE8" />
        <stop offset="0.2669" stopColor="#BACCED" />
        <stop offset="0.8064" stopColor="#ECF1FA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient y2="0" x2="0.49998" y1="1.00027" x1="0.49998" id="svg_188">
        <stop stopColor="#12E7FA" offset="0" />
        <stop stopColor="#0CC1FA" offset="0.09059" />
        <stop stopColor="#07A2F9" offset="0.1766" />
        <stop stopColor="#0597F9" offset="0.225" />
        <stop stopColor="#058CEF" offset="0.3219" />
        <stop stopColor="#046ED2" offset="0.4937" />
        <stop stopColor="#033EA5" offset="0.7183" />
        <stop stopColor="#020D78" offset="0.9181" />
      </linearGradient>
      <linearGradient y2="0.49995" x2="1.00003" y1="0.49995" x1="-0.00003" id="svg_187">
        <stop stopColor="#D4FFFF" offset="0" />
        <stop stopColor="#B6FAFC" offset="0.04513" />
        <stop stopColor="#86F1F8" offset="0.1272" />
        <stop stopColor="#67ECF5" offset="0.1897" />
        <stop stopColor="#5CEAF4" offset="0.225" />
        <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.3154" />
        <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.4757" />
        <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.687" />
        <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9386" />
        <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
      </linearGradient>
      <linearGradient y2="0.43375" x2="7.46259" y1="0.56578" x1="-6.44461" id="svg_186">
        <stop stopColor="#D4FFFF" offset="0" />
        <stop stopColor="#B6FAFC" offset="0.04513" />
        <stop stopColor="#86F1F8" offset="0.1272" />
        <stop stopColor="#67ECF5" offset="0.1897" />
        <stop stopColor="#5CEAF4" offset="0.225" />
        <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.3154" />
        <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.4757" />
        <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.687" />
        <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9386" />
        <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
      </linearGradient>
      <linearGradient y2="0.49995" x2="0.99968" y1="0.49995" x1="-0.00038" id="svg_185">
        <stop stopOpacity="0" stopColor="#1D3CDD" offset="0" />
        <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.06138" />
        <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.313" />
        <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.5243" />
        <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.6846" />
        <stop stopColor="#5CEAF4" offset="0.775" />
        <stop stopColor="#67ECF5" offset="0.8103" />
        <stop stopColor="#86F1F8" offset="0.8728" />
        <stop stopColor="#B6FAFC" offset="0.9549" />
        <stop stopColor="#D4FFFF" offset="1" />
      </linearGradient>
      <linearGradient y2="0.49972" x2="0.99968" y1="0.49972" x1="-0.00006" id="svg_184">
        <stop stopOpacity="0" stopColor="#1D3CDD" offset="0" />
        <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.06137" />
        <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.313" />
        <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.5243" />
        <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.6846" />
        <stop stopColor="#5CEAF4" offset="0.775" />
        <stop stopColor="#67ECF5" offset="0.8103" />
        <stop stopColor="#86F1F8" offset="0.8728" />
        <stop stopColor="#B6FAFC" offset="0.9549" />
        <stop stopColor="#D4FFFF" offset="1" />
      </linearGradient>
      <linearGradient y2="0.49663" x2="0.99822" y1="0.49663" x1="-0.00102" id="svg_182">
        <stop stopOpacity="0" stopColor="#1D3CDD" offset="0" />
        <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.07919" />
        <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.4039" />
        <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.6765" />
        <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.8833" />
        <stop stopColor="#5CEAF4" offset="1" />
      </linearGradient>
      <linearGradient y2="0.50234" x2="0.99887" y1="0.50234" x1="-0.0024" id="svg_181">
        <stop stopColor="#5CEAF4" />
        <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.1167" />
        <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.3235" />
        <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.5961" />
        <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9208" />
        <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
      </linearGradient>
      <linearGradient y2="0.50001" x2="1.00001" y1="0.50001" x1="0.00002" id="svg_180">
        <stop stopColor="#D4FFFF" offset="0" />
        <stop stopColor="#B6FAFC" offset="0.04513" />
        <stop stopColor="#86F1F8" offset="0.1272" />
        <stop stopColor="#67ECF5" offset="0.1897" />
        <stop stopColor="#5CEAF4" offset="0.225" />
        <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.3154" />
        <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.4757" />
        <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.687" />
        <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9386" />
        <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
      </linearGradient>
      <linearGradient y2="0.47947" x2="1.00099" y1="0.5216" x1="-0.00016" id="svg_196">
        <stop stopColor="#D4FFFF" offset="0" />
        <stop stopColor="#B6FAFC" offset="0.04513" />
        <stop stopColor="#86F1F8" offset="0.1272" />
        <stop stopColor="#67ECF5" offset="0.1897" />
        <stop stopColor="#5CEAF4" offset="0.225" />
        <stop stopColor="#46CEEF" offset="0.4413" />
        <stop stopColor="#0D85E3" offset="0.9071" />
        <stop stopColor="#0176E0" offset="1" />
      </linearGradient>
      <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_195">
        <stop stopColor="#30C7EF" />
        <stop stopColor="#01B0EF" offset="0.9963" />
      </linearGradient>
      <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_193">
        <stop stopColor="#1042AF" />
        <stop stopColor="#003593" offset="1" />
      </linearGradient>
      <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_192">
        <stop stopColor="#019CC9" offset="0" />
        <stop stopColor="#103199" offset="0.9963" />
      </linearGradient>
      <linearGradient y2="1.14649" x2="0.45832" y1="-0.13067" x1="0.53234" id="svg_191">
        <stop stopOpacity="0" stopColor="#66CBFF" offset="0.5475" />
        <stop stopOpacity="0.124" stopColor="#6FCEFF" offset="0.6031" />
        <stop stopOpacity="0.3287" stopColor="#88D7FF" offset="0.695" />
        <stop stopOpacity="0.5888" stopColor="#B1E5FF" offset="0.8117" />
        <stop stopOpacity="0.8911" stopColor="#E9F8FF" offset="0.9474" />
        <stop stopColor="white" offset="0.9963" />
      </linearGradient>
      <linearGradient y2="-0.04954" x2="0.94397" y1="1.04816" x1="0.07202" id="svg_204">
        <stop stopColor="white" />
        <stop stopColor="#FFF6FC" offset="0.01685" />
        <stop stopColor="#FDBAE9" offset="0.1319" />
        <stop stopColor="#FC88D9" offset="0.2427" />
        <stop stopColor="#FB62CD" offset="0.3465" />
        <stop stopColor="#FB46C4" offset="0.4416" />
        <stop stopColor="#FA35BF" offset="0.5249" />
        <stop stopColor="#FA2FBD" offset="0.5875" />
        <stop stopColor="#F82FBE" offset="0.5941" />
        <stop stopColor="#6342FF" offset="1" />
      </linearGradient>
      <linearGradient y2="0.50036" x2="0.99966" y1="0.50036" x1="-0.0002" id="svg_203">
        <stop stopColor="#FF99FD" offset="0.00372" />
        <stop stopColor="#FA93FD" offset="0.1649" />
        <stop stopColor="#ED82FD" offset="0.3798" />
        <stop stopColor="#D866FE" offset="0.6251" />
        <stop stopColor="#BB40FF" offset="0.8909" />
        <stop stopColor="#AD2EFF" offset="1" />
      </linearGradient>
      <linearGradient y2="0.50036" x2="0.99966" y1="0.50036" x1="-0.00066" id="svg_202">
        <stop stopColor="#3605A3" />
        <stop stopColor="#55036D" offset="1" />
      </linearGradient>
      <linearGradient y2="0.49992" x2="0.99984" y1="0.49992" x1="-0.00005" id="svg_201">
        <stop stopColor="#9507E0" offset="0" />
        <stop stopColor="#54049D" offset="0.5317" />
        <stop stopColor="#3606D4" offset="1" />
      </linearGradient>
      <linearGradient y2="0.49967" x2="0.99994" y1="0.49967" x1="-0.00003" id="svg_200">
        <stop stopColor="#8C06BD" offset="0" />
        <stop stopColor="#6204A4" offset="0.3181" />
        <stop stopColor="#120073" offset="0.8659" />
      </linearGradient>
      <linearGradient y2="0.04449" x2="0.98784" y1="0.87452" x1="0.00618" id="svg_199">
        <stop stopColor="white" offset="0.0838" />
        <stop stopColor="#FEF3FF" offset="0.1649" />
        <stop stopColor="#FCD2FF" offset="0.313" />
        <stop stopColor="#F89CFF" offset="0.5115" />
        <stop stopColor="#F353FF" offset="0.7532" />
        <stop stopColor="#ED00FF" offset="1" />
      </linearGradient>
      <linearGradient y2="0.50033" x2="1.00011" y1="0.50033" x1="-0.00006" id="svg_198">
        <stop stopColor="white" offset="0.0838" />
        <stop stopColor="#FEF3FF" offset="0.1649" />
        <stop stopColor="#FCD2FF" offset="0.313" />
        <stop stopColor="#F89CFF" offset="0.5115" />
        <stop stopColor="#F353FF" offset="0.7532" />
        <stop stopColor="#ED00FF" offset="1" />
      </linearGradient>
      <linearGradient y2="1.38758" x2="0.64104" y1="-0.39465" x1="0.37844" id="svg_248">
        <stop stopColor="#FC7DD6" />
        <stop stopColor="#FB61CD" offset="0.1077" />
        <stop stopColor="#FB46C4" offset="0.2407" />
        <stop stopColor="#FA35BF" offset="0.3579" />
        <stop stopColor="#FA2FBD" offset="0.446" />
        <stop stopColor="#EB31C4" offset="0.527" />
        <stop stopColor="#C336D5" offset="0.683" />
        <stop stopColor="#843EF0" offset="0.8964" />
        <stop stopColor="#6342FF" offset="1" />
      </linearGradient>
      <linearGradient y2="0.5" x2="1.00015" y1="0.5" x1="0.00015" id="svg_247">
        <stop stopColor="#FC7DD6" />
        <stop stopColor="#FB61CD" offset="0.1077" />
        <stop stopColor="#FB46C4" offset="0.2407" />
        <stop stopColor="#FA35BF" offset="0.3579" />
        <stop stopColor="#FA2FBD" offset="0.446" />
        <stop stopColor="#EB31C4" offset="0.527" />
        <stop stopColor="#C336D5" offset="0.683" />
        <stop stopColor="#843EF0" offset="0.8964" />
        <stop stopColor="#6342FF" offset="1" />
      </linearGradient>
      <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_246">
        <stop stopColor="#30C7EF" />
        <stop stopColor="#01B0EF" offset="0.9963" />
      </linearGradient>
      <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_245">
        <stop stopColor="#019CC9" offset="0" />
        <stop stopColor="#103199" offset="0.9963" />
      </linearGradient>
      <linearGradient y2="0.13759" x2="0.98659" y1="1.02349" x1="0.00827" id="svg_244">
        <stop stopColor="#8C06BD" offset="0" />
        <stop stopColor="#7C05B3" offset="0.1832" />
        <stop stopColor="#510399" offset="0.5439" />
        <stop stopColor="#120073" offset="1" />
      </linearGradient>
      <linearGradient y2="0.77544" x2="0.98461" y1="0.1061" x1="0.0063" id="svg_243">
        <stop stopColor="#8C06BD" offset="0" />
        <stop stopColor="#7C05B3" offset="0.1832" />
        <stop stopColor="#510399" offset="0.5439" />
        <stop stopColor="#120073" offset="1" />
      </linearGradient>
      <radialGradient id="paint0_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(57.60036087036133,41.59390640258789,-12.6441650390625,17.509981155395508,37.29496765136719,331.53765869140625)">
        <stop stopColor="#4937C2" />
        <stop offset="0.2189" stopColor="#402FA6" />
        <stop offset="0.5575" stopColor="#332380" />
        <stop offset="0.8299" stopColor="#2C1D69" />
        <stop offset="1" stopColor="#291A60" />
      </radialGradient>
      <linearGradient id="paint1_linear_296_3255" x1="0.0555" y1="0.49888" x2="0.809" y2="0.49972">
        <stop offset="0" stopColor="#130073" />
        <stop offset="0.04703" stopColor="#3E219D" />
        <stop offset="0.09836" stopColor="#6540C4" />
        <stop offset="0.1455" stopColor="#8257E1" />
        <stop offset="0.1864" stopColor="#9364F2" />
        <stop offset="0.2166" stopColor="#9969F8" />
        <stop offset="0.2309" stopColor="#9B70F8" />
        <stop offset="0.3211" stopColor="#A996F6" />
        <stop offset="0.3935" stopColor="#B2ADF5" />
        <stop offset="0.4385" stopColor="#B5B6F5" />
        <stop offset="0.5351" stopColor="#A4ABF5" />
        <stop offset="0.8463" stopColor="#728AF3" />
        <stop offset="1" stopColor="#5F7DF3" />
      </linearGradient>
      <linearGradient id="paint2_linear_296_3255" x1="-0.05214" y1="0.73057" x2="1.09734" y2="0.17447">
        <stop stopColor="#33057C" />
        <stop offset="0.147" stopColor="#291482" />
        <stop offset="0.4292" stopColor="#0D3B92" />
        <stop offset="0.4595" stopColor="#0A4094" />
        <stop offset="0.6135" stopColor="#0E67B7" />
        <stop offset="0.8199" stopColor="#15A2EA" />
        <stop offset="0.9255" stopColor="#B9D9EA" />
      </linearGradient>
      <radialGradient id="paint3_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(68.17347717285156,-38.29472732543945,15.1970853805542,27.054325103759766,83.3713150024414,38.10646438598633)">
        <stop stopColor="#C4EBFF" />
        <stop offset="0.1464" stopColor="#C1E9FE" stopOpacity="0.8923" />
        <stop offset="0.272" stopColor="#B6E4FC" stopOpacity="0.7999" />
        <stop offset="0.3896" stopColor="#A4DBF8" stopOpacity="0.7133" />
        <stop offset="0.4078" stopColor="#A1D9F7" stopOpacity="0.7" />
        <stop offset="0.7916" stopColor="#43B4EE" stopOpacity="0.2463" />
        <stop offset="1" stopColor="#15A2EA" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint4_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-82.9000473022461,-58.62918472290039,19.975004196166992,-28.244104385375977,72.42559051513672,318.7861022949219)">
        <stop offset="0" stopColor="#EEEEEE" />
        <stop offset="0.3578" stopColor="#6AD1F7" />
        <stop offset="0.7914" stopColor="#5D2FB3" />
        <stop offset="1" stopColor="#43146D" />
      </radialGradient>
      <radialGradient id="paint5_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-36.502437591552734,0,0,-135.65696716308594,24.16291618347168,135.41099548339844)">
        <stop stopColor="#1E429C" />
        <stop offset="0.2515" stopColor="#223487" />
        <stop offset="0.7669" stopColor="#2B1152" />
        <stop offset="1" stopColor="#300038" />
      </radialGradient>
      <linearGradient id="paint6_linear_296_3255" x1="1.53969" y1="0.4992" x2="-0.52181" y2="0.50238">
        <stop offset="0.01441" stopColor="#4F72BD" />
        <stop offset="0.4118" stopColor="#323B99" />
        <stop offset="0.7619" stopColor="#1C107E" />
        <stop offset="0.9404" stopColor="#130073" />
      </linearGradient>
      <linearGradient id="paint7_linear_296_3255" x1="-0.32714" y1="0.81131" x2="0.95585" y2="0.14992">
        <stop offset="0" stopColor="#2E1877" />
        <stop offset="0.453" stopColor="#2B3282" />
        <stop offset="0.6313" stopColor="#2A3E87" />
        <stop offset="0.6849" stopColor="#38519B" />
        <stop offset="0.7944" stopColor="#5C82D0" />
        <stop offset="0.8712" stopColor="#77A8F8" />
        <stop offset="0.8975" stopColor="#7EADF8" />
        <stop offset="0.9369" stopColor="#93BAF8" />
        <stop offset="0.9843" stopColor="#B4CFF8" />
        <stop offset="1" stopColor="#C1D7F8" />
      </linearGradient>
      <linearGradient id="paint8_linear_296_3255" x1="0.43556" y1="0.5939" x2="0.52462" y2="0.41712">
        <stop offset="0.275" stopColor="#8688F8" />
        <stop offset="0.3926" stopColor="#6864D6" />
        <stop offset="0.5522" stopColor="#4439AF" />
        <stop offset="0.6961" stopColor="#2A1A92" />
        <stop offset="0.8177" stopColor="#1B0780" />
        <stop offset="0.9025" stopColor="#15007A" />
      </linearGradient>
      <radialGradient id="paint9_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-57.42073059082031,-40.8641357421875,11.599135398864746,-16.298662185668945,73.80209350585938,285.5682678222656)">
        <stop stopColor="#A8EEFF" />
        <stop offset="0.5093" stopColor="#B19BF1" />
        <stop offset="1" stopColor="#00277C" />
      </radialGradient>
      <radialGradient id="paint10_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(57.52165222167969,41.1169548034668,-19.4679012298584,27.235136032104492,42.92498016357422,309.0166015625)">
        <stop stopColor="#42329C" />
        <stop offset="0.393" stopColor="#392A87" />
        <stop offset="1" stopColor="#291A60" />
      </radialGradient>
      <radialGradient id="paint11_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-8.856847763061523,-23.379711151123047,44.43810272216797,-16.834318161010742,91.8490982055664,47.621299743652344)">
        <stop stopColor="#9E00F8" stopOpacity="0.2" />
        <stop offset="1" stopColor="#9E00F8" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint12_linear_296_3255" x1="0.12188" y1="0.80144" x2="0.95937" y2="0.03657">
        <stop stopColor="#66CBFF" />
        <stop offset="0.1235" stopColor="#6FCEFF" />
        <stop offset="0.3275" stopColor="#88D7FF" />
        <stop offset="0.5866" stopColor="#B1E5FF" />
        <stop offset="0.8878" stopColor="#E9F8FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint13_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(13.3690185546875,9.87443733215332,-4.4140520095825195,5.976192474365234,56.199981689453125,288.911376953125)">
        <stop stopColor="#7BC1FC" />
        <stop offset="0.2405" stopColor="#78BDFA" />
        <stop offset="0.4763" stopColor="#6FB1F3" />
        <stop offset="0.7102" stopColor="#619EE8" />
        <stop offset="0.9417" stopColor="#4C82D9" />
        <stop offset="1" stopColor="#467AD4" />
      </radialGradient>
      <radialGradient id="paint14_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(13.488922119140625,7.432407379150391,-3.1427576541900635,5.703726768493652,30.729822158813477,267.75775146484375)">
        <stop stopColor="#6B79DC" />
        <stop offset="0.1516" stopColor="#6A72D8" />
        <stop offset="0.3671" stopColor="#6660CE" />
        <stop offset="0.6198" stopColor="#6041BD" />
        <stop offset="0.7474" stopColor="#5D2FB3" />
        <stop offset="1" stopColor="#23087A" />
      </radialGradient>
      <linearGradient id="paint15_linear_296_3255" x1="0.08399" y1="0.37187" x2="1.02852" y2="0.66353">
        <stop offset="0" stopColor="#ED00AE" />
        <stop offset="0.08112" stopColor="#F01BB5" />
        <stop offset="0.2477" stopColor="#F44CC2" />
        <stop offset="0.4128" stopColor="#F876CD" />
        <stop offset="0.5731" stopColor="#FB96D5" />
        <stop offset="0.7272" stopColor="#FDADDB" />
        <stop offset="0.8726" stopColor="#FFBADF" />
        <stop offset="1" stopColor="#FFBFBE" />
      </linearGradient>
      <linearGradient id="paint16_linear_296_3255" x1="0.09197" y1="0.37047" x2="1.02897" y2="0.66422">
        <stop stopColor="#FFA100" />
        <stop offset="0.1452" stopColor="#FFAB15" />
        <stop offset="0.4463" stopColor="#FFC54C" />
        <stop offset="0.8741" stopColor="#FFEFA4" />
        <stop offset="1" stopColor="#FFFCBF" />
      </linearGradient>
      <linearGradient id="paint17_linear_296_3255" x1="0.37435" y1="0.12346" x2="0.66863" y2="0.98242">
        <stop stopColor="#BFFFF9" />
        <stop offset="0.213" stopColor="#91F6ED" />
        <stop offset="0.5939" stopColor="#43E6D8" />
        <stop offset="0.8657" stopColor="#13DDCB" />
        <stop offset="1" stopColor="#00D9C6" />
      </linearGradient>
      <linearGradient id="paint18_linear_296_3255" x1="0.31608" y1="0.12605" x2="0.74553" y2="0.94604">
        <stop stopColor="#3BDBFF" />
        <stop offset="0.1931" stopColor="#35D2FF" />
        <stop offset="0.5076" stopColor="#23BAFF" />
        <stop offset="0.9025" stopColor="#0792FF" />
        <stop offset="0.9963" stopColor="#0088FF" />
      </linearGradient>
      <linearGradient id="paint19_linear_296_3255" x1="0.49724" y1="1.0021" x2="0.49724" y2="0.0006">
        <stop offset="0" stopColor="#FF0089" />
        <stop offset="0.1677" stopColor="#FF2093" />
        <stop offset="0.5339" stopColor="#FF71AB" />
        <stop offset="0.6946" stopColor="#FF97B6" />
        <stop offset="1" stopColor="#FFCBF3" />
      </linearGradient>
      <linearGradient id="paint20_linear_296_3255" x1="0.50302" y1="1.00394" x2="0.50302" y2="-0.00093">
        <stop stopColor="#FDFB6A" />
        <stop offset="0.2044" stopColor="#FDFB6D" />
        <stop offset="0.3673" stopColor="#FDFB77" />
        <stop offset="0.5161" stopColor="#FDFC88" />
        <stop offset="0.6563" stopColor="#FEFCA0" />
        <stop offset="0.7905" stopColor="#FEFDBE" />
        <stop offset="0.9185" stopColor="#FFFEE3" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint21_linear_296_3255" x1="0.50302" y1="0.99735" x2="0.50302" y2="-0.00755">
        <stop stopColor="#009DC8" />
        <stop offset="0.1198" stopColor="#05A0C9" />
        <stop offset="0.2664" stopColor="#14A7CD" />
        <stop offset="0.427" stopColor="#2DB4D4" />
        <stop offset="0.5977" stopColor="#50C6DC" />
        <stop offset="0.7762" stopColor="#7CDDE8" />
        <stop offset="0.9588" stopColor="#B2F8F6" />
        <stop offset="1" stopColor="#BFFFF9" />
      </linearGradient>
      <radialGradient id="paint22_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(46.435184478759766,19.82908821105957,-10.434741973876953,24.43577766418457,73.41886138916016,318.5733947753906)">
        <stop stopColor="#0095D1" />
        <stop offset="0.4693" stopColor="#1E429C" />
        <stop offset="0.56" stopColor="#203C93" />
        <stop offset="0.7112" stopColor="#242B79" />
        <stop offset="0.903" stopColor="#2C1050" />
        <stop offset="1" stopColor="#300038" />
      </radialGradient>
      <radialGradient id="paint23_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(64.46578216552734,6.210312843322754,-2.9268407821655273,30.38189697265625,76.19567108154297,313.12371826171875)">
        <stop stopColor="#D3F6FF" />
        <stop offset="0.06486" stopColor="#C5ECFE" />
        <stop offset="0.2281" stopColor="#A6D7FD" />
        <stop offset="0.3194" stopColor="#9ACFFC" />
        <stop offset="0.3997" stopColor="#96C2F9" />
        <stop offset="0.5486" stopColor="#8C9FF1" />
        <stop offset="0.6017" stopColor="#8891EE" />
        <stop offset="1" stopColor="#003375" />
      </radialGradient>
      <linearGradient id="paint24_linear_296_3255" x1="0.49394" y1="1.44636" x2="0.50448" y2="-0.18229">
        <stop offset="0.1819" stopColor="#7594E6" />
        <stop offset="0.3015" stopColor="#6C85DB" />
        <stop offset="0.5298" stopColor="#535FC0" />
        <stop offset="0.8406" stopColor="#2B2293" />
        <stop offset="1" stopColor="#15007A" />
      </linearGradient>
      <radialGradient id="paint25_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(65.53105926513672,-0.7591317892074585,0.14814236760139465,12.788200378417969,81.96312713623047,280.45977783203125)">
        <stop offset="0.2384" stopColor="#CFDCFF" />
        <stop offset="0.3192" stopColor="#C5D5FC" />
        <stop offset="0.4582" stopColor="#A9C1F4" />
        <stop offset="0.6372" stopColor="#7DA2E7" />
        <stop offset="0.784" stopColor="#5385DB" />
        <stop offset="1" stopColor="#4148AD" />
      </radialGradient>
      <radialGradient id="paint26_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(20.803007125854492,-0.13965758681297302,0.029879828914999962,4.450816631317139,124.9474105834961,299.22979736328125)">
        <stop offset="0.05063" stopColor="#0A4094" />
        <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint27_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-2.826373815536499,20.567882537841797,-3.3592677116394043,-0.46161994338035583,103.67162322998047,319.4833679199219)">
        <stop stopColor="#234094" />
        <stop offset="0.00962" stopColor="#244094" stopOpacity="0.9904" />
        <stop offset="0.6479" stopColor="#444094" stopOpacity="0.3521" />
        <stop offset="1" stopColor="#504094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint28_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-2.979118585586548,19.0784969329834,-3.198206901550293,-0.49940183758735657,92.48826599121094,329.9278869628906)">
        <stop offset="0.1814" stopColor="#002F78" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint29_linear_296_3255" x1="0.49996" y1="1.0006" x2="0.49996" y2="0.9462">
        <stop offset="0.1198" stopColor="#000067" stopOpacity="0.6" />
        <stop offset="0.3961" stopColor="#06126A" stopOpacity="0.4117" />
        <stop offset="0.7219" stopColor="#0B206D" stopOpacity="0.1896" />
        <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint30_linear_296_3255" x1="0.49996" y1="1.0006" x2="0.49996" y2="0.43651">
        <stop stopColor="#000067" stopOpacity="0.6" />
        <stop offset="0.06499" stopColor="#010368" stopOpacity="0.561" />
        <stop offset="0.6457" stopColor="#0A1C6C" stopOpacity="0.2126" />
        <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint31_linear_296_3255" x1="0.50099" y1="1.01008" x2="0.50099" y2="-0.01008">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <radialGradient id="paint32_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(29.376861572265625,-0.14336003363132477,0.02230028249323368,4.569698333740234,124.73131561279297,307.71600341796875)">
        <stop stopColor="#324094" />
        <stop offset="1" stopColor="#324094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint33_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(54.12583541870117,-37.97003936767578,14.55309009552002,20.745256423950195,331.4510498046875,328.4541931152344)">
        <stop stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint34_linear_296_3255" x1="1.05217" y1="0.7309" x2="-0.09733" y2="0.17455">
        <stop stopColor="#33057C" />
        <stop offset="0.147" stopColor="#291482" />
        <stop offset="0.1645" stopColor="#211F87" />
        <stop offset="0.1993" stopColor="#162F8D" />
        <stop offset="0.2391" stopColor="#0F3891" />
        <stop offset="0.2942" stopColor="#0D3B92" />
        <stop offset="0.4595" stopColor="#0A4094" />
        <stop offset="0.6135" stopColor="#0E67B7" />
        <stop offset="0.8199" stopColor="#15A2EA" />
        <stop offset="0.9255" stopColor="#B9D9EA" />
      </linearGradient>
      <radialGradient id="paint35_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(32.62901306152344,0,0,148.08729553222656,345.2733154296875,149.7788543701172)">
        <stop offset="0.00753" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint36_linear_296_3255" x1="0.01081" y1="0.50013" x2="1.00987" y2="0.50013">
        <stop stopColor="#001038" />
        <stop offset="0.9925" stopColor="#1E429C" />
      </linearGradient>
      <radialGradient id="paint37_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-59.71062088012695,-41.388771057128906,14.368724822998047,-20.729421615600586,281.2412109375,28.85996437072754)">
        <stop offset="0" stopColor="#F7F7F7" stopOpacity="0.9" />
        <stop offset="1" stopColor="#EEC8F7" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint38_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-69.59146118164062,49.231563568115234,-23.056652069091797,-32.59181594848633,312.5995178222656,305.17657470703125)">
        <stop stopColor="#E6E4EE" />
        <stop offset="0.1457" stopColor="#28F7F7" />
        <stop offset="0.5139" stopColor="#15A2EA" />
        <stop offset="0.6289" stopColor="#117AC7" />
        <stop offset="0.7335" stopColor="#0D59AA" />
        <stop offset="0.7371" stopColor="#0D57A9" />
        <stop offset="0.8565" stopColor="#062891" />
        <stop offset="0.9479" stopColor="#020B82" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <linearGradient id="paint39_linear_296_3255" x1="0.56401" y1="0.63537" x2="0.44149" y2="0.3959">
        <stop offset="0.3676" stopColor="#5890DC" />
        <stop offset="0.5277" stopColor="#3A5DA5" />
        <stop offset="0.6767" stopColor="#223579" />
        <stop offset="0.8097" stopColor="#10185A" />
        <stop offset="0.922" stopColor="#060647" />
        <stop offset="1" stopColor="#020040" />
      </linearGradient>
      <linearGradient id="paint40_linear_296_3255" x1="-0.00015" y1="0.49983" x2="1.00016" y2="0.49983">
        <stop stopColor="#3C67ED" />
        <stop offset="0.2955" stopColor="#7E99F5" />
        <stop offset="0.6397" stopColor="#CFD6FF" />
        <stop offset="0.6759" stopColor="#CBD2FF" />
        <stop offset="0.7117" stopColor="#BFC7FE" />
        <stop offset="0.7473" stopColor="#ABB5FD" />
        <stop offset="0.7828" stopColor="#909BFB" />
        <stop offset="0.8179" stopColor="#6C7AF8" />
        <stop offset="0.8207" stopColor="#6977F8" />
        <stop offset="0.9851" stopColor="#130073" />
      </linearGradient>
      <linearGradient id="paint41_linear_296_3255" x1="0.50643" y1="1.00474" x2="0.50643" y2="0.00121">
        <stop stopColor="#FD3390" />
        <stop offset="0.1181" stopColor="#FD3C96" />
        <stop offset="0.315" stopColor="#FD56A8" />
        <stop offset="0.5664" stopColor="#FE80C4" />
        <stop offset="0.8595" stopColor="#FFB9EB" />
        <stop offset="1" stopColor="#FFD7FF" />
      </linearGradient>
      <linearGradient id="paint42_linear_296_3255" x1="1.22782" y1="0.89386" x2="0.33425" y2="0.04664">
        <stop offset="0" stopColor="#210C63" />
        <stop offset="0.1568" stopColor="#201066" />
        <stop offset="0.3124" stopColor="#1B1C70" />
        <stop offset="0.4677" stopColor="#153081" />
        <stop offset="0.6217" stopColor="#0B4C99" />
        <stop offset="0.7591" stopColor="#006BB3" />
        <stop offset="0.7995" stopColor="#0D73B7" />
        <stop offset="0.8746" stopColor="#308AC2" />
        <stop offset="0.9755" stopColor="#68AED4" />
        <stop offset="1" stopColor="#77B7D9" />
      </linearGradient>
      <linearGradient id="paint43_linear_296_3255" x1="0.00526" y1="0.50245" x2="1.00409" y2="0.50245">
        <stop stopColor="#FDFB6A" />
        <stop offset="0.2044" stopColor="#FDFB6D" />
        <stop offset="0.3673" stopColor="#FDFB77" />
        <stop offset="0.5161" stopColor="#FDFC88" />
        <stop offset="0.6563" stopColor="#FEFCA0" />
        <stop offset="0.7905" stopColor="#FEFDBE" />
        <stop offset="0.9185" stopColor="#FFFEE3" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint44_linear_296_3255" x1="0.50643" y1="1.00088" x2="0.50643" y2="-0.00266">
        <stop stopColor="#00D9C6" />
        <stop offset="0.1298" stopColor="#24E0D0" />
        <stop offset="0.3499" stopColor="#5BEBDE" />
        <stop offset="0.5546" stopColor="#86F4EA" />
        <stop offset="0.7376" stopColor="#A5FAF2" />
        <stop offset="0.8923" stopColor="#B8FEF7" />
        <stop offset="1" stopColor="#BFFFF9" />
      </linearGradient>
      <radialGradient id="paint45_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-51.636531829833984,37.865638732910156,-15.696866989135742,-21.405467987060547,308.1611022949219,276.6371154785156)">
        <stop stopColor="#A1EFFF" />
        <stop offset="0.2272" stopColor="#4EA8EE" />
        <stop offset="0.6417" stopColor="#0A4094" />
        <stop offset="0.9244" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint46_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(75.3411636352539,-52.74681091308594,26.4532470703125,37.78462219238281,305.013427734375,312.5178527832031)">
        <stop stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint47_linear_296_3255" x1="0.08909" y1="0.07483" x2="1.50444" y2="1.38095">
        <stop offset="0.0037" stopColor="white" />
        <stop offset="0.2626" stopColor="#D4F0FF" stopOpacity="0.7402" />
        <stop offset="0.7678" stopColor="#85D6FF" stopOpacity="0.2331" />
        <stop offset="1" stopColor="#66CBFF" stopOpacity="0" />
      </linearGradient>
      <radialGradient id="paint48_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(8.443352699279785,0,0,5.074596405029297,303.2973937988281,293.01361083984375)">
        <stop stopColor="#BFFFF9" />
        <stop offset="0.09361" stopColor="#B7FBF8" />
        <stop offset="0.2353" stopColor="#A3F0F4" />
        <stop offset="0.4074" stopColor="#80DDEF" />
        <stop offset="0.6034" stopColor="#51C4E7" />
        <stop offset="0.8167" stopColor="#15A3DD" />
        <stop offset="0.886" stopColor="#0098D9" />
        <stop offset="1" stopColor="#005CA8" />
      </radialGradient>
      <radialGradient id="paint49_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(8.791802406311035,-1.0215024948120117,0.6460784077644348,5.560626983642578,305.3372802734375,294.32904052734375)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint50_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(8.623863220214844,-1.5162591934204102,0.9648802876472473,5.487844467163086,319.9581604003906,282.0126953125)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint51_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(8.048973083496094,-0.9357784986495972,0.5919592976570129,5.091658592224121,318.45233154296875,280.8974609375)">
        <stop offset="0" stopColor="#FFBFDD" />
        <stop offset="0.1284" stopColor="#FFBBDB" />
        <stop offset="0.255" stopColor="#FFAFD6" />
        <stop offset="0.3811" stopColor="#FF9BCE" />
        <stop offset="0.5068" stopColor="#FF80C2" />
        <stop offset="0.6323" stopColor="#FF5CB3" />
        <stop offset="0.7559" stopColor="#FF30A1" />
        <stop offset="0.8734" stopColor="#FF008C" />
        <stop offset="1" stopColor="#A400AD" />
      </radialGradient>
      <radialGradient id="paint52_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(8.53759765625,-1.3964924812316895,0.8932498693466187,5.460975646972656,335.8896179199219,269.6617126464844)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint53_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(8.048973083496094,-0.9357784986495972,0.5919592976570129,5.091658592224121,334.3465881347656,268.6033630371094)">
        <stop stopColor="#FFFCBF" />
        <stop offset="0.1278" stopColor="#FFFABB" />
        <stop offset="0.254" stopColor="#FFF4AF" />
        <stop offset="0.3795" stopColor="#FFEB9B" />
        <stop offset="0.5047" stopColor="#FFDE80" />
        <stop offset="0.6296" stopColor="#FFCD5C" />
        <stop offset="0.7527" stopColor="#FFB830" />
        <stop offset="0.8696" stopColor="#FFA100" />
        <stop offset="1" stopColor="#AD6800" />
      </radialGradient>
      <linearGradient id="paint54_linear_296_3255" x1="0.49996" y1="0.99979" x2="0.49996" y2="-0.00018">
        <stop offset="0" stopColor="#006E8C" />
        <stop offset="1" stopColor="#006E8C" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint55_linear_296_3255" x1="0.5" y1="1.00554" x2="0.5" y2="-0.00679">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.9" />
      </linearGradient>
      <radialGradient id="paint56_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1.3287190198898315,3.561302661895752,-4.789299964904785,1.7868837118148804,124.37721252441406,296.8610534667969)">
        <stop stopColor="#FCFEFF" />
        <stop offset="0.5536" stopColor="#5D96D4" />
        <stop offset="0.6858" stopColor="#437CC0" />
        <stop offset="1" stopColor="#0A4094" />
      </radialGradient>
      <radialGradient id="paint57_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(3.6216351985931396,0,0,3.6209428310394287,109.88519287109375,338.742919921875)">
        <stop stopColor="#5D96D4" />
        <stop offset="0.174" stopColor="#538BCA" />
        <stop offset="0.4813" stopColor="#386DAF" />
        <stop offset="0.8831" stopColor="#0E3D83" />
        <stop offset="1" stopColor="#002E75" />
      </radialGradient>
      <radialGradient id="paint58_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(4.007741451263428,0,0,4.020648956298828,124.08939361572266,305.49798583984375)">
        <stop stopColor="#FCFEFF" />
        <stop offset="0.5536" stopColor="#5D96D4" />
        <stop offset="0.6858" stopColor="#437CC0" />
        <stop offset="1" stopColor="#0A4094" />
      </radialGradient>
      <radialGradient id="paint59_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(4.207285404205322,0,0,2.7079367637634277,155.56813049316406,358.052001953125)">
        <stop stopColor="#5D96D4" />
        <stop offset="0.174" stopColor="#538BCA" />
        <stop offset="0.4813" stopColor="#386DAF" />
        <stop offset="0.8831" stopColor="#0E3D83" />
        <stop offset="1" stopColor="#002E75" />
      </radialGradient>
      <radialGradient id="paint60_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(239.50830078125,0,0,32.63346481323242,290.1824035644531,355.15155029296875)">
        <stop stopColor="#66ABFF" />
        <stop offset="1" stopColor="#162E6F" />
      </radialGradient>
      <linearGradient id="paint61_linear_296_3255" x1="-0.0006" y1="0.50035" x2="1.00121" y2="0.50035">
        <stop offset="0" stopColor="#4F6AD6" />
        <stop offset="0.3007" stopColor="#5684E1" />
        <stop offset="0.9419" stopColor="#69C8FC" />
        <stop offset="1" stopColor="#6BCEFF" />
      </linearGradient>
      <linearGradient id="paint62_linear_296_3255" x1="0.78741" y1="0.13612" x2="0.387" y2="0.87287">
        <stop stopColor="#CFEFFF" />
        <stop offset="1" stopColor="#3C88FC" />
      </linearGradient>
      <linearGradient id="paint63_linear_296_3255" x1="-0.0017" y1="0.50191" x2="0.99692" y2="0.50191">
        <stop offset="0.1819" stopColor="#7594E6" />
        <stop offset="0.3015" stopColor="#6C85DB" />
        <stop offset="0.5298" stopColor="#535FC0" />
        <stop offset="0.8406" stopColor="#2B2293" />
        <stop offset="1" stopColor="#15007A" />
      </linearGradient>
      <linearGradient id="paint64_linear_296_3255" x1="0.09849" y1="0.2213" x2="0.91433" y2="0.73951">
        <stop offset="0.01441" stopColor="#FFC4FE" />
        <stop offset="0.1324" stopColor="#F3A7F1" />
        <stop offset="0.4419" stopColor="#D460D3" />
        <stop offset="0.6998" stopColor="#BE2CBC" />
        <stop offset="0.8932" stopColor="#B00CAE" />
        <stop offset="1" stopColor="#AB00A9" />
      </linearGradient>
      <linearGradient id="paint65_linear_296_3255" x1="0.21651" y1="0.1618" x2="0.79885" y2="0.86909">
        <stop stopColor="#FED2FD" />
        <stop offset="0.1403" stopColor="#FAC8FD" />
        <stop offset="0.3761" stopColor="#EEAEFC" />
        <stop offset="0.6786" stopColor="#DC84FA" />
        <stop offset="1" stopColor="#C54FF8" />
      </linearGradient>
      <linearGradient id="paint66_linear_296_3255" x1="0.00005" y1="0.49944" x2="1.00005" y2="0.49944">
        <stop offset="0" stopColor="#002673" />
        <stop offset="0.04235" stopColor="#104288" />
        <stop offset="0.1333" stopColor="#3079B2" />
        <stop offset="0.2178" stopColor="#48A5D3" />
        <stop offset="0.2933" stopColor="#5AC4EB" />
        <stop offset="0.357" stopColor="#65D7FA" />
        <stop offset="0.4013" stopColor="#69DEFF" />
        <stop offset="0.5893" stopColor="#8CE5FF" />
        <stop offset="0.8627" stopColor="#BAEFFF" />
        <stop offset="1" stopColor="#CCF3FF" />
      </linearGradient>
      <linearGradient id="paint67_linear_296_3255" x1="0.28074" y1="0.21157" x2="0.87166" y2="1.00703">
        <stop stopColor="#CCF3FF" />
        <stop offset="0.1232" stopColor="#B0EDFF" />
        <stop offset="0.3864" stopColor="#69DEFF" />
        <stop offset="0.4693" stopColor="#64D8F9" />
        <stop offset="0.5824" stopColor="#57C7E7" />
        <stop offset="0.713" stopColor="#42AACB" />
        <stop offset="0.8565" stopColor="#2483A3" />
        <stop offset="1" stopColor="#005473" />
      </linearGradient>
      <linearGradient id="paint68_linear_296_3255" x1="0.06728" y1="0.26486" x2="0.93407" y2="0.74568">
        <stop stopColor="#CCF3FF" />
        <stop offset="0.1232" stopColor="#B0EDFF" />
        <stop offset="0.3864" stopColor="#69DEFF" />
        <stop offset="0.4653" stopColor="#65D8FB" />
        <stop offset="0.5731" stopColor="#59C6EF" />
        <stop offset="0.6976" stopColor="#44AADD" />
        <stop offset="0.8345" stopColor="#2981C2" />
        <stop offset="0.9801" stopColor="#054FA1" />
        <stop offset="1" stopColor="#00479C" />
      </linearGradient>
      <radialGradient id="paint69_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(4.22637414932251,0,0,19.469623565673828,280.38568115234375,310.6905212402344)">
        <stop stopColor="#AE5AE6" />
        <stop offset="0.1811" stopColor="#944FCC" />
        <stop offset="0.57" stopColor="#513288" />
        <stop offset="1" stopColor="#000F36" />
      </radialGradient>
      <radialGradient id="paint70_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(3.964198112487793,0,0,16.858936309814453,268.03472900390625,310.6831970214844)">
        <stop offset="0" stopColor="#00A4DB" />
        <stop offset="0.1147" stopColor="#029ED7" />
        <stop offset="0.2633" stopColor="#078ECD" />
        <stop offset="0.4304" stopColor="#0F73BC" />
        <stop offset="0.61" stopColor="#1A4FA4" />
        <stop offset="0.6637" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint71_linear_296_3255" x1="-0.08441" y1="0.30413" x2="1.11479" y2="0.70012">
        <stop offset="0.01441" stopColor="#FFC4FE" />
        <stop offset="0.04985" stopColor="#FFBEFE" />
        <stop offset="0.7069" stopColor="#FA56F7" />
        <stop offset="1" stopColor="#F82DF5" />
      </linearGradient>
      <linearGradient id="paint72_linear_296_3255" x1="-0.10202" y1="0.34472" x2="1.09932" y2="0.65036">
        <stop offset="0.01441" stopColor="#D6FDFF" />
        <stop offset="0.1408" stopColor="#C2FBFE" />
        <stop offset="0.4009" stopColor="#8DF7FD" />
        <stop offset="0.7685" stopColor="#38F0FA" />
        <stop offset="1" stopColor="#00ECF8" />
      </linearGradient>
      <radialGradient id="paint73_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(56.35099792480469,0,0,9.385412216186523,270.5048828125,282.8236999511719)">
        <stop offset="0.01441" stopColor="#D1E9F3" />
        <stop offset="0.4998" stopColor="#69CAF8" />
        <stop offset="1" stopColor="#27277D" />
      </radialGradient>
      <radialGradient id="paint74_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(91.90608215332031,-1.051623821258545,0.3834119141101837,33.50807571411133,183.89398193359375,323.79815673828125)">
        <stop offset="0.05063" stopColor="#0A4094" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint75_linear_296_3255" x1="0.50034" y1="1.14041" x2="0.49895" y2="-0.11092">
        <stop offset="0" stopColor="#69AAFF" />
        <stop offset="0.06479" stopColor="#7BB5FF" />
        <stop offset="0.2341" stopColor="#A3CDFF" />
        <stop offset="0.3999" stopColor="#C4E1FF" />
        <stop offset="0.5594" stopColor="#DAEFFF" />
        <stop offset="0.7095" stopColor="#E8F7FF" />
        <stop offset="0.8408" stopColor="#EDFAFF" />
        <stop offset="0.8506" stopColor="#E8F9FF" />
        <stop offset="0.8906" stopColor="#D8F6FF" />
        <stop offset="0.9364" stopColor="#CFF4FF" />
        <stop offset="1" stopColor="#CCF3FF" />
      </linearGradient>
      <linearGradient id="paint76_linear_296_3255" x1="0.50055" y1="1.09126" x2="0.49266" y2="-0.08713">
        <stop offset="0" stopColor="#2E1877" />
        <stop offset="0.06145" stopColor="#30207C" />
        <stop offset="0.1544" stopColor="#37348C" />
        <stop offset="0.2672" stopColor="#4356A4" />
        <stop offset="0.3956" stopColor="#5286C7" />
        <stop offset="0.5355" stopColor="#66C2F2" />
        <stop offset="0.5538" stopColor="#69CAF8" />
        <stop offset="0.9856" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint77_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(14.740531921386719,0,0,3.982841730117798,146.42225646972656,298.16168212890625)">
        <stop stopColor="#BA87FF" />
        <stop offset="0.1721" stopColor="#AB78EF" />
        <stop offset="0.5094" stopColor="#8451C5" />
        <stop offset="0.9753" stopColor="#451281" />
        <stop offset="1" stopColor="#420F7D" />
      </radialGradient>
      <linearGradient id="paint78_linear_296_3255" x1="0.50195" y1="1.00291" x2="0.50195" y2="-0.0102">
        <stop offset="0" stopColor="#67A3FC" />
        <stop offset="0.1558" stopColor="#6CA7FC" />
        <stop offset="0.3518" stopColor="#7CB1FD" />
        <stop offset="0.5694" stopColor="#96C2FD" />
        <stop offset="0.8008" stopColor="#B9DAFE" />
        <stop offset="0.9856" stopColor="#DBF1FF" />
      </linearGradient>
      <radialGradient id="paint79_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(16.482152938842773,0,0,4.190471172332764,185.01780700683594,298.1838684082031)">
        <stop offset="0" stopColor="#00A4DB" />
        <stop offset="0.1147" stopColor="#029ED7" />
        <stop offset="0.2633" stopColor="#078ECD" />
        <stop offset="0.4304" stopColor="#0F73BC" />
        <stop offset="0.61" stopColor="#1A4FA4" />
        <stop offset="0.6637" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <radialGradient id="paint80_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(14.562920570373535,0,0,3.769747495651245,223.6133575439453,298.1912841796875)">
        <stop stopColor="#BA87FF" />
        <stop offset="0.1721" stopColor="#AB78EF" />
        <stop offset="0.5094" stopColor="#8451C5" />
        <stop offset="0.9753" stopColor="#451281" />
        <stop offset="1" stopColor="#420F7D" />
      </radialGradient>
      <linearGradient id="paint81_linear_296_3255" x1="0.50109" y1="1.00291" x2="0.50109" y2="-0.0102">
        <stop offset="0" stopColor="#67A3FC" />
        <stop offset="0.1558" stopColor="#6CA7FC" />
        <stop offset="0.3518" stopColor="#7CB1FD" />
        <stop offset="0.5694" stopColor="#96C2FD" />
        <stop offset="0.8008" stopColor="#B9DAFE" />
        <stop offset="0.9856" stopColor="#DBF1FF" />
      </linearGradient>
      <radialGradient id="paint82_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(4.193470001220703,2.5428876876831055,-2.3960366249084473,3.9512975215911865,185.8236083984375,298.68341064453125)">
        <stop offset="0.4126" stopColor="#001959" />
        <stop offset="0.8768" stopColor="#001959" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint83_linear_296_3255" x1="0.09589" y1="0.20677" x2="0.90213" y2="0.79251">
        <stop offset="0.01441" stopColor="white" />
        <stop offset="0.1866" stopColor="#EBF2FE" />
        <stop offset="0.5409" stopColor="#B6D2FC" />
        <stop offset="1" stopColor="#69A2F8" />
      </linearGradient>
      <radialGradient id="paint84_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(23.642684936523438,0,0,4.502323627471924,177.66334533691406,262.2882080078125)">
        <stop stopColor="#BEE0EE" />
        <stop offset="0.1263" stopColor="#B4D7E8" />
        <stop offset="0.3432" stopColor="#98BEDA" />
        <stop offset="0.6247" stopColor="#6B95C2" />
        <stop offset="0.9565" stopColor="#2E5EA1" />
        <stop offset="1" stopColor="#25569C" />
      </radialGradient>
      <radialGradient id="paint85_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(44.9766845703125,1.4859166145324707,-0.3210729658603668,9.71844482421875,187.8037567138672,289.92523193359375)">
        <stop stopColor="#0A4094" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint86_linear_296_3255" x1="0.00027" y1="0.50056" x2="0.99986" y2="0.50056">
        <stop stopColor="#66CBFF" />
        <stop offset="0.0938" stopColor="#77D1FF" />
        <stop offset="0.5283" stopColor="#C0E9FF" />
        <stop offset="0.8401" stopColor="#EDF9FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint87_linear_296_3255" x1="0.54235" y1="0.21601" x2="0.435" y2="0.94044">
        <stop stopColor="#66CBFF" />
        <stop offset="0.0938" stopColor="#77D1FF" />
        <stop offset="0.5283" stopColor="#C0E9FF" />
        <stop offset="0.8401" stopColor="#EDF9FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint88_linear_296_3255" x1="0.08767" y1="1.52076" x2="0.8651" y2="-0.18012">
        <stop stopColor="#6BB4F3" />
        <stop offset="1" stopColor="#00356E" />
      </linearGradient>
      <linearGradient id="paint89_linear_296_3255" x1="0.2669" y1="-0.40512" x2="0.70728" y2="1.80006">
        <stop offset="0.01441" stopColor="white" />
        <stop offset="0.2348" stopColor="#FCFDFF" />
        <stop offset="0.3949" stopColor="#F3F7FE" />
        <stop offset="0.5359" stopColor="#E3EEFE" />
        <stop offset="0.6658" stopColor="#CDE0FD" />
        <stop offset="0.7883" stopColor="#B0CEFB" />
        <stop offset="0.9034" stopColor="#8DB8FA" />
        <stop offset="1" stopColor="#69A2F8" />
      </linearGradient>
      <linearGradient id="paint90_linear_296_3255" x1="0.29242" y1="1.80037" x2="0.73464" y2="-0.4187">
        <stop offset="0" stopColor="#41DEFF" stopOpacity="0.3" />
        <stop offset="0.09363" stopColor="#37BDE8" stopOpacity="0.3655" />
        <stop offset="0.2719" stopColor="#2784C0" stopOpacity="0.4903" />
        <stop offset="0.4449" stopColor="#19559F" stopOpacity="0.6114" />
        <stop offset="0.6082" stopColor="#0E3086" stopOpacity="0.7258" />
        <stop offset="0.7597" stopColor="#061673" stopOpacity="0.8318" />
        <stop offset="0.8948" stopColor="#020668" stopOpacity="0.9263" />
        <stop offset="1" stopColor="#000064" />
      </linearGradient>
      <linearGradient id="paint91_linear_296_3255" x1="0.35839" y1="1.76961" x2="0.68831" y2="-0.46792">
        <stop stopColor="#41DEFF" stopOpacity="0.2" />
        <stop offset="1" stopColor="#000064" />
      </linearGradient>
      <linearGradient id="paint92_linear_296_3255" x1="0.2669" y1="-0.40512" x2="0.70728" y2="1.80006">
        <stop offset="0.01441" stopColor="white" />
        <stop offset="0.2348" stopColor="#FCFDFF" />
        <stop offset="0.3949" stopColor="#F3F7FE" />
        <stop offset="0.5359" stopColor="#E3EEFE" />
        <stop offset="0.6658" stopColor="#CDE0FD" />
        <stop offset="0.7883" stopColor="#B0CEFB" />
        <stop offset="0.9034" stopColor="#8DB8FA" />
        <stop offset="1" stopColor="#69A2F8" />
      </linearGradient>
      <linearGradient id="paint93_linear_296_3255" x1="0.52352" y1="1.13064" x2="0.49632" y2="-0.18377">
        <stop stopColor="#66CBFF" />
        <stop offset="0.2751" stopColor="#92D4F9" />
        <stop offset="0.6" stopColor="#BFDCF3" />
        <stop offset="0.8512" stopColor="#DBE2EF" />
        <stop offset="0.9963" stopColor="#E6E4EE" />
      </linearGradient>
      <linearGradient id="paint94_linear_296_3255" x1="0.53456" y1="0.93547" x2="0.49633" y2="-0.10348">
        <stop stopColor="#66CBFF" />
        <stop offset="0.1422" stopColor="#8AD7FF" />
        <stop offset="0.3306" stopColor="#B4E6FF" />
        <stop offset="0.5146" stopColor="#D5F1FF" />
        <stop offset="0.6906" stopColor="#ECF9FF" />
        <stop offset="0.855" stopColor="#FAFDFF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint95_radial_296_3255" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(31.51172637939453,-0.36504584550857544,0.07123851031064987,6.1494951248168945,80.47754669189453,288.9139099121094)">
        <stop stopColor="#CFDCFF" />
        <stop offset="0.1292" stopColor="#C9D6FB" />
        <stop offset="0.309" stopColor="#B8C4F1" />
        <stop offset="0.5185" stopColor="#9BA6E1" />
        <stop offset="0.7504" stopColor="#747DCA" />
        <stop offset="0.9982" stopColor="#4148AD" />
        <stop offset="1" stopColor="#4148AD" />
      </radialGradient>
      <linearGradient id="paint96_linear_296_3255" x1="0.49123" y1="-0.01843" x2="0.54125" y2="1.541">
        <stop offset="0" stopColor="#2E1877" />
        <stop offset="0.2066" stopColor="#4153A2" />
        <stop offset="0.5239" stopColor="#5EA8DF" />
        <stop offset="0.6685" stopColor="#69CAF8" />
        <stop offset="0.7443" stopColor="#7ED1F8" />
        <stop offset="0.8995" stopColor="#B2E2F8" />
        <stop offset="1" stopColor="#D8EEF8" />
      </linearGradient>
      <linearGradient id="paint97_linear_296_3255" x1="0.5055" y1="1.63088" x2="0.49752" y2="-0.71889">
        <stop stopColor="#B6D7F3" />
        <stop offset="0.2304" stopColor="#6BB4F3" />
        <stop offset="1" stopColor="#00356E" />
      </linearGradient>
      <linearGradient id="paint98_linear_296_3255" x1="0.49895" y1="1.00587" x2="0.49895" y2="0.01548">
        <stop offset="0" stopColor="#FF63B6" />
        <stop offset="0.1947" stopColor="#FF5FB4" />
        <stop offset="0.3982" stopColor="#FF52AE" />
        <stop offset="0.6059" stopColor="#FF3DA5" />
        <stop offset="0.815" stopColor="#FF2098" />
        <stop offset="1" stopColor="#FF0089" />
      </linearGradient>
      <linearGradient id="paint99_linear_296_3255" x1="0.49895" y1="1.01216" x2="0.49895" y2="0.0196">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient id="paint100_linear_296_3255" x1="0.50047" y1="1.00587" x2="0.50047" y2="0.01548">
        <stop stopColor="#FEFFEB" />
        <stop offset="0.3836" stopColor="#E7F148" />
        <stop offset="0.7478" stopColor="#F6CF1B" />
        <stop offset="1" stopColor="#FFBB00" />
      </linearGradient>
      <linearGradient id="paint101_linear_296_3255" x1="0.50015" y1="1.00587" x2="0.50015" y2="0.01548">
        <stop stopColor="#00FFEA" />
        <stop offset="0.1191" stopColor="#00F2E6" />
        <stop offset="0.3374" stopColor="#00D1DA" />
        <stop offset="0.6307" stopColor="#009BC8" />
        <stop offset="0.9844" stopColor="#0051AE" />
        <stop offset="1" stopColor="#004EAD" />
      </linearGradient>
      <linearGradient id="paint102_linear_296_3255" x1="0.50015" y1="1.01216" x2="0.50015" y2="0.0196">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="0.9963" stopColor="white" stopOpacity="0.9" />
      </linearGradient>
      <linearGradient id="paint103_linear_296_3255" x1="0.49877" y1="1.01216" x2="0.49877" y2="0.0196">
        <stop offset="0.00372" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient id="paint104_linear_296_3255" x1="-0.00022" y1="0.4964" x2="0.99976" y2="0.4964">
        <stop offset="0.00372" stopColor="#E6E4EE" />
        <stop offset="0.21" stopColor="#E3E3EE" />
        <stop offset="0.3889" stopColor="#D8DFF0" />
        <stop offset="0.5576" stopColor="#C6D8F3" />
        <stop offset="0.72" stopColor="#ADCFF6" />
        <stop offset="0.8766" stopColor="#8DC3FB" />
        <stop offset="1" stopColor="#6EB7FF" />
      </linearGradient>
      <linearGradient id="paint105_linear_296_3255" x1="0.51204" y1="1.36809" x2="0.46751" y2="-0.26993">
        <stop offset="0" stopColor="#CEDFF8" />
        <stop offset="0.1885" stopColor="#E3EDFB" />
        <stop offset="0.4034" stopColor="#F3F7FD" />
        <stop offset="0.6483" stopColor="#FCFDFF" />
        <stop offset="0.9856" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint106_linear_296_3255" x1="0.44534" y1="0.78917" x2="0.52933" y2="0.43139">
        <stop offset="0.1855" stopColor="#1B69F8" />
        <stop offset="0.7404" stopColor="#2E1877" />
        <stop offset="1" stopColor="#221157" />
      </linearGradient>
      <linearGradient id="paint107_linear_296_3255" x1="0.56047" y1="0.7941" x2="0.47137" y2="0.43601">
        <stop stopColor="#0379FF" />
        <stop offset="0.1277" stopColor="#0363DC" />
        <stop offset="0.3486" stopColor="#0340A5" />
        <stop offset="0.554" stopColor="#022479" />
        <stop offset="0.7375" stopColor="#02105A" />
        <stop offset="0.8924" stopColor="#020447" />
        <stop offset="1" stopColor="#020040" />
      </linearGradient>
    </>
  );
}
export default MachineSvgDefs;
