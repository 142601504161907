// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQuestsStore, IReferralsStore } from '../../../types';
import {
  getReferrals,
} from './asyncActions';
import { TReferralsResponse } from './types';

// Types

const initialState: IReferralsStore = {
  referrals: null,
  error: null,
  loading: false,
};

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    clearErrorMessage(state) {
      state.error = null;
    },
    resetQuests(state) {
      const source: IQuestsStore = {
        loading: true,
        questsList: null,
        error: null,
      };
      Object.assign(state, source);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getReferrals.fulfilled,
        (state, action: PayloadAction<TReferralsResponse>) => {
          const source: IReferralsStore = {
            loading: false,
            referrals: action.payload,
            error: null,
          };
          Object.assign(state, source);
        },
      );
  },
});

export const { clearErrorMessage } = referralsSlice.actions;
export const ReferralsReducer = referralsSlice.reducer;
