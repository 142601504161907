// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { axiosInstance } from '../../index';
import { SettingsResponse } from './types';
// Types
export const getVersion = createAsyncThunk(
  'settings/version',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('version');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const getSettings = createAsyncThunk<SettingsResponse>(
  'settings/settings',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('settings/user');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
