import { useEffect } from 'react';
import MachineScreenBgSVG from './MachineScreenBgSVG';
import SpinResult from './SpinResult';
import SlotMachinePixi from '../SlotMachinePixi';
import BottomPart from './BottomPart';
import SecondLayer from './SecondLayer';
import MachineSvgDefs from './MachineSvgDefs';
import MiddleScreen from './MiddleScreen';
import BottomScreen from './BottomScreen';
import SpinButton from './SpinButton';
import { slotMachinePixiApp } from '../../../Layouts/AppWrapper';
import { useEnergyContext } from '../../../providers/EnergyProvider';

type MachineProps = {
  onSpin: () => void
  isSpinning: boolean
}
export const getMachineFrameScale = () => {
  const parentSvg = document.getElementById('machine-frame-svg');
  if (!parentSvg) {
    return null;
  }
  const actualWidth = parentSvg.clientWidth;
  const actualHeight = parentSvg.clientHeight;
  const viewBox = parentSvg.getAttribute('viewBox');

  if (!viewBox) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = viewBox.split(' ').map(Number);

  const scaleX = actualWidth / viewBoxWidth;
  const scaleY = actualHeight / viewBoxHeight;

  return {
    scaleX, scaleY,
  };
};

function Machine({ onSpin, isSpinning }: MachineProps) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const resize = () => {
    const foreignMachine = document.getElementById('machine-foreign');
    const parentSvg = document.getElementById('machine-frame-svg');
    const screenSvg = document.getElementById('svg_63');
    const divInside = document.getElementById('machine-content');
    if (foreignMachine && parentSvg && screenSvg && divInside) {
      const screenRect = screenSvg.getBoundingClientRect();
      const scaleData = getMachineFrameScale();
      if (scaleData) {
        const { scaleX, scaleY } = scaleData;

        foreignMachine.setAttribute('width', (screenRect.width / scaleX).toString());
        foreignMachine.setAttribute('height', (screenRect.height / scaleY).toString());
        // set position to div inside foreignObject
        divInside.style.width = `${screenRect.width / scaleX}px`;
        divInside.style.height = `${screenRect.height / scaleY}px`;
        divInside.style.position = 'fixed';
        divInside.style.top = '0';
        divInside.style.left = '0';

        if (isSafari) {
          const { height: resizeHeight, width: resizeWidth } = screenSvg.getBoundingClientRect();

          if (resizeHeight && resizeWidth) {
            slotMachinePixiApp.renderer.resize(resizeWidth, resizeHeight);
          }
          if (scaleX && scaleY) {
            slotMachinePixiApp.stage.scale.set(scaleX, scaleY);
          }
        }
      }
    }
  };
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
  }, []);
  const handleSpinClick = () => {
    onSpin();
  };
  const { energyRecoveryTimer, userEnergy: userEnergyContext } = useEnergyContext();

  const { userEnergy, maxEnergy, energyForSpin } = userEnergyContext;
  const { countdown } = energyRecoveryTimer({ energyUnit: energyForSpin });

  return (
    <>
      <svg
        id="machine-frame-svg"
        className="w-full h-auto max-w-full"
        // viewBox="0 0 374 486"
        viewBox="0 0 374 566"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        style={{
          overflow: 'hidden',
        }}
      >
        <foreignObject
          x="-5"
          y={(window?.Telegram.WebApp.platform === 'android' || window?.Telegram.WebApp.platform === 'unknown') ? '-10' : '-25'}
          width="100%"
          height="100%"
        >
          <MachineScreenBgSVG />
        </foreignObject>
        <foreignObject x="20" y="35" id="machine-foreign">
          <div
            id="machine-content"
            className="z-[2]"
          >
            <SlotMachinePixi />
          </div>
        </foreignObject>
        <g>
          <title>Layer 2</title>
          <SecondLayer />
          <svg y="373" x="69.45621" id="svg_220" fill="none" viewBox="0 0 236 104" height="104" width="236" xmlns="http://www.w3.org/2000/svg">
            <foreignObject x={0} y={0} height="100%" width="100%">
              <SpinButton onClick={handleSpinClick} isSpinning={isSpinning} />
            </foreignObject>
          </svg>
        </g>
      </svg>
      <svg
        id="machine-frame-svg-second"
        className="w-full h-auto max-w-full"
        viewBox="0 0 374 566"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        style={{
          overflow: 'hidden',
        }}
      >
        <defs>
          <MachineSvgDefs />
        </defs>
        <g>
          <title>Layer 1</title>
          <g id="svg_194">
            <g stroke="null" id="svg_136">
              <path stroke="null" d="m8.20711,267.45609l0,27.16467l58.22918,55.47419l-2.97436,0l52.96666,0l-3.14597,-18.41639l-28.31372,0.17309l-76.76179,-64.39556l0,0.00001z" fill="url(#paint0_radial_296_3255)" id="svg_1" />
              <path stroke="null" d="m118.71655,30.92141c0.05712,0.11448 -2.63116,1.02937 -2.80276,1.08673c-1.60154,0.45744 -3.14597,1.08649 -4.69039,1.65842c-3.20309,1.20097 -6.29194,2.74515 -9.55239,3.7174c-4.40431,1.31521 -9.38054,2.34458 -19.27614,7.20583c-1.83027,0.91488 -4.17558,2.05874 -6.00585,3.08811c-10.98233,6.17647 -21.27825,13.09621 -29.97263,22.24655c-5.0336,5.20422 -11.26817,15.38392 -11.84035,26.47851c-0.45744,9.32194 -0.40033,151.32128 -0.40033,151.32128l-8.12221,2.23034l0,-158.41263c0,-9.26457 5.77713,-17.27106 9.20894,-21.78911c5.66289,-7.72039 11.84035,-12.63877 19.90543,-18.35779c7.43604,-5.26133 16.47362,-10.52266 24.59582,-14.75463c10.81073,-5.60453 32.48931,-13.2107 32.48931,-13.2107c0,0 6.40642,7.43456 6.46354,7.49168l0,0.00001z" fill="url(#paint1_linear_296_3255)" id="svg_2" />
              <path stroke="null" d="m108.87832,22.00005l-0.91538,-13.9542c-36.77913,8.52117 -46.33152,14.64036 -66.75176,28.19427c-27.62729,18.98683 -30.83047,45.23662 -30.83047,45.23662l2.57399,38.54535l5.54834,15.26943l12.12638,-16.69913c0,0 -4.862,-37.00142 13.27029,-56.33121c18.13203,-19.33004 47.47538,-31.74009 47.47538,-31.74009l17.50324,-8.52105l-0.00001,0z" fill="url(#paint2_linear_296_3255)" id="svg_3" />
              <path stroke="null" d="m108.87832,22.00005l-0.91538,-13.9542c-36.77913,8.52117 -46.33152,14.64036 -66.75176,28.19427c-27.62729,18.98683 -30.83047,45.23662 -30.83047,45.23662l2.57399,38.54535l5.54834,15.26943l12.12638,-16.69913c0,0 -4.862,-37.00142 13.27029,-56.33121c18.13203,-19.33004 47.47538,-31.74009 47.47538,-31.74009l17.50324,-8.52105l-0.00001,0z" fill="url(#paint3_radial_296_3255)" id="svg_4" />
              <path stroke="null" d="m33.54642,247.2668l49.76358,42.20588l10.92521,0.40057l0.1716,32.19902l-10.98233,-0.3437l-9.38079,0l-69.09691,-63.36693l28.59964,-11.09484l0.00001,0z" fill="url(#paint4_radial_296_3255)" id="svg_5" />
              <path stroke="null" d="m26.62543,89.0829l-16.70232,-4.68965l0,172.02488l17.50296,-6.7479l-0.80065,-160.58734l0,0.00001z" fill="url(#paint5_radial_296_3255)" id="svg_6" />
              <path stroke="null" d="m26.62543,89.54034l-4.51879,-1.20097l1.1441,162.87381l4.17558,-1.54294l-0.8009,-160.12989z" fill="url(#paint6_linear_296_3255)" id="svg_7" />
              <path stroke="null" d="m112.0814,19.08337c-4.80488,0.57187 -9.20918,1.88724 -13.72798,3.4886c-17.2169,6.06198 -32.88963,15.9556 -46.61761,27.50788c-7.37867,6.23359 -11.43978,9.37905 -16.41626,16.4704c-5.14784,7.32032 -9.55215,17.27106 -9.55215,26.36427l0.1716,157.8412l6.74938,-2.28722c0,-3.48893 0.11448,-89.72947 0.05736,-101.1099c-0.05736,-10.52291 -0.05736,-21.10294 0.11424,-31.56849c0.11448,-7.54904 0.22897,-15.04071 0.8009,-22.53263c0.8009,-10.57978 7.03547,-20.81661 14.47151,-28.30853c5.77713,-5.83326 12.58388,-10.63715 19.61934,-14.92623c15.6156,-9.77938 33.74765,-16.41329 51.308,-22.07495c-2.34507,-2.91676 -4.63327,-5.89062 -6.97835,-8.86442l0.00001,0z" fill="url(#paint7_linear_296_3255)" id="svg_8" />
              <path stroke="null" d="m23.25075,251.21317l56.22687,42.60645l3.26045,-5.20496l-56.57032,-39.23127l-2.917,1.82977z" fill="url(#paint8_linear_296_3255)" id="svg_9" />
              <path stroke="null" d="m26.16775,249.3834l8.17957,-2.91527l56.45583,38.25951l-4.46167,10.17748l-60.17373,-45.52172z" fill="url(#paint9_radial_296_3255)" id="svg_10" />
              <path stroke="null" d="m4.94678,258.36164l66.69447,58.96312l54.05365,0l-1.42994,18.12957l-45.35927,0l-73.9589,-67.02647l0,-7.09159" fill="url(#paint10_radial_296_3255)" id="svg_11" />
              <path stroke="null" d="m116.19962,27.60458l2.57404,5.03261c-16.13017,3.48843 -32.08874,7.72039 -51.53672,20.47365c-6.92098,4.5178 -13.32741,9.37905 -19.27614,15.09783c-7.49315,7.20583 -12.29803,17.38554 -12.98445,25.56363c-0.45744,5.77614 -0.22872,15.21231 -0.34296,22.70399c-0.1716,10.52291 -15.27238,-25.44914 -15.27238,-25.50626c0,-9.09297 3.54627,-17.15682 8.69435,-24.47689c4.97624,-7.09159 12.24067,-14.35455 19.3335,-19.21556c15.27215,-10.52291 30.88751,-18.87235 48.04755,-24.93458c4.51854,-1.6012 8.9231,-2.91656 13.72773,-3.48843c2.45956,2.91676 4.74751,5.83326 7.03547,8.75001l0.00001,0z" fill="url(#paint11_radial_296_3255)" id="svg_12" />
              <path stroke="null" opacity="0.69" d="m90.40283,27.37586c0,0 -17.56011,6.80551 -35.12047,19.8446c-8.98021,6.69102 -18.93293,17.78562 -18.93293,17.78562c0,0 9.60951,-12.753 25.05327,-23.90496c16.81658,-11.15196 27.45571,-16.18457 27.45571,-16.18457l1.54442,2.45931l0,-0.00001z" fill="url(#paint12_linear_296_3255)" id="svg_13" />
              <path stroke="null" d="m60.08699,298.85149l-16.30177,-13.38205c-1.37282,-1.14237 -1.08673,-3.31584 0.51481,-3.94637l6.46354,-2.80153c0.80065,-0.3437 1.77315,-0.22748 2.40244,0.28683l15.44376,12.35342c1.25834,1.02862 1.20122,2.91527 -0.11448,3.83015l-5.60553,3.77575c-0.85801,0.57119 -1.94475,0.51431 -2.80276,-0.11622l0,0.00001z" fill="url(#paint13_radial_296_3255)" id="svg_14" />
              <path stroke="null" opacity="0.3" d="m44.30003,282.03986l6.46354,-2.804c0.80065,-0.3437 1.77315,-0.22748 2.40244,0.28683l15.44376,12.35342c0.57193,0.40057 0.8009,1.02862 0.85801,1.65669c0.11448,-0.79867 -0.1716,-1.65669 -0.8009,-2.23034l-15.44376,-12.35095c-0.6293,-0.51679 -1.60154,-0.6874 -2.40244,-0.28683l-6.46354,2.80153c-0.97225,0.40057 -1.48706,1.4292 -1.42994,2.34656c0.11448,-0.74427 0.57193,-1.43167 1.37282,-1.7729l0,-0.00001z" fill="#2B0691" id="svg_15" />
              <path stroke="null" opacity="0.6" d="m42.29791,270.94502l-14.92895,-12.06906c-0.74353,-0.57119 -1.71603,-0.7418 -2.63116,-0.3981l-5.66282,2.45783c-1.60157,0.85801 -1.83036,3.08836 -0.45759,4.2332l14.92902,12.0666c0.74353,0.57119 1.71603,0.7418 2.63116,0.40057l5.66289,-2.4603c1.60154,-0.80114 1.88739,-3.08836 0.45744,-4.23073l-0.00001,0.00001z" fill="url(#paint14_radial_296_3255)" id="svg_16" />
              <path stroke="null" opacity="0.5" d="m19.07496,261.50686l5.66284,-2.45783c0.85801,-0.3437 1.88739,-0.22996 2.63116,0.40057l14.92895,12.0666c0.6293,0.45744 0.91513,1.14237 0.9725,1.7729c0.1716,-0.85801 -0.1716,-1.7729 -0.91513,-2.34408l-14.9292,-12.06906c-0.74353,-0.57119 -1.71603,-0.7418 -2.63116,-0.3981l-5.66275,2.45783c-0.9724,0.45744 -1.48721,1.48607 -1.3728,2.4603c0.0572,-0.80114 0.51478,-1.48854 1.31558,-1.88911l0.00001,0z" fill="#2B0691" id="svg_17" />
              <path stroke="null" d="m25.88166,269.17212l-5.43387,-4.34694c-0.74358,-0.63053 -0.4004,-2.23034 -0.4004,-2.23034l5.09074,-1.94598c0.45769,-0.22748 1.02961,-0.11374 1.37282,0.17309l5.89161,4.40381c0,0 0.28584,1.65669 -0.6293,2.05726l-4.51879,2.00286c-0.45744,0.22748 -0.91513,0.17062 -1.37282,-0.11374l0,-0.00001z" fill="#2B0691" id="svg_18" />
              <path stroke="null" d="m25.93903,268.77155l-5.43392,-4.34694c-0.74368,-0.62805 -0.40047,-2.23034 -0.40047,-2.23034l5.09087,-1.94351c0.45744,-0.22996 1.02937,-0.11622 1.37258,0.17062l5.89161,4.40381c0,0 0.28609,1.65915 -0.62904,2.05726l-4.51879,2.00286c-0.45769,0.22996 -0.9725,0.17062 -1.37282,-0.11374l0,-0.00001z" fill="#B70870" id="svg_19" />
              <path stroke="null" d="m25.99615,267.79979l-5.37681,-4.29007c-0.74353,-0.63053 -0.62912,-1.88664 0.28609,-2.28722l4.28982,-1.94598c0.45769,-0.22748 1.02961,-0.11374 1.37282,0.17309l5.37681,4.2876c0.74353,0.63053 0.6293,1.88911 -0.28609,2.28968l-4.28982,1.94351c-0.51481,0.22748 -1.02961,0.11374 -1.37282,-0.17062l0,0.00001z" fill="url(#paint15_linear_296_3255)" id="svg_20" />
              <path stroke="null" d="m34.80476,276.43433l-5.37656,-4.29007c-0.74378,-0.62805 -0.40057,-2.17347 -0.40057,-2.17347l5.0336,-1.88664c0.45769,-0.22748 0.9725,-0.11374 1.37282,0.17309l5.83425,4.40134c0,0 0.28609,1.65915 -0.62904,2.05973l-4.46167,2.00286c-0.40033,0.05687 -0.97225,0 -1.37282,-0.28683l-0.00001,0z" fill="#2B0691" id="svg_21" />
              <path stroke="null" d="m34.86212,276.03376l-5.37681,-4.34694c-0.74353,-0.62805 -0.40033,-2.17347 -0.40033,-2.17347l4.97624,-1.82977c0.45769,-0.22748 0.9725,-0.11374 1.37282,0.17309l5.83425,4.40381c0,0 0.28609,1.65669 -0.62904,2.05726l-4.46167,2.00286c-0.40033,0.11374 -0.91513,0.05687 -1.31546,-0.28683z" fill="#D88705" id="svg_22" />
              <path stroke="null" d="m34.86212,275.11887l-5.37681,-4.2332c-0.74378,-0.62805 -0.6293,-1.88664 0.28584,-2.28722l4.29007,-1.88664c0.45769,-0.22748 0.9725,-0.11374 1.37282,0.17062l5.37681,4.2332c0.74353,0.62805 0.62904,1.88664 -0.28609,2.28722l-4.29007,1.88664c-0.40033,0.17309 -0.91513,0.11622 -1.37258,-0.17062l0.00001,0z" fill="url(#paint16_linear_296_3255)" id="svg_23" />
              <path stroke="null" opacity="0.6" d="m51.39261,289.75951l-5.66264,-4.51756c-0.74353,-0.63053 -0.40033,-2.23034 -0.40033,-2.23034l5.03335,-1.94598c0.45769,-0.22748 1.02961,-0.11374 1.37282,0.17309l5.89161,4.40381c0,0 0.28609,1.59981 -0.6293,2.05726c-1.02961,0.57366 -4.17558,2.23034 -4.17558,2.23034c-0.57193,0.22996 -1.02937,0.17309 -1.42994,-0.17062z" fill="#2B0691" id="svg_24" />
              <path stroke="null" d="m51.39261,289.41581l-5.66264,-4.51756c-0.74353,-0.62805 -0.40033,-2.23034 -0.40033,-2.23034l5.03335,-1.94351c0.45769,-0.22996 1.02961,-0.11622 1.37282,0.17062l5.89161,4.40381c0,0 0.28609,1.59981 -0.6293,2.05726c-1.02961,0.57366 -4.17558,2.23282 -4.17558,2.23282c-0.45744,0.17062 -1.02937,0.11374 -1.42994,-0.17309l0,-0.00001z" fill="#0694BA" id="svg_25" />
              <path stroke="null" d="m51.44998,288.44405l-5.60578,-4.46068c-0.74353,-0.63053 -0.62904,-1.88664 0.28609,-2.28722l4.29007,-1.94598c0.45744,-0.22748 1.02961,-0.11374 1.37258,0.17309l5.37681,4.23073c0.74378,0.63053 0.57218,1.7729 -0.28584,2.28722c-0.9725,0.57366 -4.00398,2.1166 -4.00398,2.1166c-0.51481,0.22996 -1.08698,0.17309 -1.42994,-0.11374l-0.00001,-0.00001z" fill="url(#paint17_linear_296_3255)" id="svg_26" />
              <path stroke="null" opacity="0.6" d="m61.34533,297.82286l-6.34906,-5.14809c-0.8009,-0.62805 -0.34321,-2.28722 -0.34321,-2.28722l5.0336,-2.11412c0.45769,-0.22996 1.02961,-0.11622 1.37282,0.22748l6.34906,4.69064c0,0 0.1716,1.48607 -0.80065,2.171c-0.91538,0.63053 -3.83238,2.69025 -3.83238,2.69025c-0.51481,0.17062 -1.08698,0.11374 -1.43018,-0.22996l-0.00001,0.00001z" fill="#2B0691" id="svg_27" />
              <path stroke="null" d="m61.40269,297.42229l-6.34906,-5.14561c-0.8009,-0.63053 -0.34321,-2.28968 -0.34321,-2.28968l5.03335,-2.11412c0.45769,-0.22996 1.02961,-0.11622 1.37282,0.22748l6.1777,4.51756c0,0 0.57193,1.43167 -0.6293,2.34656c-0.9725,0.7418 -3.83238,2.68779 -3.83238,2.68779c-0.51481,0.17062 -1.02961,0.11374 -1.42994,-0.22996l0,-0.00001z" fill="#2178D8" id="svg_28" />
              <path stroke="null" d="m61.45981,296.45054l-6.34906,-5.08874c-0.8009,-0.63053 -0.6293,-1.94598 0.28609,-2.34656l4.2327,-2.1166c0.45744,-0.22748 1.02961,-0.11374 1.37282,0.22996l5.54816,4.57442c0.91538,0.74427 0.6293,1.71603 -0.22872,2.28722c-0.91513,0.63053 -3.60341,2.51717 -3.60341,2.51717c-0.34321,0.40057 -0.91538,0.28683 -1.25858,-0.05687z" fill="url(#paint18_linear_296_3255)" id="svg_29" />
              <g stroke="null" opacity="0.6" id="svg_30">
                <path stroke="null" d="m76.16004,350.09496l-4.74751,-11.09484l-63.1482,-57.7614l0.05722,-2.00286l64.34932,58.79003l5.09097,12.06906l-1.60179,0l-0.00001,0.00001z" fill="#6B2FDD" id="svg_31" />
              </g>
              <path stroke="null" d="m16.95863,215.41347l-2.1736,0c-0.80077,0 -1.37277,-0.62904 -1.37277,-1.37258l0,-18.01458c0,-0.80065 0.62919,-1.37258 1.37277,-1.37258l2.1736,0c0.8008,0 1.37277,0.6293 1.37277,1.37258l0,18.01458c0,0.80065 -0.62919,1.37258 -1.37277,1.37258z" fill="url(#paint19_linear_296_3255)" id="svg_32" />
              <path stroke="null" d="m17.35911,230.62578l-3.03156,0c-0.572,0 -0.9724,-0.45744 -0.9724,-0.97225l0,-8.40681c0,-0.57193 0.45759,-0.97225 0.9724,-0.97225l3.03156,0c0.572,0 0.9724,0.45769 0.9724,0.97225l0,8.40681c0,0.57193 -0.4004,0.97225 -0.9724,0.97225z" fill="url(#paint20_linear_296_3255)" id="svg_33" />
              <path stroke="null" d="m17.35911,132.14605l-3.03156,0c-0.572,0 -0.9724,-0.45744 -0.9724,-0.972l0,-8.40681c0,-0.57193 0.45759,-0.97225 0.9724,-0.97225l3.03156,0c0.572,0 0.9724,0.45744 0.9724,0.97225l0,8.40681c0,0.45744 -0.4004,0.972 -0.9724,0.972z" fill="url(#paint21_linear_296_3255)" id="svg_34" />
              <path stroke="null" d="m71.6415,317.32475l7.26419,18.12957l47.2469,0l-3.66078,-18.18643l-50.85031,0.05687l-0.00001,-0.00001z" fill="url(#paint22_radial_296_3255)" id="svg_35" />
              <path stroke="null" d="m137.99293,293.81962l-58.51507,0l-7.83636,23.50513l66.35143,0l0,-23.50513z" fill="url(#paint23_radial_296_3255)" id="svg_36" />
              <path stroke="null" d="m130.61401,293.81962l-51.1364,0l-0.11424,-4.74751l51.02192,0l0.22872,4.74751z" fill="url(#paint24_linear_296_3255)" id="svg_37" />
              <path stroke="null" d="m154.23759,281.58242l0.11424,7.89027l-41.58401,0l-33.46156,0l0,-1.82977c0,-4.29007 4.51879,-9.77938 8.98021,-9.77938c11.55426,0 54.22525,-0.11374 62.17585,-0.05687c2.11635,-0.05687 3.77526,1.60229 3.77526,3.77575l0.00001,0.00001z" fill="url(#paint25_radial_296_3255)" id="svg_38" />
              <path stroke="null" opacity="0.7" d="m79.19177,287.64291c0,-4.29007 4.63303,-9.89312 9.0947,-9.89312c4.40431,0 11.15393,0 20.93505,0.05687c0,0 -11.84035,0.45744 -18.1894,0.80114c-5.14809,0.28436 -7.89373,1.71603 -10.0672,4.86125c-1.65866,2.28722 -1.77315,4.17385 -1.77315,4.17385z" fill="white" id="svg_39" />
              <path stroke="null" d="m125.18009,303.65588c11.78323,0 21.33537,-1.97071 21.33537,-4.40381c0,-2.43063 -9.55215,-4.40381 -21.33537,-4.40381c-11.78323,0 -21.33537,1.97318 -21.33537,4.40381c0,2.4331 9.55215,4.40381 21.33537,4.40381z" fill="url(#paint26_radial_296_3255)" id="svg_40" />
              <path stroke="null" d="m100.69875,340.71367c-1.77315,-0.3981 -1.94475,-10.06374 -0.28609,-21.67289c1.65891,-11.60915 4.51879,-20.76048 6.29194,-20.41677c1.77339,0.3437 1.94475,10.06374 0.28609,21.67536c-1.65891,11.66603 -4.46167,20.75801 -6.29194,20.41431z" fill="url(#paint27_radial_296_3255)" id="svg_41" />
              <path stroke="null" d="m89.37321,349.63504c-1.71603,-0.3981 -1.65891,-9.43568 0,-20.24369c1.71603,-10.80801 4.57591,-19.15819 6.29194,-18.75762c1.71603,0.40057 1.65866,9.43568 0,20.24369c-1.77315,10.75114 -4.57591,19.15819 -6.29194,18.75762z" fill="url(#paint28_radial_296_3255)" id="svg_42" />
              <path stroke="null" d="m111.05179,342.14534l-6.40618,0c-3.31757,0 -6.0061,-2.68779 -6.0061,-6.0061l0,-0.22748c0.11448,-0.97423 3.03173,-22.87708 4.57591,-30.48299c1.02961,-5.08874 5.14809,-8.69141 10.12432,-8.69141l11.78323,0l0,3.42958l-11.78323,0c-3.37469,0 -6.06297,2.40343 -6.80675,5.94923c-1.42994,7.26221 -4.28982,28.30705 -4.51854,30.13682c0.05712,1.43167 1.20097,2.4603 2.57379,2.4603l6.40642,0l0.05712,3.43206l0,-0.00001z" fill="url(#paint29_linear_296_3255)" id="svg_43" />
              <path stroke="null" d="m111.05179,342.14534l-6.40618,0c-3.31757,0 -6.0061,-2.68779 -6.0061,-6.0061l0,-0.22748c0.11448,-0.97423 3.03173,-22.87708 4.57591,-30.48299c1.02961,-5.08874 5.14809,-8.69141 10.12432,-8.69141l11.78323,0l0,3.42958l-11.78323,0c-3.37469,0 -6.06297,2.40343 -6.80675,5.94923c-1.42994,7.26221 -4.28982,28.30705 -4.51854,30.13682c0.05712,1.43167 1.20097,2.4603 2.57379,2.4603l6.40642,0l0.05712,3.43206l0,-0.00001z" fill="#B4D6F2" id="svg_44" />
              <path stroke="null" d="m111.05179,342.14534l-6.40618,0c-3.31757,0 -6.0061,-2.68779 -6.0061,-6.0061l0,-0.22748c0.11448,-0.97423 3.03173,-22.87708 4.57591,-30.48299c1.02961,-5.08874 5.14809,-8.69141 10.12432,-8.69141l11.78323,0l0,3.42958l-11.78323,0c-3.37469,0 -6.06297,2.40343 -6.80675,5.94923c-1.42994,7.26221 -4.28982,28.30705 -4.51854,30.13682c0.05712,1.43167 1.20097,2.4603 2.57379,2.4603l6.40642,0l0.05712,3.43206l0,-0.00001z" fill="url(#paint30_linear_296_3255)" id="svg_45" />
              <path stroke="url(#paint31_linear_296_3255)" d="m121.29059,298.05282l-7.95085,0c-4.80463,0 -8.23669,3.48646 -8.9231,8.23397l-4.51854,29.33815c-0.40057,2.51717 1.54418,4.86125 4.11822,4.86125l3.14597,0" strokeWidth="1.4744" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="svg_46" />
              <path stroke="null" d="m125.00848,312.23354c16.61654,0 30.08687,-2.02264 30.08687,-4.51756c0,-2.49491 -13.47033,-4.51756 -30.08687,-4.51756c-16.61654,0 -30.08687,2.02264 -30.08687,4.51756c0,2.49491 13.47033,4.51756 30.08687,4.51756z" fill="url(#paint32_radial_296_3255)" id="svg_47" />
              <path stroke="null" d="m361.98602,272.65858l0,21.50475l-58.34248,55.93163l-29.40243,0l5.20496,-14.64064l82.53996,-62.79574z" fill="url(#paint33_radial_296_3255)" id="svg_48" />
              <path stroke="null" d="m281.16209,30.52109c0,0 29.34556,12.41005 47.4776,31.74009c18.13203,19.3298 13.26831,56.33121 13.26831,56.33121l12.06906,16.64201l5.54866,-15.26943l2.57404,-38.5456c0,0 -3.2021,-26.24979 -30.82916,-45.23638c-20.42172,-13.5539 -29.97362,-19.61598 -66.752,-28.13715l-0.91488,13.9542l17.55838,8.52105l-0.00001,0.00001z" fill="url(#paint34_linear_296_3255)" id="svg_49" />
              <path stroke="null" d="m345.85684,89.0829l16.70036,-4.51805l0.11622,171.10901l-17.56085,-3.5458l0.74427,-163.04516l0,0.00001z" fill="url(#paint35_radial_296_3255)" id="svg_50" />
              <path stroke="null" d="m345.85684,89.54034l4.51756,-1.20097l-0.85801,163.78869l-4.40381,0l0.74427,-162.58772z" fill="url(#paint36_linear_296_3255)" id="svg_51" />
              <path stroke="null" d="m281.16209,30.52109c0,0 29.34556,12.41005 47.4776,31.74009c18.13203,19.3298 13.26831,56.33121 13.26831,56.33121l12.06906,16.64201l7.20781,-14.52615l0.85801,-39.80345c-4.29007,-18.98683 -14.98681,-33.85595 -30.77229,-44.72182c-20.42172,-13.5539 -29.97362,-19.61598 -66.752,-28.13715l-0.91488,13.9542l17.55838,8.52105l-0.00001,0.00001z" fill="url(#paint37_radial_296_3255)" id="svg_52" />
              <path stroke="null" d="m349.63012,249.44027l-62.86251,40.03241l-0.05687,32.25589l9.32441,0l69.15296,-65.59727l-15.558,-6.69102l0.00001,0z" fill="url(#paint38_radial_296_3255)" id="svg_53" />
              <path stroke="null" d="m349.63012,249.44027l-61.20335,46.49595l-0.91488,-7.09159l60.00163,-41.86218l2.1166,2.45783l0,-0.00001z" fill="url(#paint39_linear_296_3255)" id="svg_54" />
              <path stroke="null" d="m253.5919,30.63557c-0.05687,0.11424 2.63339,1.02937 2.804,1.08649c1.60229,0.45769 3.14523,1.08673 4.69064,1.65866c3.2021,1.20097 6.40667,2.40194 9.5519,3.71716c8.23644,2.63067 12.52651,3.83164 19.84806,7.37744c1.82977,0.91513 3.60267,1.88713 5.37804,2.91676c10.9811,6.17622 21.27726,12.52428 29.97115,21.61726c5.03434,5.20422 11.26792,15.95585 11.84158,27.05044c0.45744,9.32194 1.25612,148.74922 1.25612,148.74922l3.60514,1.31546l3.65954,-6.23407l0,-148.80584c0,-9.26482 -5.77614,-17.2713 -9.20819,-21.78911c-5.49178,-7.49192 -12.06906,-12.81037 -19.90494,-18.35779c-7.43777,-5.26133 -16.47288,-10.52266 -24.59557,-14.75463c-10.75361,-5.6619 -32.43392,-13.26806 -32.43392,-13.26806c0,0 -6.46354,7.66353 -6.46354,7.72065l-0.00001,-0.00001z" fill="url(#paint40_linear_296_3255)" id="svg_55" />
              <path stroke="null" d="m354.83508,179.15557l2.05973,0c0.63053,0 1.08797,-0.51481 1.08797,-1.08673l0,-9.09297c0,-0.62904 -0.51431,-1.08649 -1.08797,-1.08649l-2.05973,0c-0.62805,0 -1.0855,0.51456 -1.0855,1.08649l0,9.09297c0.05687,0.6293 0.51431,1.08673 1.0855,1.08673z" fill="url(#paint41_linear_296_3255)" id="svg_56" />
              <path stroke="null" d="m254.44991,27.60458c17.56085,5.66165 35.63601,12.29557 51.30775,22.07495c7.0372,4.34645 13.84444,9.09297 19.62058,14.92623c7.4353,7.49192 13.67135,17.72874 14.4725,28.30878c0.57119,7.49168 0.68493,14.98334 0.79867,22.53239c0.17309,10.52291 0.17309,21.10269 0.11622,31.56849c-0.05687,11.4378 0.05687,94.53336 0.05687,97.96418l6.80724,1.88664l0.17062,-154.35202c0,-9.09322 -4.34694,-19.10132 -9.5519,-26.36427c-4.97747,-7.09135 -9.09445,-10.23682 -16.41601,-16.4704c-13.72823,-11.6094 -29.39996,-21.4459 -46.61711,-27.50788c-4.52003,-1.60142 -8.92384,-2.91676 -13.72823,-3.48866c-2.4603,3.08833 -4.74751,6.00483 -7.0372,8.92159l-0.00002,0z" fill="url(#paint42_linear_296_3255)" id="svg_57" />
              <path stroke="null" d="m354.83508,164.91551l2.05973,0c0.63053,0 1.08797,-0.51481 1.08797,-1.08649l0,-9.09322c0,-0.62904 -0.51431,-1.08649 -1.08797,-1.08649l-2.05973,0c-0.62805,0 -1.0855,0.51456 -1.0855,1.08649l0,9.09322c0.05687,0.57168 0.51431,1.08649 1.0855,1.08649z" fill="url(#paint43_linear_296_3255)" id="svg_58" />
              <path stroke="null" d="m354.83508,122.30955l2.05973,0c0.63053,0 1.08797,-0.51456 1.08797,-1.08649l0,-9.09297c0,-0.62904 -0.51431,-1.08673 -1.08797,-1.08673l-2.05973,0c-0.62805,0 -1.0855,0.51481 -1.0855,1.08673l0,9.09297c0.05687,0.57193 0.51431,1.08649 1.0855,1.08649z" fill="url(#paint44_linear_296_3255)" id="svg_59" />
              <path stroke="null" d="m347.51352,246.98244l-8.63701,-2.57404l-55.76844,37.28776l6.40667,7.77652l57.99878,-42.49024l0,0.00001z" fill="url(#paint45_radial_296_3255)" id="svg_60" />
              <path stroke="null" d="m365.18811,256.1313l-70.92586,61.19346l-49.87855,0l0.80114,18.12957l46.04592,0l73.95735,-65.13983l0,-14.18319z" fill="url(#paint46_radial_296_3255)" id="svg_61" />
              <path stroke="null" d="m279.1617,26.74657c0,0 9.95247,3.889 21.39101,10.12259c9.15379,4.91813 21.79405,13.3823 21.79405,13.3823c0,0 -5.77614,-5.1471 -17.38776,-13.15358c-13.15704,-8.46393 -23.05264,-12.23845 -23.05264,-12.23845l-2.74465,1.88713l-0.00001,0.00001z" fill="url(#paint47_linear_296_3255)" id="svg_62" />
              <path stroke="null" opacity="0.5" d="m305.70821,298.76001c3.75597,-0.52915 6.52041,-2.93258 6.17671,-5.3731c-0.3437,-2.44052 -3.66449,-3.99088 -7.41799,-3.46173c-3.7535,0.52668 -6.52041,2.93258 -6.17671,5.3731c0.3437,2.43805 3.66449,3.9884 7.41799,3.46173z" fill="#00389B" id="svg_63" />
              <path stroke="null" d="m311.47941,292.84787c0.3437,2.28722 -2.34656,4.6313 -5.94923,5.08874c-3.60267,0.51431 -6.80724,-0.97176 -7.15094,-3.31584c-0.34123,-2.28722 2.34656,-4.63377 5.94923,-5.09121c3.60514,-0.45744 6.80724,1.0311 7.15094,3.31832z" fill="url(#paint48_radial_296_3255)" id="svg_64" />
              <path stroke="null" opacity="0.4" d="m313.70975,293.24844c0.45744,3.08836 -3.08836,6.11984 -7.89274,6.80477c-4.80685,0.6874 -8.98071,-1.31546 -9.43815,-4.34694c-0.45744,-3.08836 3.08836,-6.11737 7.89274,-6.80477c4.74751,-0.6874 9.03758,1.25858 9.43815,4.34694z" fill="url(#paint49_radial_296_3255)" id="svg_65" />
              <path stroke="null" opacity="0.4" d="m328.63969,280.78127c0.45744,3.08836 -3.09083,6.11737 -7.89521,6.80477c-4.80438,0.6874 -8.98071,-1.31546 -9.43815,-4.34694c-0.45744,-3.08836 3.09083,-6.11984 7.89521,-6.80477c4.80438,-0.6874 8.98071,1.25858 9.43815,4.34694z" fill="url(#paint50_radial_296_3255)" id="svg_66" />
              <path stroke="null" d="m320.51946,286.40905c3.7535,-0.52668 6.51794,-2.93258 6.17424,-5.3731c-0.34123,-2.44052 -3.66201,-3.9884 -7.41799,-3.46173c-3.7535,0.52668 -6.51794,2.93258 -6.17671,5.3731c0.3437,2.43805 3.66449,3.9884 7.42046,3.46173z" fill="#7405A3" id="svg_67" />
              <path stroke="null" d="m326.29313,280.60819c0.3437,2.28722 -2.34408,4.63377 -5.94923,5.09121c-3.60267,0.51431 -6.80477,-0.97176 -7.14847,-3.31832c-0.3437,-2.34408 2.34408,-4.6313 5.94923,-5.08874c3.60267,-0.45744 6.80477,0.97176 7.14847,3.31584z" fill="url(#paint51_radial_296_3255)" id="svg_68" />
              <path stroke="null" opacity="0.4" d="m344.53891,268.42785c0.45991,3.08836 -3.08836,6.11984 -7.89274,6.80477c-4.80438,0.6874 -8.98071,-1.31546 -9.43815,-4.34694c-0.45744,-3.08588 3.08836,-6.11737 7.89521,-6.80477c4.80438,-0.68493 9.03758,1.25858 9.43568,4.34694z" fill="url(#paint52_radial_296_3255)" id="svg_69" />
              <path stroke="null" opacity="0.5" d="m336.4261,274.16937c3.7535,-0.52668 6.52041,-2.93258 6.17671,-5.37063c-0.3437,-2.44052 -3.66449,-3.99088 -7.41799,-3.4642c-3.7535,0.52915 -6.52041,2.93505 -6.17671,5.3731c0.3437,2.44052 3.66449,3.99088 7.41799,3.46173l0,0.00001z" fill="#012E56" id="svg_70" />
              <path stroke="null" d="m342.19483,268.31411c0.3437,2.28722 -2.34408,4.6313 -5.94923,5.08874c-3.60267,0.51431 -6.80724,-0.97176 -7.14847,-3.31584c-0.3437,-2.28722 2.34408,-4.63377 5.94675,-5.09121c3.60514,-0.51431 6.80724,1.0311 7.15094,3.31832l0.00001,0z" fill="url(#paint53_radial_296_3255)" id="svg_71" />
              <g stroke="null" opacity="0.6" id="svg_72">
                <path stroke="null" d="m293.06054,350.09248l-1.60229,0l5.2643,-12.0666l0.17062,-0.11374l64.97911,-59.81866l0,2.23034l-63.89361,58.61694l-4.91813,11.15171l0,0.00001z" fill="#223CAA" id="svg_73" />
              </g>
              <path stroke="null" d="m146.97315,374.22814l-49.64934,0c-3.31757,0 -6.52066,-1.4292 -8.75149,-3.83263c-2.05899,-2.17347 -3.03148,-4.975 -2.85988,-7.94961c0.91513,-11.77977 4.91912,-34.77059 7.09284,-47.18088l0.34321,-1.88664c0.91513,-5.14809 5.03335,-8.40705 10.86785,-8.40705l20.76345,0l0,3.43206l-20.76345,0c-1.94475,0 -6.63515,0.57119 -7.49315,5.60306l-0.34321,1.88911c-2.11635,12.29408 -6.12033,35.17116 -7.03547,46.83718c-0.1716,1.94351 0.57193,3.83263 1.94475,5.3187c1.65866,1.71603 3.8895,2.68779 6.23457,2.68779l49.64934,0c4.28982,0 5.66264,-4.17385 6.17745,-6.63416c0.40033,-2.00038 1.4873,-8.06335 1.4873,-8.06335l3.37469,0.62805c0,0 -1.1441,6.06297 -1.4873,8.06335c-1.08673,6.0061 -4.63303,9.49503 -9.55215,9.49503l0,-0.00001z" fill="#A9E6E9" id="svg_74" />
              <path stroke="null" d="m146.97315,374.22814l-49.64934,0c-3.31757,0 -6.52066,-1.4292 -8.75149,-3.83263c-2.05899,-2.17347 -3.03148,-4.975 -2.85988,-7.94961c0.91513,-11.77977 4.91912,-34.77059 7.09284,-47.18088l0.34321,-1.88664c0.91513,-5.14809 5.03335,-8.40705 10.86785,-8.40705l20.76345,0l0,3.43206l-20.76345,0c-1.94475,0 -6.63515,0.57119 -7.49315,5.60306l-0.34321,1.88911c-2.11635,12.29408 -6.12033,35.17116 -7.03547,46.83718c-0.1716,1.94351 0.57193,3.83263 1.94475,5.3187c1.65866,1.71603 3.8895,2.68779 6.23457,2.68779l49.64934,0c4.28982,0 5.66264,-4.17385 6.17745,-6.63416c0.40033,-2.00038 1.4873,-8.06335 1.4873,-8.06335l3.37469,0.62805c0,0 -1.1441,6.06297 -1.4873,8.06335c-1.08673,6.0061 -4.63303,9.49503 -9.55215,9.49503l0,-0.00001z" fill="url(#paint54_linear_296_3255)" id="svg_75" />
              <path stroke="url(#paint55_linear_296_3255)" d="m155.55304,357.81461l-1.42994,7.89274c-0.85801,4.23073 -4.63327,7.31909 -8.9231,7.31909l-46.90369,0c-8.29381,-0.17062 -12.29779,-4.975 -11.15393,-14.46755c1.4873,-11.72537 6.92123,-44.55244 6.92123,-44.55244c0.62904,-4.23073 4.74751,-7.89027 8.98021,-7.89027l17.61748,-0.05687" strokeWidth="1.4744" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="svg_76" />
              <path stroke="null" d="m126.26682,300.79748l-1.83027,0c-0.8009,0 -1.42994,-0.63053 -1.42994,-1.4292l0,-1.94598c0,-0.80114 0.62904,-1.4292 1.42994,-1.4292l2.34507,0l-0.51481,4.80438z" fill="url(#paint56_radial_296_3255)" id="svg_77" />
              <path stroke="null" d="m112.59621,342.7734l-2.63116,0c-0.8009,0 -1.42994,-0.62805 -1.42994,-1.4292l0,-1.94351c0,-0.80114 0.62904,-1.43167 1.42994,-1.43167l1.77315,0l0.85801,4.80438z" fill="url(#paint57_radial_296_3255)" id="svg_78" />
              <path stroke="null" d="m125.75202,309.20453l-2.00187,0c-0.6293,0 -1.20122,-0.51679 -1.20122,-1.20172l0,-2.57404c0,-0.62805 0.51481,-1.20172 1.20122,-1.20172l2.40244,0l-0.40057,4.97747l0,0.00001z" fill="url(#paint58_radial_296_3255)" id="svg_79" />
              <path stroke="null" d="m159.61414,354.84l0,1.60229c0,0.91488 -0.74353,1.65915 -1.65866,1.65915l-3.14597,0c-0.91513,0 -1.65891,-0.74427 -1.65891,-1.65915l0,-1.60229l6.46354,0z" fill="url(#paint59_radial_296_3255)" id="svg_80" />
              <path stroke="null" d="m268.29435,352.55278l-151.17935,0c-1.83027,0 -3.03148,-1.60229 -3.43206,-3.43206c-0.68641,-3.5458 -2.00187,-10.69427 -2.63116,-14.3538c-0.28584,-1.71603 1.4873,-3.25897 3.26045,-3.25897l168.96644,0c0,0 -5.14809,13.55266 -6.80477,17.61278c-1.65915,4.11946 -6.29293,3.43206 -8.17957,3.43206l0.00001,0z" fill="url(#paint60_radial_296_3255)" id="svg_81" />
              <path stroke="null" d="m258.05506,47.90663l15.84483,-9.95099c0.34123,-1.54393 0.97176,-3.25971 0.97176,-3.25971l4.2332,-8.00648l15.78548,-10.2368l-13.15456,-14.58322l-23.68069,46.0372l-0.00001,0z" fill="url(#paint61_linear_296_3255)" id="svg_82" />
              <path stroke="null" d="m87.65719,1.98384l-11.55426,15.0979c5.49129,3.37417 12.75548,7.94929 12.75548,7.94929c0,0 4.29007,6.86263 6.29194,10.23682c0.45769,1.65842 0.68641,2.1161 1.08673,3.5458l16.01593,9.15009l-24.59582,-45.97991z" fill="url(#paint62_linear_296_3255)" id="svg_83" />
              <path stroke="null" d="m95.0361,306.28679c-0.51481,3.14523 -4.11847,5.6624 -8.06533,5.6624c-3.94661,0 -6.74938,-2.68779 -5.71976,-5.77614c1.02961,-3.14523 5.26232,-5.88988 8.75149,-5.88988c3.48918,0 5.49104,2.91527 5.0336,6.00363z" fill="url(#paint63_linear_296_3255)" id="svg_84" />
              <path stroke="null" d="m94.17809,305.31503c-0.22897,2.91527 -3.66078,5.6624 -7.26444,5.6624c-3.66078,0 -5.54841,-2.23034 -5.54841,-5.26183c0,-3.08836 5.14809,-5.49178 8.29405,-5.49178c3.26045,-0.05687 4.80463,1.71603 4.51879,5.09121z" fill="url(#paint64_linear_296_3255)" id="svg_85" />
              <path stroke="null" d="m93.94937,304.28641c-0.45769,2.8584 -3.77526,5.20248 -7.43604,5.20248c-3.66078,0 -5.83425,-2.34408 -4.91912,-5.20248c0.91513,-2.86087 4.2327,-5.20496 7.43579,-5.20496c3.20333,0 5.43392,2.28722 4.91936,5.20496z" fill="url(#paint65_linear_296_3255)" id="svg_86" />
              <path stroke="null" d="m242.78216,331.73543l40.32592,0c3.5458,0 6.40667,-2.8584 6.40667,-6.4042l0,-35.80169l-49.30663,0l2.57404,42.20588l-0.00001,0z" fill="url(#paint66_linear_296_3255)" id="svg_87" />
              <path stroke="null" d="m254.623,302.28355c-2.23034,0 -4.11946,-1.82977 -4.11946,-4.11698c0,-2.23034 1.83224,-4.11698 4.11946,-4.11698c2.23034,0 4.11698,1.82977 4.11698,4.11698c-0.05687,2.28722 -1.88664,4.11698 -4.11698,4.11698z" fill="#101B68" id="svg_88" />
              <path stroke="null" d="m254.623,302.68412c-2.4603,0 -4.5769,-2.00286 -4.5769,-4.57442c0,-2.57404 2.00286,-4.5769 4.5769,-4.5769c2.57404,0 4.57442,2.00286 4.57442,4.5769c0,2.57157 -2.05726,4.57442 -4.57442,4.57442zm0,-8.12023c-2.00286,0 -3.66201,1.65669 -3.66201,3.65954c0,2.00038 1.65915,3.65954 3.66201,3.65954c2.00038,0 3.65954,-1.65915 3.65954,-3.65954c0,-2.00286 -1.65915,-3.65954 -3.65954,-3.65954z" fill="url(#paint67_linear_296_3255)" id="svg_89" />
              <path stroke="null" d="m254.623,315.77934c-2.23034,0 -4.11946,-1.82977 -4.11946,-4.11698c0,-2.23034 1.83224,-4.11698 4.11946,-4.11698c2.23034,0 4.11698,1.82977 4.11698,4.11698c-0.05687,2.34408 -1.88664,4.11698 -4.11698,4.11698z" fill="#101B68" id="svg_90" />
              <path stroke="null" d="m254.623,316.23679c-2.4603,0 -4.5769,-2.00038 -4.5769,-4.57442c0,-2.57404 2.00286,-4.57442 4.5769,-4.57442c2.57404,0 4.57442,2.00038 4.57442,4.57442c0,2.57404 -2.05726,4.57442 -4.57442,4.57442zm0,-8.12023c-2.00286,0 -3.66201,1.65915 -3.66201,3.65954c0,2.00286 1.65915,3.66201 3.66201,3.66201c2.00038,0 3.65954,-1.65915 3.65954,-3.66201c0,-2.00038 -1.65915,-3.65954 -3.65954,-3.65954z" fill="url(#paint68_linear_296_3255)" id="svg_91" />
              <path stroke="null" d="m277.78938,296.50741l0,28.36639c0,1.48607 1.19924,2.63091 2.63091,2.63091c1.48607,0 2.63091,-1.20172 2.63091,-2.63091l0,-28.30952c0,-1.48607 -1.20172,-2.63091 -2.63091,-2.63091c-1.48854,-0.05687 -2.63091,1.14484 -2.63091,2.57404l0,-0.00001z" fill="url(#paint69_radial_296_3255)" id="svg_92" />
              <path stroke="null" d="m265.43348,296.50741l0,28.36639c0,1.48607 1.20172,2.63091 2.63091,2.63091c1.48607,0 2.63091,-1.20172 2.63091,-2.63091l0,-28.30952c0,-1.48607 -1.20172,-2.63091 -2.63091,-2.63091c-1.4292,-0.05687 -2.63091,1.14484 -2.63091,2.57404l0,-0.00001z" fill="url(#paint70_radial_296_3255)" id="svg_93" />
              <path stroke="null" d="m278.53117,300.68126l0,2.74713c0,1.02862 0.85801,1.82977 1.83224,1.82977c1.02862,0 1.82977,-0.85801 1.82977,-1.82977l0,-2.74713c0,-1.02862 -0.85801,-1.82977 -1.82977,-1.82977c-0.97423,0 -1.83224,0.80114 -1.83224,1.82977z" fill="url(#paint71_linear_296_3255)" id="svg_94" />
              <path stroke="null" d="m266.23462,317.66846l0,2.74465c0,1.02862 0.85801,1.82977 1.82977,1.82977c1.02862,0 1.82977,-0.85801 1.82977,-1.82977l0,-2.74465c0,-1.0311 -0.85801,-1.82977 -1.82977,-1.82977c-1.02862,-0.05934 -1.82977,0.7418 -1.82977,1.82977z" fill="url(#paint72_linear_296_3255)" id="svg_95" />
              <path stroke="null" d="m213.78203,289.47268l75.73272,0l-0.05687,-2.97214c0,-4.74751 -1.94598,-8.98071 -10.23929,-8.8645c-11.44101,0.17062 -51.59409,0.17062 -59.60205,0.22748c-2.05924,0 -4.17558,1.71603 -4.51879,3.83263c-0.6293,4.74504 -1.3157,7.77652 -1.3157,7.77652z" fill="url(#paint73_radial_296_3255)" id="svg_96" />
              <path stroke="null" d="m184.78189,357.01347c51.96622,0 94.09298,-14.87554 94.09298,-33.22764c0,-18.34963 -42.12675,-33.22517 -94.09298,-33.22517c-51.96622,0 -94.09323,14.87554 -94.09323,33.22517c0,18.3521 42.127,33.22764 94.09323,33.22764z" fill="url(#paint74_radial_296_3255)" id="svg_97" />
              <path stroke="null" opacity="0.5" d="m128.09709,293.81962l-48.61947,0l-22.02178,-16.41354l21.85018,16.87098l48.73396,0.11374l0.05712,-0.57119l-0.00001,0z" fill="#2B0691" id="svg_98" />
              <path stroke="null" d="m242.49632,287.58604l-115.54308,0l0,-0.11374c0,-4.00324 3.26045,-7.26469 7.26444,-7.26469l100.89973,0c4.11847,0 7.37892,3.31832 7.37892,7.37843z" fill="url(#paint75_linear_296_3255)" id="svg_99" />
              <path stroke="null" opacity="0.7" d="m144.91392,280.20762l-10.69624,0c-4.00398,0 -7.26444,3.26144 -7.26444,7.26469c0,0 0.22872,-3.65954 4.0611,-5.83301c0.68641,-0.40057 2.05924,-0.91488 2.91725,-0.91488c2.74564,-0.11622 10.98233,-0.51679 10.98233,-0.51679l-0.00001,-0.00001z" fill="white" id="svg_100" />
              <path stroke="null" d="m242.49632,287.58604l-115.54308,0l-1.54442,22.76086l118.68806,0l-1.60056,-22.76086z" fill="url(#paint76_linear_296_3255)" id="svg_101" />
              <path stroke="#C2E9F2" d="m159.27094,300.45378l-25.68256,0c-0.51456,0 -0.97225,-0.45744 -0.97225,-0.97176l0,-2.17347c0,-0.80114 0.62904,-1.4292 1.42994,-1.4292l25.16775,0c0.51481,0 0.9725,0.45744 0.9725,0.97176l0,2.68779c0,0.45744 -0.40057,0.91488 -0.91538,0.91488l0,0.00001z" fill="url(#paint77_radial_296_3255)" strokeWidth="0.9923" strokeMiterlimit="10" id="svg_102" />
              <path stroke="null" d="m159.27094,300.74061l-25.68256,0c-0.68641,0 -1.25834,-0.57366 -1.25834,-1.25858l0,-2.17347c0,-0.97176 0.8009,-1.65915 1.65891,-1.65915l25.16775,0c0.68641,0 1.25834,0.57119 1.25834,1.25858l0,2.68779c0.05712,0.57119 -0.45769,1.14484 -1.1441,1.14484zm-25.16775,-4.63377c-0.62904,0 -1.14385,0.51431 -1.14385,1.14484l0,2.17347c0,0.40057 0.28584,0.68493 0.68641,0.68493l25.68256,0c0.40033,0 0.68641,-0.28436 0.68641,-0.68493l0,-2.68779c0,-0.40057 -0.28609,-0.6874 -0.68641,-0.6874l-25.22511,0.05687l-0.00001,-0.00001z" fill="url(#paint78_linear_296_3255)" id="svg_103" />
              <path stroke="null" d="m200.68359,300.45378l-31.28808,0c-0.51481,0 -0.9725,-0.45744 -0.9725,-0.97176l0,-2.68779c0,-0.51431 0.45769,-0.97176 0.9725,-0.97176l31.28808,0c0.51481,0 0.97225,0.45744 0.97225,0.97176l0,2.68779c0,0.51431 -0.40033,0.97176 -0.97225,0.97176z" fill="url(#paint79_radial_296_3255)" id="svg_104" />
              <path stroke="#C2E9F2" d="m210.80766,300.45378l25.62544,0c0.51481,0 0.9725,-0.45744 0.9725,-0.97176l0,-2.17347c0,-0.80114 -0.6293,-1.4292 -1.42994,-1.4292l-25.168,0c-0.51456,0 -0.97225,0.45744 -0.97225,0.97176l0,2.68779c0,0.45744 0.45769,0.91488 0.97225,0.91488l0.00001,0.00001z" fill="url(#paint80_radial_296_3255)" strokeWidth="0.9923" strokeMiterlimit="10" id="svg_105" />
              <path stroke="null" d="m236.4331,300.74061l-25.62544,0c-0.68641,0 -1.25834,-0.57366 -1.25834,-1.25858l0,-2.17347c0,-0.97176 0.8009,-1.65915 1.65891,-1.65915l25.16775,0c0.68641,0 1.25834,0.57119 1.25834,1.25858l0,2.68779c0.05712,0.57119 -0.51481,1.14484 -1.20122,1.14484zm-25.16775,-4.63377c-0.6293,0 -1.1441,0.51431 -1.1441,1.14484l0,2.17347c0,0.40057 0.28609,0.68493 0.68641,0.68493l25.62544,0c0.40033,0 0.68641,-0.28436 0.68641,-0.68493l0,-2.68779c0,-0.40057 -0.28609,-0.6874 -0.68641,-0.6874l-25.16775,0.05687l0,-0.00001z" fill="url(#paint81_linear_296_3255)" id="svg_106" />
              <path stroke="null" d="m184.72478,305.48565c-3.31757,0 -6.00585,-2.68779 -6.00585,-6.00363c0,-3.31832 2.68828,-6.0061 6.00585,-6.0061c3.31757,0 6.00585,2.68779 6.00585,6.0061c0,3.25897 -2.68828,6.00363 -6.00585,6.00363z" fill="url(#paint82_radial_296_3255)" id="svg_107" />
              <path stroke="null" d="m185.23958,301.59862c-1.88763,0 -3.43206,-1.54541 -3.43206,-3.43206c0,-1.88664 1.54442,-3.43206 3.43206,-3.43206c1.88763,0 3.43206,1.54541 3.43206,3.43206c0,1.88664 -1.54442,3.43206 -3.43206,3.43206z" fill="url(#paint83_linear_296_3255)" id="svg_108" />
              <path stroke="null" d="m197.70897,267.34235l0,-1.7729c0,-2.51717 -2.05899,-4.63377 -4.63303,-4.63377l-16.81658,0c-2.51692,0 -4.63327,2.05973 -4.63327,4.63377l0,1.7729l26.08288,0z" fill="url(#paint84_radial_296_3255)" id="svg_109" />
              <path stroke="null" opacity="0.7" d="m171.74058,267.34235l0,-1.7729c0,-2.51717 2.05924,-4.63377 4.63303,-4.63377l16.81683,0l-16.53074,0.3437c-1.37282,0.11374 -3.03148,0.62805 -4.17558,2.45783c-0.57193,1.0311 -0.74353,3.60514 -0.74353,3.60514l0,-0.00001z" fill="white" id="svg_110" />
              <path stroke="null" d="m188.32819,300.45378c28.14707,0 50.9648,-4.73762 50.9648,-10.58053c0,-5.8429 -22.81773,-10.58053 -50.9648,-10.58053c-28.14707,0 -50.96455,4.73762 -50.96455,10.58053c0,5.8429 22.81749,10.58053 50.96455,10.58053z" fill="url(#paint85_radial_296_3255)" id="svg_111" />
              <path stroke="null" d="m79.30601,289.41581l-53.13826,-40.03241l53.13826,39.34749l0,0.68493l0,-0.00001z" fill="url(#paint86_linear_296_3255)" id="svg_112" />
              <path stroke="null" d="m74.61586,315.83868c-0.51481,0 -0.85801,-0.51679 -0.74378,-0.97423l5.37681,-20.64426l-6.52066,19.50189c-0.28609,0.91488 -1.42994,1.31546 -2.17372,0.7418l-65.60773,-56.10225l66.63735,58.90625l19.79094,0l-16.75921,-1.4292l0,0.00001z" fill="url(#paint87_linear_296_3255)" id="svg_113" />
              <path stroke="null" d="m150.86265,268.82842c-1.48706,0 -2.80276,0.85801 -3.37469,2.17347l-8.80886,18.587c-0.45744,1.0855 -0.51481,3.71641 1.4873,3.71641l89.34572,0c1.54418,0 1.88739,-2.63091 1.48706,-3.65954l-8.46541,-18.587c-0.57218,-1.37233 -1.88763,-2.23034 -3.37494,-2.23034l-68.29618,0z" fill="url(#paint88_linear_296_3255)" id="svg_114" />
              <path stroke="null" d="m151.54906,267.34235c-1.83051,0 -3.60366,1.14237 -4.34719,2.80153l-8.52278,19.50189c-0.45744,1.0855 0.28609,2.17347 1.4873,2.17347l89.34547,0c1.1441,0 1.88763,-1.14484 1.4873,-2.17347l-8.23669,-19.44502c-0.74378,-1.71603 -2.45956,-2.91774 -4.40431,-2.91774l-66.80912,0.05934l0.00001,-0.00001z" fill="url(#paint89_linear_296_3255)" id="svg_115" />
              <path stroke="null" d="m140.28089,288.10036l8.17932,-18.587c0.34321,-0.79867 1.08698,-1.25612 1.88763,-1.25612l69.15419,0c0.85801,0 1.65891,0.51431 1.88763,1.31546l7.89348,18.58453c0.57193,1.43167 -0.45769,2.91774 -1.88763,2.91774l-85.16988,0c-1.54442,-0.05687 -2.51692,-1.65915 -1.94475,-2.97461z" fill="#0C056B" id="svg_116" />
              <path stroke="null" d="m151.66354,267.68358c-1.42994,0 -2.68853,0.85801 -3.26045,2.1166l-8.57989,18.12957c-0.45769,1.02862 -0.45769,3.60267 1.42994,3.60267l87.11488,0c1.4873,0 1.83027,-2.51717 1.42994,-3.60267l-8.23669,-18.12957c-0.57193,-1.37233 -1.83027,-2.17347 -3.31757,-2.17347l-66.58016,0l0,0.05687z" fill="url(#paint90_linear_296_3255)" id="svg_117" />
              <path stroke="null" d="m140.28089,288.10036l8.17932,-18.587c0.34321,-0.79867 1.08698,-1.25612 1.88763,-1.25612l69.15419,0c0.85801,0 1.65891,0.51431 1.88763,1.31546l7.89348,18.58453c0.57193,1.43167 -0.45769,2.91774 -1.88763,2.91774l-85.16988,0c-1.54442,-0.05687 -2.51692,-1.65915 -1.94475,-2.97461z" fill="url(#paint91_linear_296_3255)" id="svg_118" />
              <path stroke="null" d="m151.54906,267.34235c-1.83051,0 -3.60366,1.14237 -4.34719,2.80153l-8.52278,19.50189c-0.45744,1.0855 0.28609,2.17347 1.4873,2.17347l89.34547,0c1.1441,0 1.88763,-1.14484 1.4873,-2.17347l-8.23669,-19.44502c-0.74378,-1.71603 -2.45956,-2.91774 -4.40431,-2.91774l-66.80912,0.05934l0.00001,-0.00001z" fill="url(#paint92_linear_296_3255)" id="svg_119" />
              <path stroke="null" d="m258.05506,47.90663l-145.68756,0l-24.65294,-45.92286l194.07807,0l-23.73757,45.92286l0.00001,0z" fill="url(#paint93_linear_296_3255)" id="svg_120" />
              <path stroke="null" d="m112.36749,47.90663l-24.65294,-45.98003l109.30802,0l-102.27255,1.5441c-1.4873,0.0572 -2.34532,1.5441 -1.77315,2.85946l19.39062,41.57646l0.00001,0z" fill="url(#paint94_linear_296_3255)" id="svg_121" />
              <path stroke="null" d="m79.30601,289.41581l0,-0.68493l34.49142,0.68493l-34.49142,0z" fill="url(#paint95_radial_296_3255)" id="svg_122" />
              <path stroke="null" d="m244.44057,327.84594l-118.74568,0l0,13.66888l3.20309,11.78224c0.28609,0.97176 1.20122,1.65669 2.17347,1.65669l108.39313,0c1.08673,0 1.94475,-0.7418 2.17347,-1.71355l2.74564,-11.61163l0,-13.78262l0.05687,0l-0.00001,0z" fill="url(#paint96_linear_296_3255)" id="svg_123" />
              <path stroke="null" d="m244.55431,341.51482l-118.74518,0l-8.12221,-6.51794l134.13207,0l-7.26469,6.51794l0.00001,0z" fill="url(#paint97_linear_296_3255)" id="svg_124" />
              <path stroke="null" d="m159.1567,350.15183l-25.39672,0c-0.57193,0 -1.08673,-0.45744 -1.08673,-1.08797l0,-2.45783c0,-0.57366 0.45744,-1.08797 1.08673,-1.08797l25.39672,0c0.57193,0 1.08673,0.45744 1.08673,1.08797l0,2.45783c-0.05736,0.63053 -0.51481,1.08797 -1.08673,1.08797z" fill="url(#paint98_linear_296_3255)" id="svg_125" />
              <path stroke="null" d="m132.67324,349.1776l0,-2.57157c0,-0.57366 0.45744,-1.08797 1.08673,-1.08797l25.39672,0c0.57193,0 1.08673,0.45744 1.08673,1.08797l0,2.57157c0,0 -0.11448,-1.31298 -0.1716,-1.88664c-0.11448,-0.7418 -0.45769,-0.91488 -1.20122,-0.91488l-24.88191,0c-0.51481,0 -0.97225,0.17309 -1.20097,0.97176l-0.11448,1.82977l0,-0.00001z" fill="url(#paint99_linear_296_3255)" id="svg_126" />
              <path stroke="null" d="m200.56886,350.15183l-31.11648,0c-0.57193,0 -1.08673,-0.45744 -1.08673,-1.08797l0,-2.45783c0,-0.57366 0.45769,-1.08797 1.08673,-1.08797l31.17385,0c0.57193,0 1.08673,0.45744 1.08673,1.08797l0,2.45783c-0.05712,0.63053 -0.51481,1.08797 -1.1441,1.08797l0.00001,0z" fill="url(#paint100_linear_296_3255)" id="svg_127" />
              <path stroke="null" d="m210.92215,350.15183l25.39647,0c0.57193,0 1.08673,-0.45744 1.08673,-1.08797l0,-2.45783c0,-0.57366 -0.45744,-1.08797 -1.08673,-1.08797l-25.39647,0c-0.57218,0 -1.08698,0.45744 -1.08698,1.08797l0,2.45783c0,0.63053 0.45769,1.08797 1.08698,1.08797z" fill="url(#paint101_linear_296_3255)" id="svg_128" />
              <path stroke="null" d="m209.83516,349.1776l0,-2.57157c0,-0.57366 0.45769,-1.08797 1.08698,-1.08797l25.39647,0c0.57193,0 1.08673,0.45744 1.08673,1.08797l0,2.57157c0,0 -0.11424,-1.31298 -0.1716,-1.88664c-0.11424,-0.7418 -0.45744,-0.91488 -1.20122,-0.91488l-24.88166,0c-0.51481,0 -0.9725,0.17309 -1.20122,0.97176l-0.11448,1.82977l0,-0.00001z" fill="url(#paint102_linear_296_3255)" id="svg_129" />
              <path stroke="null" d="m168.42276,349.1776l0,-2.57157c0,-0.57366 0.45769,-1.08797 1.08698,-1.08797l31.11648,0c0.57193,0 1.08673,0.45744 1.08673,1.08797l0,2.57157c0,0 -0.11448,-1.31298 -0.1716,-1.88664c-0.11448,-0.7418 -0.45769,-0.91488 -1.20122,-0.91488l-30.54456,0c-0.51481,0 -0.97225,0.17309 -1.20122,0.97176l-0.1716,1.82977l0,-0.00001z" fill="url(#paint103_linear_296_3255)" id="svg_130" />
              <path stroke="null" d="m250.90411,335.22437l-132.30205,0c-1.02961,0 -1.77315,-0.85801 -1.65866,-1.82977l0.62904,-7.09159l134.18968,0l0.80114,7.03473c0.05687,1.02862 -0.6874,1.88664 -1.65915,1.88664z" fill="url(#paint104_linear_296_3255)" id="svg_131" />
              <path stroke="null" opacity="0.7" d="m129.41279,287.64291l-2.40244,0l-1.02961,14.92747l1.37282,-14.12632c0,-0.28683 0.22897,-0.45744 0.51481,-0.51431l1.54442,-0.28683l0,-0.00001z" fill="white" id="svg_132" />
              <path stroke="null" d="m241.00901,305.0282l-112.16814,0c-0.45769,0 -0.85801,0.22996 -1.02961,0.63053l-10.0672,20.0162c-0.57193,1.14237 0.28609,2.40096 1.54442,2.40096l130.8145,0c1.25858,0 2.05973,-1.31546 1.54541,-2.40096l-9.66687,-19.95934c-0.11448,-0.40057 -0.57218,-0.6874 -0.9725,-0.6874l-0.00001,0z" fill="url(#paint105_linear_296_3255)" id="svg_133" />
              <path stroke="null" d="m26.05326,88.68257l-0.1716,2.51618c0,0 -2.7454,-0.28584 -4.862,-0.91488c-4.11825,-1.25834 -6.92104,-2.51643 -9.03741,-4.06061c-2.00199,-1.48681 -2.1736,-1.94425 -2.1736,-1.94425l0.572,-2.91676c0,0 1.08678,2.34482 7.55032,5.03261c4.86207,2.00162 8.12228,2.28771 8.12228,2.28771z" fill="url(#paint106_linear_296_3255)" id="svg_134" />
              <path stroke="null" d="m347.51352,88.39648l0.05934,2.28771c0,0 2.57404,-0.28609 4.51756,-0.85801c3.83263,-1.14361 6.46354,-2.28746 8.40953,-3.77452c1.88664,-1.37233 2.00038,-1.83002 2.00038,-1.83002l-0.45744,-2.80227c0,0 -1.0855,2.28771 -7.14847,4.80389c-4.46316,1.88739 -7.3809,2.17322 -7.3809,2.17322l0,-0.00001z" fill="url(#paint107_linear_296_3255)" id="svg_135" />
            </g>
            {/* bottom panel */}
            <g id="svg_164">
              <svg width="136" height="24" viewBox="0 0 140 24" fill="none" id="svg_4" x="119.18385" y="305.41553" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#svg_9)" id="svg_147">
                  <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="#0C056B" id="svg_152" />
                  <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="url(#svg_3)" id="svg_151" />
                  <path d="m132.542,22.3534l-129.39484,0c-0.84609,0 -1.41014,-0.9041 -1.0153,-1.6387l9.30694,-18.64664c0.1692,-0.39553 0.5641,-0.62155 1.0153,-0.62155l111.1189,0c0.452,0 0.846,0.22602 1.016,0.62155l8.968,18.64664c0.339,0.7911 -0.169,1.6387 -1.015,1.6387zm-8.235,-22.03699l-112.5298,0c-0.4512,0 -0.846,0.22602 -1.0153,0.62155l-10.09659,20.11584c-0.56406,1.1301 0.28203,2.4297 1.52295,2.4297l131.25574,0c1.241,0 2.087,-1.2996 1.523,-2.4297l-9.645,-20.05933c-0.169,-0.39554 -0.621,-0.67806 -1.015,-0.67806z" fill="url(#svg_2)" id="svg_148" />
                </g>
                <defs>
                  <clipPath id="svg_9">
                    <rect width="134.697" height="23.2236" fill="white" y="0.3169" x="0.49609" id="svg_12" />
                  </clipPath>
                </defs>
                <foreignObject x="0" y="0" width="100%" height="100%">
                  <BottomScreen userEnergy={userEnergy} maxEnergy={maxEnergy} />
                </foreignObject>
              </svg>
            </g>
            <g id="svg_173">
              <svg width="89" height="26" viewBox="0 0 92 26" fill="none" id="svg_165" x="142.2804" y="266" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#svg_166)" id="svg_168">
                  <path d="m2.22814,21.7263l8.25566,-18.88653c0.3393,-0.79882 1.0744,-1.2553 1.9226,-1.2553l64.9711,0c0.8481,0 1.6398,0.51353 1.9225,1.31236l7.9164,18.88657c0.5655,1.4265 -0.4523,2.9671 -1.9225,2.9671l-81.08666,0c-1.52674,-0.0571 -2.60111,-1.5977 -1.9791,-3.0242z" fill="#0C056B" id="svg_172" />
                  <path d="m13.6504,1.01367c-1.4137,0 -2.7142,0.85589 -3.2797,2.16825l-8.65148,18.43008c-0.45236,1.0271 -0.45236,3.6518 1.41365,3.6518l83.06573,0c1.4702,0 1.866,-2.5677 1.4137,-3.6518l-8.3123,-18.37302c-0.5654,-1.36942 -1.866,-2.22531 -3.3362,-2.22531l-62.3134,0z" fill="url(#svg_8)" id="svg_171" />
                  <path d="m2.22814,21.7263l8.25566,-18.88653c0.3393,-0.79882 1.0744,-1.2553 1.9226,-1.2553l64.9711,0c0.8481,0 1.6398,0.51353 1.9225,1.31236l7.9164,18.88657c0.5655,1.4265 -0.4523,2.9671 -1.9225,2.9671l-81.08666,0c-1.52674,-0.0571 -2.60111,-1.5977 -1.9791,-3.0242z" fill="url(#svg_7)" id="svg_170" />
                  <path d="m85.0677,23.8374l-80.40807,0c-1.13092,0 -1.92256,-1.1983 -1.47019,-2.2253l7.57716,-17.28893c0.5089,-1.14118 1.6398,-1.94001 2.8838,-1.94001l62.4831,0c1.3005,0 2.4314,0.79883 2.9404,1.94001l7.2944,17.51713c0.3958,0.97 -0.2828,1.9971 -1.3006,1.9971zm-71.4738,-23.16601c-1.8661,0 -3.61897,1.14118 -4.35407,2.85295l-8.6515,19.79946c-0.45237,1.0842 0.28273,2.2253 1.47019,2.2253l85.32758,0c1.1309,0 1.9225,-1.1411 1.4702,-2.2253l-8.2557,-19.68534c-0.7351,-1.82589 -2.488,-2.96707 -4.4106,-2.96707l-62.5961,0z" fill="url(#svg_6)" id="svg_169" />
                </g>
                <defs>
                  <clipPath id="svg_166">
                    <rect width="88.4941" height="24.9348" fill="white" y="0.67041" x="0.47522" id="svg_167" />
                  </clipPath>
                </defs>
                <foreignObject x="0" y="6" width="100%" height="100%">
                  <MiddleScreen countdown={countdown} userEnergy={userEnergy} maxEnergy={maxEnergy} />
                </foreignObject>
              </svg>
            </g>
            {/* top panel */}
            <g id="svg_183" stroke="null" transform="matrix(1.33735 0 0 1.22845 -59.4995 -1.83685)">
              <svg width="137" height="35" viewBox="0 0 137 35" fill="none" id="svg_174" x="118.05675" y="7.96347" stroke="null" xmlns="http://www.w3.org/2000/svg">
                <path d="m120.086,33.2441l-106.474,0l-12.50409,-32.35396l134.73009,0l-15.752,32.35396z" fill="#0C056B" id="svg_177" stroke="null" />
                <path d="m120.086,33.2441l-106.474,0l-12.50409,-32.35396l134.73009,0l-15.752,32.35396z" fill="url(#svg_11)" id="svg_176" stroke="null" />
                <path d="m120.086,34.0569l-106.474,0c-0.3248,0 -0.6496,-0.2167 -0.7578,-0.5419l-12.50411,-32.29977c-0.10826,-0.27097 -0.05413,-0.54195 0.10826,-0.75872c0.16239,-0.21678 0.37891,-0.37936 0.64956,-0.37936l134.73009,0c0.271,0 0.541,0.16258 0.704,0.37936c0.162,0.21677 0.162,0.54194 0.054,0.81291l-15.752,32.35398c-0.162,0.271 -0.433,0.4335 -0.758,0.4335zm-104.0923,-1.6258l101.9813,0c0.974,0 1.895,-0.5419 2.328,-1.4632l13.099,-26.93457c0.541,-1.08389 -0.271,-2.33035 -1.461,-2.33035l-127.2605,0c-1.13673,0 -1.94869,1.13807 -1.51564,2.22196l10.39304,26.82616c0.3789,1.0297 1.3532,1.68 2.4358,1.68z" fill="url(#svg_10)" id="svg_175" stroke="null" />
                <foreignObject x="0" y="9" width="100%" height="100%">
                  <SpinResult isSpinning={isSpinning} />
                </foreignObject>
              </svg>
            </g>
          </g>
        </g>
      </svg>
      <div className="mt-[-54px] flex justify-center">
        <BottomPart />
      </div>
    </>
  );
}
export default Machine;
