import React, { useEffect } from 'react';
import { useGetEnergyRefillTimer } from '../../hooks/useGetEnergyRefillTimer';
import { useAppSelector } from '../../store';

type RefilCountdownProps = {
    dailyEnergyRefillRefreshSeconds: number
}
function RefilCountdown({ dailyEnergyRefillRefreshSeconds }: RefilCountdownProps) {
  const { questsList } = useAppSelector(
    ({ quests }) => quests,
  );
  const {
    secondsFromLastRefill, countdown,
  } = useGetEnergyRefillTimer();
  useEffect(() => {
    countdown.restart(dailyEnergyRefillRefreshSeconds - secondsFromLastRefill);
  }, [questsList?.daily_energy_refill]);
  return (
    <div className="flex gap-[.7rem]">
      <div className="w-11 bg-white bg-opacity-10 px-4 py-2 rounded-10 text-lg font-bold">{countdown.formatedMinutes[0]}</div>
      <div className="w-11 bg-white bg-opacity-10 px-4 py-2 rounded-10 text-lg font-bold">{countdown.formatedMinutes[1]}</div>
      <div className="py-2 rounded-10 text-lg font-bold">:</div>
      <div className="w-11 bg-white bg-opacity-10 px-4 py-2 rounded-10 text-lg font-bold">{countdown.formatedSecond[0]}</div>
      <div className="w-11 bg-white bg-opacity-10 px-4 py-2 rounded-10 text-lg font-bold">{countdown.formatedSecond[1]}</div>
    </div>
  );
}
export default RefilCountdown;
