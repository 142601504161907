import LoaderLogo from '../../assets/images/logo2x.png';

function Loader() {
  return (
    <div className="absolute h-screen bg-loaderBG bg-cover w-full z-[2] flex justify-center items-center">
      <div className="mt-[-120px] flex justify-center items-center flex-col">
        <img src={LoaderLogo} alt="logo" className="max-w-[62%]" />
        <div className="text-white text-sm">
          Loading..
        </div>
      </div>
    </div>
  );
}

export default Loader;
