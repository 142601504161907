import React, { useMemo } from 'react';
import { ReactComponent as EnergySvg } from '../../../assets/images/energy-40x40.svg';
import Button from '../../../Components/Button';
import RefilCountdown from '../../../Components/RefilCountdown';
import { useGetEnergyRefillTimer } from '../../../hooks/useGetEnergyRefillTimer';
import { useAppSelector } from '../../../store';
import { SettingsResponse } from '../../../store/reducers/settings/types';

type EnergyDrawerContentProps = {
    handleEnergyFill: () => void
}
function EnergyDrawerContent({ handleEnergyFill }: EnergyDrawerContentProps) {
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const {
    countdownTime, completedEnergyQuests, totalEnergyQuests,
  } = useGetEnergyRefillTimer();

  const remainEnergyRefill = useMemo(() => (totalEnergyQuests || 0) - (completedEnergyQuests || 0), [totalEnergyQuests, completedEnergyQuests]);

  return (
    <div className="bg-dailyEnergyBgStar bg-no-repeat bg-energyBgStarPosition h-full">
      <div className="mt-[1.125rem] stroke-none">
        <EnergySvg height="100px" width="100px" className="m-auto" />
      </div>
      <div className="text-lg mt-[.8rem] font-bold">Energy Refill</div>
      <div className="text-sm text-gray-2">Refill your energy to unlock more spins and rare rewards!</div>
      <div className="text-12 mt-[.8rem]">
        {remainEnergyRefill}
        <span className="pl-[.2rem]">{' '}</span>
        <span className="text-gray-2">/</span>
        <span className="pl-[.2rem]">{' '}</span>
        {totalEnergyQuests}
      </div>
      {(countdownTime > 0 && completedEnergyQuests !== totalEnergyQuests)
        ? (
          <div className="flex justify-center items-center mt-[1.3rem]">
            <RefilCountdown dailyEnergyRefillRefreshSeconds={(appSettings as SettingsResponse).daily_energy_refill_refresh_seconds} />
          </div>
        )
        : (
          <div className="px-4">
            <Button
              className="mt-2 w-full"
              style={{ padding: '.7rem', marginTop: '1.3rem', fontSize: '0.875rem' }}
              onClick={handleEnergyFill}
              disabled={completedEnergyQuests === totalEnergyQuests}
            >
              Get Energy
            </Button>
          </div>
        )}
    </div>
  );
}

export default EnergyDrawerContent;
