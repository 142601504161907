import { useAppSelector } from '../../../store';
import { ReactComponent as Coin } from '../../../assets/images/coin-size.svg';
import { ReactComponent as Energy } from '../../../assets/images/energy-22x22.svg';
import { ReactComponent as Revive } from '../../../assets/images/revive.svg';
import { ReactComponent as Booster } from '../../../assets/images/booster.svg';
import { useEnergyContext } from '../../../providers/EnergyProvider';

type SpinResultProps = {
  isSpinning: boolean
}
function SpinResult({ isSpinning }: SpinResultProps) {
  const { userEnergy } = useEnergyContext();
  const { slotMachineSpin } = useAppSelector(
    ({ aviator }) => aviator,
  );

  const renderInfo = () => {
    const effect = slotMachineSpin?.effects[0];
    if (!effect || isSpinning) {
      return {
        icon: null,
        label: null,
      };
    }
    const energy = (userEnergy.maxEnergy / 100) * effect.amount; // calculate % of regenerated energy
    switch (effect.name) {
      case 'bonus':
        return {
          icon: <Coin width="16px" height="16px" />,
          label: effect.amount,
        };
      case 'regenerate_energy':
        return {
          icon: <Energy width="16px" height="16px" />,
          label: energy,
        };
      case 'revive':
        return {
          icon: <Revive width="16px" height="16px" />,
          label: 'revive',
        };
      case 'reward_multiplier':
        return {
          icon: <Booster width="16px" height="16px" />,
          label: 'booster',
        };
      case 'aviator':
        return {
          icon: <Booster width="16px" height="16px" />,
          label: 'aviator',
        };
      default:
        return {
          icon: null,
          label: null,
        };
    }
  };
  if (isSpinning) {
    return null;
  }
  return (
    <div className="flex items-center justify-center text-xs gap-1">
      <span>
        {renderInfo().icon}
      </span>
      <span>
        {renderInfo().label}
      </span>
    </div>
  );
}

export default SpinResult;
