import { useAppSelector } from '../store';

export type UseUserEnergyT = {
  maxEnergy: number
  userEnergy: number
  energyRecoveryPerSecond: number
  energyForSpin: number
}
export const useUserEnergy = (): UseUserEnergyT => {
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const { purchases } = useAppSelector(
    ({ shop }) => shop,
  );
  const activeEnergyCapacityEffect = purchases?.find((purchase) => purchase.is_active
      && purchase.store_item_level.effects.map((effect) => effect.name).includes('energy_capacity'))?.store_item_level?.effects[0];
  const userEnergy = Number((userData?.energy || 0).toFixed(5));
  const maxEnergy = (appSettings?.max_energy || 0) + parseInt(activeEnergyCapacityEffect?.value || '0', 10);
  const energyRecoveryPerSecond = appSettings?.energy_recovery_per_second || 1;
  return {
    maxEnergy, userEnergy, energyRecoveryPerSecond, energyForSpin: appSettings?.energy_for_spin || 1,
  };
};
