import React, { useEffect, useState } from 'react';
import { ReactComponent as AutopilotSvg } from '../../assets/images/autopilot.svg';
import { ReactComponent as Coin } from '../../assets/images/coin-size-26x26.svg';
import Button from '../Button';
import { useAppDispatch } from '../../store';
import { getSecondsBetweenDates } from '../../helpers';
import { useCountDown } from '../../hooks/useCoundown';
import { AutopilotT } from '../../types';
import { finishAutopilot, startAutopilot } from '../../store/reducers/store/asyncActions';
import { finishQuest } from '../../store/reducers/quests/asyncActions';

type AutopilotModalProps = {
    onClose: () => void
    autopilot: AutopilotT | null
}
function AutopilotModal({ onClose, autopilot }: AutopilotModalProps) {
  const dispatch = useAppDispatch();
  const [currentBalance, setCurrentBalance] = useState<number>(autopilot?.balance || 0);
  useEffect(() => {
    if (autopilot) {
      setCurrentBalance(autopilot.balance);
      const interval = setInterval(() => {
        if (currentBalance < autopilot.coins_per_interval) {
          setCurrentBalance((prev) => prev + autopilot.coins_per_second);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    return () => null;
  }, [autopilot]);
  const handleCollectFarm = async () => {
    const resp = await dispatch(finishAutopilot());
    if (!resp?.error) {
      dispatch(startAutopilot());
    }
  };
  const handleClose = () => {
    onClose();
  };
  const isFarmComplete = autopilot && (currentBalance >= autopilot.coins_per_interval);
  return (
    <div className="bg-autopilotStarBg h-full w-full bg-center-center bg-no-repeat p-[16px] stroke-none">
      <div className="mt-[1.025rem] stroke-none">
        <AutopilotSvg height="100px" width="100px" className="m-auto" />
      </div>
      <div className="text-[18px] font-[700] mt-1">
        Auto-pilot
      </div>
      <div className="text-sm text-gray-2 font-[400] line-[17px]">
        The bot is on and will continue to collect coins until you reach the limit. You can still tap and get rewards for this activity while it`s running.
      </div>
      <div className="main-content mt-2">
        <div className="bg-white bg-opacity-[0.15] p-[12px] rounded mt-3 flex flex-col align-center">
          {!autopilot?.end_at
          && <div>Earned</div>}
          <div className="flex mx-auto my-0 items-center">
            <span className="text-[26px] font-[700]">
              {(!autopilot?.end_at ? '' : '+') + Math.floor(currentBalance)}
            </span>
            <span>
              <Coin />
            </span>
          </div>
        </div>
        {(!isFarmComplete && autopilot)
        && <FarmingInfo autopilot={autopilot} />}
      </div>
      <Button
        onClick={isFarmComplete ? handleCollectFarm : handleClose}
        pureClass
        className="w-full mt-6 mb-10 bg-white text-black-text font-bold py-2 px-4 rounded-10 disabled:bg-gray-10 disabled:text-gray-600 focus:ring-4 transform active:scale-75 transition-transform"
      >
        {isFarmComplete ? 'Claim Rewards' : 'Close'}
      </Button>
    </div>
  );
}

export default AutopilotModal;

type FarmingInfoProps = {
  autopilot: AutopilotT
}

function FarmingInfo({ autopilot }: FarmingInfoProps) {
  const secondsFromStart = getSecondsBetweenDates(new Date(autopilot.started_at || ''), new Date());
  const { formatedSecond, formatedMinutes, hours } = useCountDown(secondsFromStart, true);

  return (
    <div className="mt-2 flex gap-2">
      <div className="bg-white bg-opacity-[0.15] p-[10px] rounded w-full flex flex-col">
        <span className="text-[22px] font-[700]">
          {hours}
        </span>
        <span className="text-sm">
          Hours
        </span>
      </div>
      <div className="bg-white bg-opacity-[0.15] p-[10px] rounded w-full flex flex-col">
        <span className="text-[22px] font-[700]">
          {formatedMinutes}
        </span>
        <span className="text-sm">
          Minutes
        </span>
      </div>
      <div className="bg-white bg-opacity-[0.15] p-[10px] rounded w-full flex flex-col">
        <span className="text-[22px] font-[700]">
          {formatedSecond}
        </span>
        <span className="text-sm">
          Seconds
        </span>
      </div>
    </div>
  );
}
