// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { axiosInstance } from '../../index';
import { TopPlayersResponse } from '../../../types';
// Types

export const getTopPlayers = createAsyncThunk<TopPlayersResponse>(
  'topplayers/all',
  async (topPlayers, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('users/top');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
