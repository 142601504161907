// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AviatorHistoryResponse,
  AviatorStartResponse,
  EarnedEffect,
  IAviatorStore,
  SlotMachineEffectT,
  SlotMachineSpinResponse,
} from '../../../types';
import {
  changeGame, getActiveSlotEffects,
  getAviatorHistory,
  getSlotMachineSpinData,
  setAviatorGameStatus,
  setSlotMachineGameStatus,
  startAviator,
  updateReviveAmount,
} from './asyncActions';

// Types

const initialState: IAviatorStore = {
  aviatorData: null,
  slotMachineSpin: null,
  activeGame: 'slotMachine',
  aviatorGameStatus: 'starting',
  slotMachineStatus: 'initial',
  aviatorHistory: [],
  earnedEffects: [],
  reviveAmount: 0,
  error: null,
  loading: false,
};

export const aviatorSlice = createSlice({
  name: 'aviator',
  initialState,
  reducers: {
    clearErrorMessage(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        startAviator.fulfilled,
        (state, action: PayloadAction<AviatorStartResponse>) => {
          state.aviatorData = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        getAviatorHistory.fulfilled,
        (state, action: PayloadAction<AviatorHistoryResponse>) => {
          state.aviatorHistory = action.payload;
          state.loading = false;
          state.error = null;
        },
      )
      .addCase(
        getSlotMachineSpinData.fulfilled,
        (state, action: PayloadAction<SlotMachineSpinResponse>) => {
          const spinEffect: SlotMachineEffectT | undefined = action.payload.effects[0];
          if (spinEffect?.name === 'revive') {
            state.reviveAmount = spinEffect.amount;
          }
          state.slotMachineSpin = action.payload;
        },
      )
      .addCase(
        getActiveSlotEffects.fulfilled,
        (state, action: PayloadAction<EarnedEffect[]>) => {
          state.earnedEffects = action.payload;
        },
      )
      .addCase(
        changeGame.fulfilled,
        (state, action: PayloadAction<IAviatorStore['activeGame']>) => {
          state.activeGame = action.payload;
        },
      )
      .addCase(
        setAviatorGameStatus.fulfilled,
        (state, action: PayloadAction<IAviatorStore['aviatorGameStatus']>) => {
          state.aviatorGameStatus = action.payload;
        },
      )
      .addCase(
        setSlotMachineGameStatus.fulfilled,
        (state, action: PayloadAction<IAviatorStore['slotMachineStatus']>) => {
          state.slotMachineStatus = action.payload;
        },
      )
      .addCase(
        updateReviveAmount.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.reviveAmount = action.payload;
        },
      );
  },
});

export const { clearErrorMessage } = aviatorSlice.actions;
export const AviatorReducer = aviatorSlice.reducer;
