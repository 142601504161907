import { TQuestsResponse } from '../types';
import { getSecondsBetweenDates } from '../helpers';
import { SettingsResponse } from '../store/reducers/settings/types';
import { useAppSelector } from '../store';
import { useCountDown } from './useCoundown';

export const useGetEnergyRefillTimer = () => {
  const { questsList } = useAppSelector(
    ({ quests }) => quests,
  );

  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const dailyEnergyRefillRefreshSeconds = (appSettings as SettingsResponse).daily_energy_refill_refresh_seconds;
  const completedEnergyQuests = questsList?.daily_energy_refill?.filter((el) => el.progress?.completed_at).length;
  const totalEnergyQuests = questsList?.daily_energy_refill?.length;
  const lastCompletedRefill = [...(questsList as TQuestsResponse).daily_energy_refill].find((el) => el.progress
        && el.progress.started_at
        && !el.progress.completed_at);
  const secondsFromLastRefill = getSecondsBetweenDates(new Date(lastCompletedRefill?.progress?.started_at || ''), new Date());
  const countdownTime = dailyEnergyRefillRefreshSeconds - secondsFromLastRefill;
  const countdown = useCountDown(dailyEnergyRefillRefreshSeconds - secondsFromLastRefill);
  return {
    secondsFromLastRefill, countdownTime, completedEnergyQuests, totalEnergyQuests, countdown, dailyEnergyRefillRefreshSeconds,
  };
};
