import React from 'react';
import { useCountDown } from '../../../../hooks/useCoundown';

type EnergyCardCountdownProps = {
    secondsFromLastRefill: number
    dailyEnergyRefillRefreshSeconds: number
}

function EnergyCardCountdown({ secondsFromLastRefill, dailyEnergyRefillRefreshSeconds }: EnergyCardCountdownProps) {
  const { formatedSecond, formatedMinutes } = useCountDown(dailyEnergyRefillRefreshSeconds - secondsFromLastRefill);
  return (
    <span className="ml-2 text-[10px] text-black-text bg-white p-[2px] px-1 rounded">
      {formatedMinutes}
      :
      {formatedSecond}
    </span>
  );
}
export default EnergyCardCountdown;
