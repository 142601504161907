import * as PIXI from 'pixi.js';
import { Spine, TextureAtlas } from 'pixi-spine';
import eventEmitter, { GameEvents } from '../../pages/Home/eventEmiter';
import { getMiddleElementPosition, getSlotCanvasRect } from '../../Components/SlotMachine/SlotMachinePixi';
import { getMachineFrameScale } from '../../Components/SlotMachine/Machine/Machine';

export const overlayPixiApp = new PIXI.Application({
  // width: 430,
  // height: 932,
  width: window.innerWidth,
  height: window.innerHeight,
  resolution: 2,
  autoDensity: true,
  // resizeTo: overlayParent as any,
  // backgroundColor: 'transparent',
  backgroundAlpha: 0,
  hello: true,
});
const folderPath = '../spine/';

const fileList = [
  {
    name: 'coins',
    jsonPath: 'overlay/coins/coins.json',
    atlasPath: 'overlay/coins/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'meteors',
    jsonPath: 'overlay/meteors/meteors.json',
    atlasPath: 'overlay/meteors/meteors.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'multiplier',
    jsonPath: 'slot-machine/multiplier/multiplier.json',
    atlasPath: 'slot-machine/multiplier/multiplier.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'galaxy',
    jsonPath: 'overlay/galaxySmall/galaxy.json',
    atlasPath: 'overlay/galaxySmall/galaxy.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coinsRain',
    jsonPath: 'overlay/coinsRain/coins.json',
    atlasPath: 'overlay/coinsRain/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coinsRainCopy',
    jsonPath: 'overlay/coinsRain/coins.json',
    atlasPath: 'overlay/coinsRain/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'energy',
    jsonPath: 'overlay/energy/energy.json',
    atlasPath: 'overlay/energy/energy.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coinsExplosion',
    jsonPath: 'overlay/coinsExplosion/coins.json',
    atlasPath: 'overlay/coinsExplosion/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
] as const;

type SpineDataListT = {
  [key in typeof fileList[number]['name']]?: any
}
async function loadSpineAtlas(path: string) {
  const pathArray = path.split('/');
  const folder = pathArray.slice(0, pathArray.length - 1).join('/');
  const name = path.split('/').pop()?.replace('.json', '');
  const atlasPath = `${folder}/${name}.atlas`;
  const rawAtlas = await fetch(atlasPath).then((res) => res.text());
  return new TextureAtlas(rawAtlas, ((line, callback) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    callback(PIXI.BaseTexture.from(`${folder}/${line}`));
  }));
}

async function loadAllSpineData() {
  await Promise.all(
    fileList.map(async (animationFile) => {
      const path = folderPath + animationFile.jsonPath;

      try {
        const spineAtlas = await loadSpineAtlas(path);

        PIXI.Assets.add({
          src: path,
          alias: path,
          data: {
            spineAtlas,
          },
        });
        await PIXI.Assets.load(path);
      } catch (error) {
        window.console.error(`Preload error ${path}:`, error);
      }
    }),
  );
}
loadAllSpineData().finally(() => {
  eventEmitter.emit(GameEvents.OVERLAY_LOADED);
});

function getOrCreateSpineAnimation(spineName: keyof SpineDataListT) {
  const animationFile = fileList.find((el) => el.name === spineName);
  if (animationFile) {
    const path = folderPath + animationFile.jsonPath;
    const { spineData } = PIXI.Assets.get(path);
    if (spineData) {
      const spineObject: any = new Spine(spineData);
      overlayPixiApp.stage.addChild(spineObject);
      return spineObject;
    }
    console.error('Cache not found');
  }
  return null;
}

const calculateScale = (animation: any, targetWidth: number, targetHeight: number) => { // TODO: helper?
  const originalWidth = animation.width;
  const originalHeight = animation.height;
  const scaleX = targetWidth / originalWidth;
  const scaleY = targetHeight / originalHeight;
  return Math.min(scaleX, scaleY);
};

const getMiddlePosition = () => {
  const elementPosition = getMiddleElementPosition();
  const canvasPosition = getSlotCanvasRect();
  return {
    x: canvasPosition.left + elementPosition.calculatedPosition.x,
    y: canvasPosition.top + elementPosition.calculatedPosition.y,
  };
};
const startGalaxyTransition = () => {
  const machineContentBounding = getBoundingById('machine-content');
  const elementPosition = getMiddleElementPosition();
  const machineFrameScaleData = getMachineFrameScale();

  if (!elementPosition || !machineContentBounding || !machineFrameScaleData) {
    return;
  }

  const { scaleX, scaleY } = machineFrameScaleData;
  const galaxy: any = getOrCreateSpineAnimation('galaxy');
  const galaxyScale = Math.min(scaleX, scaleY) / 9;
  galaxy.visible = true;
  galaxy.scale.set(galaxyScale);

  const canvasCenterX = machineContentBounding.left + machineContentBounding.width / 2;
  const canvasCenterY = machineContentBounding.top + machineContentBounding.height / 2;
  const yOffset = 50 * scaleY;
  const xOffset = 15 * scaleX;
  const position = {
    x: canvasCenterX - xOffset,
    y: canvasCenterY + galaxy.height / 2 - yOffset,
  };

  galaxy.x = position.x;
  galaxy.y = position.y;
  galaxy.visible = true;
  galaxy.state.setAnimation(0, 'animation', false);
};

const showWinCoins = () => {
  const coins: any = getOrCreateSpineAnimation('coins');
  coins.scale.set(calculateScale(coins, overlayPixiApp.renderer.width, overlayPixiApp.renderer.height) / 2);
  coins.x = (overlayPixiApp.renderer.width / 2) / 2;
  coins.y = (overlayPixiApp.renderer.height / 2);
  coins.visible = true;
  coins.state.setAnimation(0, 'win', false);
  coins.state.addListener({
    complete: () => eventEmitter.emit(GameEvents.OVERLAY_COINS_RAIN_STOP),
  });
};

const startMeteorTransition = () => {
  const meteors: any = getOrCreateSpineAnimation('meteors');
  meteors.scale.set(0.4255044287565546, 0.3255044287565546);
  meteors.x = (overlayPixiApp.renderer.width / 2) / 2 + 40;
  meteors.y = (overlayPixiApp.renderer.height / 2);
  meteors.visible = true;
  meteors.state.setAnimation(0, 'win', false);
};
const runCoinRain = () => {
  const coinsRain: any = getOrCreateSpineAnimation('coinsRain');
  coinsRain.state.setAnimation(0, 'win', true);
  coinsRain.scale.set(0.5);
  coinsRain.x = (overlayPixiApp.renderer.width / 2) / 3;
  coinsRain.y = (overlayPixiApp.renderer.height / 2);
  const coinsRainCopy: any = getOrCreateSpineAnimation('coinsRainCopy');
  coinsRain.visible = true;
  coinsRainCopy.visible = true;
  coinsRainCopy.scale.set(0.5);
  coinsRainCopy.x = (overlayPixiApp.renderer.width / 2) / 3;
  coinsRainCopy.y = (overlayPixiApp.renderer.height / 2);
  const targetFrame = 30;
  let frame = 0;
  let isReached = false;
  function loopRain() {
    if (frame === targetFrame && !isReached) {
      coinsRainCopy.state.setAnimation(0, 'win', true);
      isReached = true;
      frame = 0;
    }
    frame += 1;
  }

  function stopCoinsRain() {
    coinsRain.visible = false;
    coinsRainCopy.visible = false;
    overlayPixiApp.ticker.remove(loopRain);
    eventEmitter.off(GameEvents.OVERLAY_COINS_RAIN_STOP, stopCoinsRain);
  }
  overlayPixiApp.ticker.add(loopRain);
  eventEmitter.on(GameEvents.OVERLAY_COINS_RAIN_STOP, stopCoinsRain);
};
const showMultiplierAnimation = (multiplerValue: number) => {
  const machineContentBounding = getBoundingById('machine-content');
  const elementPosition = getMiddleElementPosition();
  const machineFrameScaleData = getMachineFrameScale();

  if (!elementPosition || !machineContentBounding || !machineFrameScaleData) {
    return;
  }

  const { scaleX, scaleY } = machineFrameScaleData;
  const multiplier: any = getOrCreateSpineAnimation('multiplier');
  multiplier.scale.set(Math.min(scaleX, scaleY) / 4.5);
  multiplier.visible = true;

  const canvasCenterX = machineContentBounding.left + machineContentBounding.width / 2;
  const canvasCenterY = machineContentBounding.top + machineContentBounding.height / 2;
  const yOffset = 70 * scaleY;
  const xOffset = 15 * scaleX;
  const position = {
    x: canvasCenterX - xOffset,
    y: canvasCenterY + multiplier.height / 2 + yOffset,
  };

  multiplier.x = position.x;
  multiplier.y = position.y;
  multiplier.state.setAnimation(0, `multiplier_x${multiplerValue}`, false);
  const onAnimationComplete = () => {
    multiplier.visible = false;
    eventEmitter.emit(GameEvents.SLOT_SHOW_MIDDLE);
  };
  multiplier.state.addListener({
    complete: onAnimationComplete,
  });
};

const getBoundingById = (itemId:string) => {
  if (!itemId) {
    return null;
  }
  const el = document.getElementById(itemId);
  if (el) {
    return el.getBoundingClientRect() as DOMRect;
  }
  return null;
};

const showEnergyAnimation = () => {
  const machineContentBounding = getBoundingById('machine-content');
  const elementPosition = getMiddleElementPosition();
  const machineFrameScaleData = getMachineFrameScale();

  if (!elementPosition || !machineContentBounding || !machineFrameScaleData) {
    return;
  }

  const { scaleX, scaleY } = machineFrameScaleData;
  const energy: any = getOrCreateSpineAnimation('energy');
  const energyScale = Math.min(scaleX, scaleY) / 4.5;
  energy.visible = true;
  energy.scale.set(energyScale);
  const canvasCenterX = machineContentBounding.left + machineContentBounding.width / 2;
  const canvasCenterY = machineContentBounding.top + machineContentBounding.height / 2;
  const yOffset = 37.5 * scaleY;
  const xOffset = 15 * scaleX;
  const position = {
    x: canvasCenterX - xOffset,
    y: canvasCenterY + energy.height / 2 + yOffset,
  };
  energy.x = position.x;
  energy.y = position.y;
  energy.state.setAnimation(0, 'animation', false);

  const targetFrame = 24;
  let frame = 0;
  let isReached = false;
  function CalculateEnergyFrame() {
    if (frame === targetFrame && !isReached) {
      eventEmitter.emit(GameEvents.ENERGY_HIT_SCREEN);
      isReached = true;
      frame = 0;
    }
    frame += 1;
  }
  const onAnimationComplete = () => {
    eventEmitter.emit(GameEvents.SLOT_SHOW_MIDDLE);
    energy.visible = false;
    overlayPixiApp.ticker.remove(CalculateEnergyFrame);
  };
  energy.state.addListener({
    complete: onAnimationComplete,
  });
  overlayPixiApp.ticker.add(CalculateEnergyFrame);
};
const showCoinsExplosion = (animation: 'coins_small'| 'coins_mid' | 'coins_big') => {
  const coinsExplosion: any = getOrCreateSpineAnimation('coinsExplosion');
  coinsExplosion.visible = true;
  const elementPosition = getMiddleElementPosition();
  coinsExplosion.scale.set(0.2);
  const canvasPosition = getSlotCanvasRect();
  const position = {
    x: canvasPosition.x + elementPosition.calculatedPosition.x + (elementPosition.elementWidth / 2),
    y: canvasPosition.bottom + (elementPosition.calculatedPosition.y) + elementPosition.baseYOffset,
  };
  coinsExplosion.x = (window.innerWidth / 2) + 20;
  coinsExplosion.y = position.y;
  coinsExplosion.state.setAnimation(0, animation, false);
  const onAnimationComplete = () => {
    coinsExplosion.visible = false;
  };
  coinsExplosion.state.addListener({
    complete: onAnimationComplete,
  });
};
eventEmitter.on(GameEvents.OVERLAY_GALAXY_TRANSITION, () => {
  startGalaxyTransition();
});
eventEmitter.on(GameEvents.SHOW_WIN_COINS, () => {
  showWinCoins();
});

eventEmitter.on(GameEvents.SHOW_METEORS, () => {
  startMeteorTransition();
});
eventEmitter.on(GameEvents.OVERLAY_COINS_RAIN_START, () => {
  runCoinRain();
});
eventEmitter.on(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, (val: number) => {
  showMultiplierAnimation(val);
});
eventEmitter.on(GameEvents.OVERLAY_SHOW_ENERGY_ANIMATION, () => {
  showEnergyAnimation();
});
eventEmitter.on(GameEvents.SLOT_COINS_EXPLOSION, ({ animation }: { animation: 'coins_small'| 'coins_mid' | 'coins_big' }) => {
  showCoinsExplosion(animation);
});
// const debugSquare = () => {
//   const redSquare: any = new PIXI.Graphics();
//
//   redSquare.beginFill(0xff0000);
//   redSquare.drawRect(0, 0, 100, 100);
//   redSquare.endFill();
//
//   redSquare.x = position.x;
//   redSquare.y = position.y;
//   overlayPixiApp.stage.addChild(redSquare);
// }
