import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import { useAppDispatch, useAppSelector } from '../../store';
import { useWebSocketContext } from '../../providers/WebSocketProvider';
import { AviatorGameStartedData, AviatorGameWSMessages } from '../../providers/WebSocketProvider/types';
import { updateProfileCoins, updateUserData } from '../../store/reducers/profile/asyncActions';
import { ReactComponent as Coin } from '../../assets/images/coin-size-26x26.svg';
import { ReactComponent as CoinBooster } from '../../assets/svg/coinBooster.svg';
import { ReactComponent as Recovery } from '../../assets/svg/recovery.svg';
import { ReactComponent as ReduceCrash } from '../../assets/svg/reduceCrash.svg';
import { useCountDown } from '../../hooks/useCoundown';
import { getSecondsBetweenDates, numberWithCommas } from '../../helpers';
import BetsHistoryModal from '../../pages/Home/BetsHistoryModal';
import {
  changeGame, getActiveSlotEffects,
  getAviatorHistory,
  setAviatorGameStatus,
} from '../../store/reducers/aviator/asyncActions';
import { getPurchases } from '../../store/reducers/store/asyncActions';
import { IAviatorStore, Purchase } from '../../types';
import AviatorGameContainer from '../../pages/Home/AviatorGameContainer';
import eventEmitter, { emitGameEvent, GameEvents } from '../../pages/Home/eventEmiter';
import AviatorWinModal from './AviatorWinModal';
import AviatorLoseModal from './AviatorLoseModal';
import { MultiplierScale } from '../../pages/Home/MultiplierScale';

function AviatorRocket() {
  const {
    startGame, webSocket, stopGame,
  } = useWebSocketContext();
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const { aviatorHistory, earnedEffects } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const { purchases } = useAppSelector(
    ({ shop }) => shop,
  );
  const {
    aviatorGameStatus,
  } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const activeStartSumItems = purchases?.filter((purchasedItem) => purchasedItem.store_item_level.store_item.type === 'permanent' && purchasedItem.store_item_level.effects[0].name === 'bonus' && purchasedItem.is_active);
  const calculatedStartSumEffect = activeStartSumItems?.reduce(
    (acc, obj) => acc + obj.store_item_level.effects.reduce((effectAccumulator, effectObj) => effectAccumulator + Number(effectObj.value), 0),
    userSettings?.aviator_base_coins || 0,
  );
  const startSumBonusGoldEffect = calculatedStartSumEffect || (userSettings?.aviator_base_coins || 1000);
  const initialTimerSeconds = 5;
  const dispatch = useAppDispatch();
  const bet = startSumBonusGoldEffect;
  const [gameData, setGameData] = useState<AviatorGameStartedData['data'] | null>(null);
  const [multiplier, setMultiplier] = useState<number>(0);
  const [rocketValue, setRocketValue] = useState<number | null>(null);
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
  const [winModalOpen, setWinModalOpen] = useState<boolean>(false);
  const [loseModalOpen, setLoseModalOpen] = useState<boolean>(false);
  const { formatedSecond, restart: restartCounter } = useCountDown(0);
  if (webSocket) {
    webSocket.onmessage = (event: MessageEvent<string>) => {
      const message: AviatorGameWSMessages = JSON.parse(event.data);
      if (message.type === 'start') {
        dispatch(updateUserData(message.data.user));
        setGameData(message.data);
      }
      if (message.type === 'progress') {
        handleAnimationsStart();
        setRocketValue(message.data.currentValue);
        emitGameEvent({ event: GameEvents.SET_AVIATOR_MULTIPLIER, value: message.data.currentValue });
      } else if (message.type === 'result') {
        dispatch(updateUserData(message.data.user));
        handleStop({ isWin: message.data.isWinner });
        if (message.data.isWinner) {
          dispatch(setAviatorGameStatus('won'));
          dispatch(updateProfileCoins({ coins: message.data.user.coins, replace: true }));
        } else {
          dispatch(setAviatorGameStatus('lose'));
        }
      } else if (message.type === 'error') {
        window.console.log('error', message);
      }
    };
  }

  const handleAnimationsStart = () => {
    if (aviatorGameStatus !== 'running' && aviatorGameStatus !== 'stoppedByUser') {
      dispatch(setAviatorGameStatus('running'));
      emitGameEvent({ event: GameEvents.START_GAME, animations: 'all', loop: false });
    }
  };
  const handleAnimationsStop = (pause: boolean) => {
    if (pause) {
      emitGameEvent({ event: GameEvents.WIN_GAME });
    } else {
      emitGameEvent({ event: GameEvents.STOP_GAME });
    }
  };
  const handleStart = (revive?: boolean) => {
    dispatch(setAviatorGameStatus('starting'));
    setMultiplier((prev) => { // hack because start called from useEffect[]
      startGame(prev || undefined, revive);
      return prev;
    });
  };
  const handleStop = ({ byUser, isWin }: {byUser?: boolean, isWin?: boolean}) => {
    handleAnimationsStop(!!(byUser || isWin));
    if (isWin) {
      setTimeout(() => {
        setWinModalOpen(true);
      }, 1500);
    }
    dispatch(getAviatorHistory());
    dispatch(getPurchases());
    if (gameData && byUser) {
      dispatch(setAviatorGameStatus('stoppedByUser'));
      stopGame({ aviatorId: gameData.aviatorId, hash: gameData.hash });
    }
  };

  const handleAddMultiplier = () => {
    setMultiplier(Number((multiplier + 0.1).toFixed(1)));
  };
  const handleSubtractMultiplier = () => {
    const calculatedMultiplier = Number((multiplier - 0.1).toFixed(1));
    if (calculatedMultiplier >= 0) {
      setMultiplier(calculatedMultiplier);
    }
  };
  const handleCloseGame = () => {
    if (aviatorGameStatus === 'won') {
      emitGameEvent({ event: GameEvents.SHOW_WIN_COINS });
    }
    setTimeout(() => {
      setWinModalOpen(false);
      dispatch(getActiveSlotEffects());
      dispatch(changeGame('slotMachine'));
      emitGameEvent({ event: GameEvents.TAKEOFF });
    }, 2000);
    dispatch(setAviatorGameStatus('bet'));
  };

  const activePurchases = [...purchases || []]?.filter((purchase) => purchase.is_active);
  const boosters = purchases?.filter((purchasedItem) => purchasedItem.store_item_level.store_item.type === 'temporary' && purchasedItem.is_active) || [];
  // const multiplyReward = () => {
  //   const rewardMultiplierEffects = activePurchases.map((purchase) => purchase.store_item_level.effects.filter((effect) => effect.name === 'reward_multiplier')).flat();
  //   const rewardMultipler = rewardMultiplierEffects.reduce((effectAccumulator, effectObj) => effectAccumulator + Number(effectObj.value), 0);
  //   return rewardMultipler ? rewardMultipler * bet * (rocketValue || 1) : bet * (rocketValue || 1);
  // };

  useEffect(() => {
    setTimeout(() => {
      emitGameEvent({ event: GameEvents.TAKEOFF });
    }, 1000);
    (new MultiplierScale()).draw();
    const endGameStatuses: IAviatorStore['aviatorGameStatus'][] = ['won', 'lose'];
    if (!endGameStatuses.includes(aviatorGameStatus)) {
      dispatch(setAviatorGameStatus('starting'));
      restartCounter(initialTimerSeconds);
      const autostartTimeout = setTimeout(() => {
        handleStart();
      }, initialTimerSeconds * 1000);
      return () => {
        emitGameEvent({ event: GameEvents.RESTART });
        clearTimeout(autostartTimeout);
      };
    }
    return () => null;
  }, []);
  useEffect(() => {
    const handleExplosionEnd = () => {
      setLoseModalOpen(() => true);
      setTimeout(() => {
        emitGameEvent({ event: GameEvents.SHOW_METEORS });
      }, 1000);
      setTimeout(() => {
        handleCloseGame();
      }, 500);
    };
    eventEmitter.on(GameEvents.EXPLOSION_END, handleExplosionEnd);
    return () => {
      eventEmitter.off(GameEvents.EXPLOSION_END, handleExplosionEnd);
    };
  }, []);
  const handleBetsClick = () => {
    setOpenHistoryModal(true);
  };
  // const cashout = multiplyReward();
  const cashout = bet * (rocketValue || 1);
  const displayBetsUi = ['bet', 'starting'].includes(aviatorGameStatus);

  const rewardMultiplerEffect = earnedEffects?.find((el) => el.effect.name === 'reward_multiplier');
  const rocketValueRef = useRef<HTMLDivElement>(null);
  const cahsoutValue = Math.round(bet * (rocketValue || 1) * (rewardMultiplerEffect?.effect?.value ? rewardMultiplerEffect.effect.value : 1));
  return (
    <div className="absolute h-screen w-screen top-0 overflow-hidden">
      <BetsHistoryModal
        open={openHistoryModal}
        onClose={() => setOpenHistoryModal(false)}
        aviatorHistory={aviatorHistory}
      />
      <div className="absolute h-screen w-screen">
        <AviatorGameContainer />
      </div>
      <div className="z-[3] relative h-full w-full flex items-end justify-center">
        <div className="z-[3] absolute top-[60px] w-fit flex gap-2 flex flex-col w-full">
          <div className="buttons flex gap-2  w-full justify-between">
            <Button
              className="z-[3]  bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px] w-fit px-3 py-1 rounded-10 text-xs ml-3"
              pureClass
              onClick={handleBetsClick}

            >
              My bets
            </Button>
            {boosters[0]
            && (
            <Button
              className="z-[3] bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px] w-fit rounded-10 text-xs mr-3 p-1 px-3"
              pureClass
            >
              <EffectsButtonContent boosters={boosters} />
            </Button>
            )}
          </div>
          <div className="z-[3] w-full flex justify-center">
            <div className="flex flex-col mt-1">
              {aviatorGameStatus === 'starting'
                  && <div className="text-xs">Start in</div>}
              {['won', 'lose', 'running', 'stoppedByUser'].includes(aviatorGameStatus)
                  && (
                  <>
                    {rewardMultiplerEffect
                    && (
                    <div className="text-xl">
                      Earned multiplier
                      {' '}
                      {rewardMultiplerEffect?.effect.value}
                      x
                    </div>
                    )}
                    <div className={`text-5xl font-bold w-fit mx-auto ${aviatorGameStatus === 'lose' ? 'text-[#FF5876]' : ''}`} ref={rocketValueRef} id="rocket-value-text">
                      {rocketValue}
                      x
                    </div>
                  </>
                  )}
              {aviatorGameStatus === 'starting'
                  && <div className="text-5xl">{formatedSecond}</div>}
              <div className="text-sm flex justify-center gap-1 mt-1 z-[4] text-white">
                <span>Current cashout</span>
                <span>{numberWithCommas(Math.round(cashout))}</span>
                <span>
                  <Coin width="18px" height="18px" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="h-fit mb-[100px] w-full flex justify-center flex-col items-center max-w-[90vw] gap-2">
          {(displayBetsUi || (['running'].includes(aviatorGameStatus) && multiplier))
            ? (
              <div className="bg-white bg-opacity-[0.08] rounded-10 p-1 px-2 flex gap-2 w-full justify-between border-[#FFFFFF14] border-[1px]">
                {displayBetsUi
            && (
            <Button
              onClick={handleSubtractMultiplier}
              className="bg-white bg-opacity-[0.08] text-gray-2 border-[#FFFFFF14] border-[1px]"
            >
              -
            </Button>
            )}
                <div className="p-0 w-full">
                  <div className="text-gray-2 text-[10px] p-0">Auto withdrawal</div>
                  <div className="text-xs p-0">
                    {multiplier ? `${multiplier} x` : <span className="text-gray-2">None</span>}
                  </div>
                </div>
                {displayBetsUi
            && (
            <Button
              onClick={handleAddMultiplier}
              className="bg-white bg-opacity-[0.08] text-gray-2 border-[#FFFFFF14] border-[1px]"
            >
              +
            </Button>
            )}
              </div>
            ) : null}
          {aviatorGameStatus === 'running'
             && (
             <Button
               onClick={() => handleStop({ byUser: true })}
               className="w-full bg-gradient-to-r from-[#EFAF00] to-[#E47513] text-[#701E00] font-[600] rounded-10 py-2 flex justify-center items-center gap-2 py-3"
               pureClass
               style={{
                 textShadow: '1px -1px #EFAB00, 1px -1px #EFAB00, -1px 1px #EFAB00, 1px 1px #EFAB00',
               }}
             >
               <span>Cash out</span>
               <span>{numberWithCommas(cahsoutValue)}</span>
               <span>
                 <Coin width="18px" height="18px" />
               </span>
             </Button>
             )}
          {displayBetsUi
          && (
          <div className="flex gap-2 w-full justify-between">
            <Button
              className="bg-white bg-opacity-[0.08] text-gray-2 border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(1.1)}
            >
              1.1x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-gray-2 border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(1.5)}
            >
              1.5x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-gray-2 border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(2)}
            >
              2x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-gray-2 border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(2.5)}
            >
              2.5x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-gray-2 border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(5)}
            >
              5x
            </Button>
          </div>
          )}
        </div>
      </div>
      <AviatorWinModal
        open={winModalOpen}
        onOkay={handleCloseGame}
        multiplier={rocketValue}
        coinsWon={cahsoutValue}
        rocketValueRef={rocketValueRef}
        bet={bet}
      />
      <AviatorLoseModal
        open={loseModalOpen}
        onClose={handleCloseGame}
      />
    </div>
  );
}

export default AviatorRocket;

type EffectsButtonContentProps = {
  boosters: Purchase[]
}
function EffectsButtonContent({ boosters }: EffectsButtonContentProps) {
  const bosterExpiration = boosters[0]?.expires_at;
  const secondsToExpire = getSecondsBetweenDates(new Date(), new Date(bosterExpiration || ''));
  const countdown = useCountDown(secondsToExpire ? Math.round(secondsToExpire) : undefined);
  const renderIco = () => {
    const effectName = boosters?.[0]?.store_item_level?.effects?.[0]?.name;
    switch (effectName) {
      case 'crash_reduction':
        return <ReduceCrash width="15px" height="15px" />;
      case 'revive':
        return <Recovery width="15px" height="15px" />;
      case 'bonus':
      case 'reward_multiplier':
      case 'speed_multiplier':
      case 'coins_multiplier':
      case 'reward_bonus':
        return <CoinBooster width="15px" height="15px" />;
      default:
        return null;
    }
  };
  if (secondsToExpire < 0) {
    return null;
  }
  return (
    <div className="flex gap-2 justify-center align-center">
      <span>{renderIco()}</span>
      {countdown.formatedMinutes}
      :
      {countdown.formatedSecond}
    </div>
  );
}
