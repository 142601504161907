import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import RoutesComponent from './Router';
import { store } from './store';
import Loader from './Components/Loader';
import AppWrapper from './Layouts/AppWrapper';
import WebSocketProvider from './providers/WebSocketProvider';
import EnergyProvider from './providers/EnergyProvider';

const tg = window?.Telegram.WebApp;
function App() {
  useEffect(() => {
    tg.ready();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tg.lockOrientation();
  }, []);
  const initTgData = process.env.REACT_APP_ENV === 'production'
    ? tg.initData
    : tg.initData || process.env.REACT_APP_DEV_TG_INIT_DATA;
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          {initTgData // wait for telegram initialization
            ? (
              <AppWrapper>
                <WebSocketProvider>
                  <EnergyProvider>
                    <RoutesComponent />
                  </EnergyProvider>
                </WebSocketProvider>
              </AppWrapper>
            )
            : <Loader />}
        </Router>
      </Provider>
    </div>
  );
}

export default App;
