import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authenticateUser, refreshAuthToken } from './asyncActions';
import { AuthResponseT, CustomErrorPayload } from '../../../types';

const initialState: AuthResponseT = {
  error: null,
  loading: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearErrorMessage(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        const error = action.payload as CustomErrorPayload;
        state.loading = false;
        state.error = error?.response?.data?.message || 'Authentication failed';
      });

    builder
      .addCase(refreshAuthToken.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(refreshAuthToken.rejected, (state, action) => {
        const error = action.payload as CustomErrorPayload;
        state.loading = false;
        state.error = error?.response?.data?.message || 'Token refresh failed';
      });
  },
});

export const { clearErrorMessage } = authSlice.actions;
export const AuthReducer = authSlice.reducer;
