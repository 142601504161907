// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export class MultiplierScale {
  private config;

  constructor() {
    const canvas = document.getElementById('multiplier_scale');
    const ctx = canvas?.getContext('2d');

    this.config = {
      ctx,
      width: canvas.width,
      height: canvas.height,
      tickSpacing: 86, // Distance between major divisions (in pixels)
      subTicks: 4, // Number of additional elements between major divisions
      visibleNumbers: 4, // Number of numbers in the visible area
      maxValue: 1000, // Maximum scale value
      currentValue: 0, // Initial value
      isScrolling: false, // Flag for scroll control
      scrollInterval: 100, // Variable for controlling animation interval
    };
  }

  drawMultiplierScale(value) {
    const {
      ctx, width, height, visibleNumbers, tickSpacing, subTicks, maxValue,
    } = this.config;

    ctx.clearRect(0, 0, width, height);

    ctx.fillStyle = 'white';
    ctx.font = '11px Inria Sans';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'middle';

    // Calculating the first value in the current area
    const startValue = Math.floor(value);

    // Drawing numbers, main lines and additional elements
    for (let i = -1; i <= visibleNumbers + 1; i++) {
      const y = (height / 2) + 20 + (i * tickSpacing) + (value % 1) * tickSpacing; // Centering the scale
      const displayValue = startValue - i; // Values from top to bottom

      if (displayValue >= 0 && displayValue <= maxValue) {
        // Text
        ctx.fillText(`${displayValue}x`, width - 24, y); // Shifting text to the left

        // Main line
        ctx.fillRect(width - 20, y - 1, 8, 1);

        // Additional elements between main lines
        for (let j = 1; j <= subTicks; j++) {
          const subTickY = y - (j * tickSpacing) / (subTicks + 1); // Equal distance
          ctx.fillRect(width - 17, subTickY - 1, 3, 1); // Length and position of additional lines
        }
      }
    }
  }

  drawMultiplierScaleArrow() {
    const { ctx, width, height } = this.config;
    const centerY = (height / 2) + 20; // Center of canvas
    ctx.fillStyle = 'white';
    ctx.beginPath();
    ctx.moveTo(width - 3, centerY - 5); // Top left corner
    ctx.lineTo(width - 9, centerY); // Center sharp end
    ctx.lineTo(width - 3, centerY + 5); // Bottom left corner
    ctx.lineTo(width, centerY + 5); // Right arrow line
    ctx.lineTo(width, centerY - 5); // Top right arrow line
    ctx.closePath();
    ctx.fill();
  }

  draw(value = 0) {
    this.drawMultiplierScale(value);
    this.drawMultiplierScaleArrow();
  }
}
