import React from 'react';
import Energy from '../../../assets/images/energy-40x40.svg';
import { useAppSelector } from '../../../store';
import { SettingsResponse } from '../../../store/reducers/settings/types';
import EnergyCardCountdown from './EnergyCardCountdown';
import { useGetEnergyRefillTimer } from '../../../hooks/useGetEnergyRefillTimer';

type RefillCardProps = {
    handleEnergyClick: () => void
}
function RefillCard({ handleEnergyClick }: RefillCardProps) {
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const {
    secondsFromLastRefill, countdownTime, completedEnergyQuests, totalEnergyQuests,
  } = useGetEnergyRefillTimer();
  return (
    <div className="bg-gradient-to-r from-blue-gradient-1 to-blue-gradient-2 rounded-10 w-full" onClick={handleEnergyClick}>
      <div className="bg-blueStar bg-yellowStarSize bg-no-repeat bg-yellowStarPosition flex justify-between  border-none flex gap-2 text-left px-[.64rem] py-[.44rem]">
        <div>
          <div className="text-base font-bold tracking-normal">
            Energy Refill
          </div>
          <div className="text-xs pt-[.3rem]">
            {(totalEnergyQuests || 0) - (completedEnergyQuests || 0)}
            <span className="pl-[.1rem]">{' '}</span>
            /
            <span className="pl-[.1rem]">{' '}</span>
            {totalEnergyQuests}
            {(countdownTime > 0)
              ? (
                <EnergyCardCountdown secondsFromLastRefill={secondsFromLastRefill} dailyEnergyRefillRefreshSeconds={(appSettings as SettingsResponse).daily_energy_refill_refresh_seconds} />
              ) : null}
          </div>
        </div>
        <div>
          <img
            className="m-auto max-h-[40px] drop-shadow-[0_0_12px_rgba(255,233,53,0.6)]"
            src={Energy}
            alt="energy"
          />
        </div>
      </div>
    </div>
  );
}

export default RefillCard;
