// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { axiosInstance } from '../../index';
import {
  AviatorHistoryResponse, AviatorStartResponse, EarnedEffect, IAviatorStore, SlotMachineSpinResponse,
} from '../../../types';
// Types

export const startAviator = createAsyncThunk<AviatorStartResponse, {multipler: number}>(
  'aviator/start',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('/aviator/start');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const getAviatorHistory = createAsyncThunk<AviatorHistoryResponse>(
  'aviator/history',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('/aviator/history');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const getSlotMachineSpinData = createAsyncThunk<SlotMachineSpinResponse>(
  'aviator/slot-machine-spin-data',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('/slot-machine/spin');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const getActiveSlotEffects = createAsyncThunk<EarnedEffect[]>( // TODO: move to users reducer??
  'aviator/active-slot-effects',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get('/users/earned-effects');
      (data as EarnedEffect[]).forEach((effect) => {
        if (effect.effect.name === 'revive') {
          dispatch(updateReviveAmount(effect.effect.value));
        }
      });
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const changeGame = createAsyncThunk<
    IAviatorStore['activeGame'],
    IAviatorStore['activeGame']
>(
  'aviator/active-game',
  async (game, { rejectWithValue }) => {
    try {
      return game;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const setAviatorGameStatus = createAsyncThunk<
    IAviatorStore['aviatorGameStatus'],
    IAviatorStore['aviatorGameStatus']
>(
  'aviator/aviator-game-status',
  async (status, { rejectWithValue }) => {
    try {
      return status;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const setSlotMachineGameStatus = createAsyncThunk<
    IAviatorStore['slotMachineStatus'],
    IAviatorStore['slotMachineStatus']
>(
  'aviator/slot-machine-game-status',
  async (status, { rejectWithValue }) => {
    try {
      return status;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const updateReviveAmount = createAsyncThunk<
    number,
    number
>(
  'aviator/update-revive',
  async (amount, { rejectWithValue }) => {
    try {
      return amount;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
