import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { EarnedEffect } from '../../../types';
import eventEmitter, { GameEvents } from '../../../pages/Home/eventEmiter';

type MultiplierTextProps = {
    rewardMultipler: EarnedEffect | undefined
}

const textStyles = {
  2: {
    background: 'linear-gradient(rgb(147, 255, 0) 0%, rgb(65, 144, 18) 100%) clip(text)',
    color: 'transparent',
    textShadow: '1px 0 #63E8FF, 0 1px #63E8FF, 1px 0 #63E8FF, 0 1px #63E8FF',
  },
  3: {
    background: 'linear-gradient(180deg, #93FF00 0%, #419012 100%) clip(text)',
    color: 'transparent',
    textShadow: '1px 0 #93FF00, 0 1px #93FF00, 1px 0 #93FF00, 0 1px #93FF00',
  },
  5: {
    background: 'linear-gradient(180deg, #F7D759 0%, #F2973E 100%) clip(text)',
    color: 'transparent',
    textShadow: '1px 0 #FFD156, 0 1px #FFD156, 1px 0 #FFD156, 0 1px #FFD156',
  },
} as const;
function MultiplierText({ rewardMultipler }: MultiplierTextProps) {
  const [multiplierValue, setMultiplierValue] = useState<number>(rewardMultipler?.effect.value || 0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [targetBoundings, setTargetBoundings] = useState<{x: number, y: number}>({ x: 0, y: 0 });
  const [isPulsing, setIsPulsing] = useState(false);
  const [flyingTextValue, setFlyingTextValue] = useState<number>(2);
  const targetText = useRef<HTMLDivElement>(null);
  const handleAnimation = (value: number) => {
    setIsAnimating(true);
    setTimeout(() => {
      setMultiplierValue((prevState) => prevState + value);
      setIsAnimating(false);
    }, 1000);
  };
  useEffect(() => {
    if (targetText.current) {
      const targetRect = targetText.current.getBoundingClientRect();
      const centerY = targetRect.top - targetRect.height + (targetRect.height / 2) + 20;
      setTargetBoundings({
        x: targetRect.left,
        y: centerY,
      });
    }
  }, [targetText.current]);
  const flyOver = () => {
    setIsPulsing(true);
  };
  useEffect(() => {
    if (!rewardMultipler?.effect.value) {
      setMultiplierValue(0);
    }
  }, [rewardMultipler?.effect.value]);

  useEffect(() => {
    const startAnimation = (value: number) => {
      setFlyingTextValue(value);
      handleAnimation(value);
    };
    eventEmitter.on(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, startAnimation);
    return () => {
      eventEmitter.off(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, startAnimation);
    };
  }, []);
  const calculateCurrentStyle = () => {
    if (multiplierValue <= 2) {
      return 2;
    }
    if (multiplierValue <= 3) {
      return 2;
    }
    return 5;
  };
  return (
    <div className="m-auto justify-center reward-multiplier flex flex-col mb-1 items-center">
      <motion.div
        animate={isPulsing ? { scale: [1, 1.2, 1] } : {}}
        transition={{
          duration: 0.6,
          ease: 'easeInOut',
        }}
        onAnimationComplete={() => setIsPulsing(false)}
        style={{
          display: 'inline-block',
        }}
      >
        <motion.div
          ref={targetText}
          className={`text-4xl font-400 font-TeaForGod w-fit ${multiplierValue ? 'visible' : 'invisible'}`}
          style={{
            display: 'inline-block',
            ...textStyles[calculateCurrentStyle()],
          }}
          key="target-text"
        >
          {`X${multiplierValue}`}
        </motion.div>
        <div className={`text-xs mb-2 ${multiplierValue ? 'visible' : 'invisible'}`}>
          X
          {multiplierValue}
          {' '}
          Multiplyer is active
        </div>
      </motion.div>

      <div
        className="fixed w-screen h-screen z-10 bottom-0 animation-container"
        style={{
          pointerEvents: 'none',
        }}
      >
        <AnimatePresence>
          {isAnimating && (
          <motion.div
            key="flying-text"
            initial={{ x: targetBoundings.x, y: window.innerHeight / 2 }}
            animate={{
              x: targetBoundings.x,
              y: targetBoundings.y,
              scale: 1,
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}
            onAnimationComplete={flyOver}
            className="text-4xl font-400 font-TeaForGod w-fit h-fit border-red"
            style={{
              position: 'fixed',
              ...textStyles[flyingTextValue as (2 | 3 |5)],
            }}
          >
              {`X${flyingTextValue}`}
          </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default MultiplierText;
