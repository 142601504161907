import {
  TypedUseSelectorHook, useDispatch, useSelector,
} from 'react-redux';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  configureStore, Store,
} from '@reduxjs/toolkit';
import { rootReducer } from './root-reducer';
import { authSlice } from './reducers/auth';
import { CustomErrorPayload } from '../types';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    retry?: boolean;
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    'ngrok-skip-browser-warning': '69420', // debugging mode
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;

    if (error.response?.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;

      try {
        const refreshToken = localStorage.getItem('jwtTokenRefresh');
        if (!refreshToken) return Promise.reject(error);

        const { data } = await axiosInstance.post('/auth/refresh', {
          refreshToken,
        }).catch((err) => {
          const customError = err as CustomErrorPayload;
          store.dispatch(authSlice.actions.setError(customError?.response?.data?.message || 'Token refresh failed'));
          return Promise.reject(error);
        });

        localStorage.setItem('jwtToken', data.token);
        localStorage.setItem('jwtTokenRefresh', data.refreshToken);

        axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`;
        if (originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${data.token}`;
        }

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('jwtTokenRefresh');

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

export type RootState = ReturnType<typeof rootReducer>
export const store: Store<RootState> = configureStore({
  reducer: rootReducer,
});

export const useAppDispatch = useDispatch.withTypes<any>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export const persistor = persistStore(store);
