import React from 'react';
import BronzeLeague from '../../assets/leaderboard/BronzeLeague.png';
import SilverLeague from '../../assets/leaderboard/SilverLeague.png';
import GoldLeague from '../../assets/leaderboard/GoldLeague.png';
import PlatinumLeague from '../../assets/leaderboard/PlatinumLeague.png';
import DiamondLeague from '../../assets/leaderboard/DiamondLeague.png';
import ChallengerLeague from '../../assets/leaderboard/ChallengerLeague.png';
import LegendaryLeague from '../../assets/leaderboard/LegendaryLeague.png';
import MasterLeague from '../../assets/leaderboard/MasterLeague.png';
import { LeaguesUnion } from '../../types';

function LeagueIcon({ league, className }: { league: LeaguesUnion, className?: string }) {
  const getLeagueIcon = () => {
    switch (league) {
      case 'Bronze':
        return <img alt="" src={BronzeLeague} className={className} />;
      case 'Silver':
        return <img alt="" src={SilverLeague} className={className} />;
      case 'Gold':
        return <img alt="" src={GoldLeague} className={className} />;
      case 'Platinum':
        return <img alt="" src={PlatinumLeague} className={className} />;
      case 'Diamond':
        return <img alt="" src={DiamondLeague} className={className} />;
      case 'Challenger':
        return <img alt="" src={ChallengerLeague} className={className} />;
      case 'Legendary':
        return <img alt="" src={LegendaryLeague} className={className} />;
      case 'Master':
        return <img alt="" src={MasterLeague} className={className} />;
      default:
        return <img alt="" src={BronzeLeague} className={className} />;
    }
  };
  return (
    <div className="h-[inherit] w-[inherit]">
      {getLeagueIcon()}
    </div>
  );
}

export default LeagueIcon;
