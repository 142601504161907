// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQuestsStore, TQuestsResponse } from '../../../types';
import { finishQuest, getQuests, startQuest } from './asyncActions';

// Types

const initialState: IQuestsStore = {
  questsList: {
    social: [],
    daily: [],
    daily_energy_refill: [],
    referral: [],
  },
  error: null,
  loading: true,
};

export const questsSlice = createSlice({
  name: 'quests',
  initialState,
  reducers: {
    clearErrorMessage(state) {
      state.error = null;
    },
    resetQuests(state) {
      const source: IQuestsStore = {
        loading: true,
        questsList: null,
        error: null,
      };
      Object.assign(state, source);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getQuests.fulfilled,
        (state, action: PayloadAction<TQuestsResponse>) => {
          const source: IQuestsStore = {
            loading: false,
            questsList: action.payload,
            error: null,
          };
          Object.assign(state, source);
        },
      )
      .addCase(startQuest.fulfilled, (state: IQuestsStore, action) => {
        const questType = action.payload.quest.type;
        const indexOf = state.questsList?.[questType]?.findIndex((el) => el.id === action.payload.quest.id);
        if (typeof indexOf === 'number' && indexOf >= 0 && state?.questsList?.[questType]) {
          const tempArr = [...state.questsList[questType]];
          tempArr[indexOf] = action.payload.quest;
          state.questsList[questType] = tempArr;
        }
      })
      .addCase(finishQuest.fulfilled, (state: IQuestsStore, action) => {
        const questType = action.payload.quest.type;
        const indexOf = state.questsList?.[questType]?.findIndex((el) => el.id === action.payload.quest.id);
        if (typeof indexOf === 'number' && indexOf >= 0 && state?.questsList?.[questType]) {
          const tempArr = [...state.questsList[questType]];
          tempArr[indexOf] = action.payload.quest;
          state.questsList[questType] = tempArr;
        }
      });
  },
});

export const { clearErrorMessage } = questsSlice.actions;
export const QuestsReducer = questsSlice.reducer;
