import { useEffect } from 'react';
import { overlayPixiApp } from './overlayApp';

function OverlayGame() {
  useEffect(() => {
    const slot = document.getElementById('overlay-game');
    if (slot) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      slot.appendChild(overlayPixiApp.view);
    }
  }, []);

  return (
    <div
      id="overlay-game"
      className="absolute top-0 w-screen h-screen z-[10]"
      style={{
        pointerEvents: 'none',
      }}
    />
  );
}

export default OverlayGame;
