import { motion } from 'framer-motion';
import { useState } from 'react';

function TestField() {
  const [isPressed, setIsPressed] = useState(false);
  const iconVariants = {
    default: { opacity: 1, y: 0 },
    pressed: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 10 },
  };

  return (
    <div>
      <motion.div
        onClick={() => setIsPressed(!isPressed)}
        whileTap={{ scale: 0.1 }}
        transition={{ duration: 0.2 }}
      >
        <motion.div
          key={isPressed ? 'pressed' : 'default'}
          variants={iconVariants}
          initial="hidden"
          animate={isPressed ? 'pressed' : 'default'}
          exit="hidden"
          transition={{ duration: 0.2 }}
        >
          {isPressed ? <PresssedSvg /> : <InitialSvg />}
        </motion.div>
      </motion.div>
    </div>
  );
}

export default TestField;

function PresssedSvg() {
  return (
    <svg y="17.3387" x="-21.6256" stroke="null" id="svg_250" fill="none" viewBox="0 0 820 235" height="235" width="820" xmlns="http://www.w3.org/2000/svg">
      <g stroke="null" id="svg_251" clipPath="url(#svg_242)">
        <path stroke="null" id="svg_252" fill="url(#svg_248)" d="m673.589,10.6094l-527.413,0l-139.3386,96.1936l132.0296,127.314l547.924,0l126.608,-127.314l-139.81,-96.1936z" />
        <path stroke="null" id="svg_253" fill="url(#svg_247)" d="m673.353,10.6094l-527.413,0l-139.33844,96.1936l132.02944,127.314l547.925,0l126.607,-127.314l-139.81,-96.1936z" />
        <path stroke="null" id="svg_254" fill="url(#svg_246)" d="m146.176,10.6095l-139.33874,96.1935l-6.83726,-6.13l146.176,-100.673l0,10.6095z" />
        <path stroke="null" id="svg_255" fill="url(#svg_245)" d="m673.588,0l-527.412,0l0,10.6095l527.412,0l139.811,96.1935l6.601,-6.13l-146.412,-100.673z" />
        <path stroke="null" id="svg_256" fill="url(#svg_244)" d="m6.83716,106.803l139.33884,-96.1936l527.412,0l139.81,96.1936l-10.373,0l-122.835,-84.641c-5.187,-3.3008 -11.081,-5.1869 -17.211,-5.1869l-509.494,0c-6.602,0 -13.203,1.8861 -18.862,5.4227l-127.78584,84.4052z" opacity="0.3" />
        <path stroke="null" id="svg_257" fill="url(#svg_243)" d="m6.60156,106.803l132.02944,127.314l547.925,0l126.607,-127.314l-10.374,0l-114.819,114.111c-3.772,3.537 -8.723,5.423 -13.674,5.423l-528.12,0c-3.536,0 -7.073,-1.415 -9.666,-3.772l-129.90844,-115.762z" opacity="0.3" />
        <g stroke="null" id="svg_258" clipPath="url(#svg_241)">
          <path stroke="null" id="svg_259" fill="#FCF8FF" d="m163.48,140.583c24.735,1.179 49.705,2.359 74.439,2.594c3.062,0 5.889,-0.707 8.009,-2.358c2.12,-1.651 3.534,-3.538 4.241,-6.133c1.413,-6.132 2.12,-9.199 3.769,-15.331c-18.139,0 -27.09,0 -44.993,0c-13.663,0 -22.85,-2.595 -27.091,-8.02c-4.24,-5.425 -4.004,-14.1518 0.472,-25.9451c0.706,-2.1228 1.177,-3.0663 1.884,-5.1891c4.476,-12.0293 10.601,-20.5205 17.903,-25.9454c7.303,-5.425 17.196,-8.2554 29.21,-8.2554c24.499,0 36.749,0 61.012,0c-1.885,9.4347 -2.827,14.3879 -4.947,23.8226c-22.614,0 -33.686,0 -56.3,0c-7.303,0 -12.014,3.3022 -14.134,10.1423c-0.471,1.4152 -0.707,2.1228 -1.178,3.3022c-2.12,6.8401 0.471,10.1423 8.009,10.1423c13.663,0 20.259,0 33.922,0c12.249,0 20.729,2.5945 25.676,7.7836c4.712,5.189 6.361,13.444 4.241,24.53c-0.707,3.302 -0.943,5.189 -1.649,8.491c-2.121,11.086 -6.596,19.342 -14.134,24.531c-7.538,5.189 -18.139,7.783 -31.802,7.783c-7.067,0 -14.605,0 -22.614,-0.236c-7.774,-0.235 -11.543,-0.471 -19.081,-0.707c-14.134,-0.472 -28.739,-1.415 -43.58,-2.359c3.534,-8.727 5.418,-13.444 8.716,-22.643z" />
          <path stroke="null" id="svg_260" fill="#FCF8FF" d="m318.247,46c24.263,0 48.762,0 73.026,0c12.249,0 20.965,2.5945 26.618,8.0195c5.654,5.4249 8.716,14.152 9.188,25.9454c0.235,6.8402 0.235,10.1423 0.471,16.9825c0.471,12.0296 -2.356,20.5206 -8.481,25.9456c-6.124,5.425 -16.254,8.019 -30.388,8.019c-19.081,0 -28.739,0 -48.055,0c-1.414,13.681 -2.12,20.521 -3.534,33.965c-15.076,0 -22.614,0 -37.926,0c6.596,-39.39 12.956,-79.0154 19.081,-118.877zm75.852,34.2008c0.236,-6.8402 -3.298,-10.1423 -10.6,-10.1423c-14.605,0 -21.672,0 -36.277,0c-1.649,15.0955 -2.356,22.4074 -3.769,37.5025c15.311,0 23.085,0 38.397,0c7.774,0 11.543,-3.302 11.778,-10.1419c0.236,-7.076 0.236,-10.3782 0.471,-17.2183z" />
          <path stroke="null" id="svg_261" fill="#FCF8FF" d="m518.007,165.349c-25.206,0 -50.411,0 -75.617,0c-0.471,-9.435 -0.706,-14.388 -1.177,-23.823c7.302,0 10.836,0 18.138,0c-1.884,-23.822 -3.533,-47.6449 -5.418,-71.4675c-6.596,0 -9.894,0 -16.254,0c-0.471,-9.4347 -0.707,-14.388 -1.178,-23.8227c24.97,0 37.455,0 62.661,0c1.413,9.4347 2.355,14.388 3.769,23.8227c-6.596,0 -9.894,0 -16.254,0c3.062,23.8226 6.124,47.6455 9.422,71.4675c7.303,0 10.836,0 18.139,0c1.413,9.435 2.356,14.388 3.769,23.823z" />
          <path stroke="null" id="svg_262" fill="#FCF8FF" d="m665,165.349c-16.018,0 -24.028,0 -39.811,0c-23.085,-23.351 -45.935,-46.702 -67.607,-71.4679c5.654,23.8229 11.543,47.6449 17.196,71.4679c-15.076,0 -22.614,0 -37.926,0c-7.302,-39.862 -14.605,-79.4874 -22.143,-119.349c13.192,0 19.788,0 32.979,0c20.494,24.7661 42.166,48.3528 64.31,71.468c-7.538,-23.8228 -15.077,-47.6454 -22.615,-71.468c12.485,0 18.846,0 31.331,0c14.84,39.8616 29.681,79.487 44.286,119.349z" />
          <path stroke="null" id="svg_263" fill="#E8B0FF" d="m231.559,46.2358c-12.249,0 -21.908,2.5946 -29.446,8.0195c-7.538,5.425 -13.663,14.1521 -17.903,25.9455c-0.706,2.1228 -1.178,3.0662 -1.884,5.189c-2.592,6.8402 -3.534,12.501 -3.298,16.9822c0.235,-4.2453 1.413,-8.9627 3.298,-14.3876c0.706,-2.1228 1.178,-3.0663 1.884,-5.1891c4.476,-12.0292 10.601,-20.5205 17.903,-25.9454c7.538,-5.425 17.197,-8.0195 29.446,-8.0195c24.263,0 36.513,0 60.54,0c0.236,-0.9435 0.236,-1.6511 0.472,-2.5946c-24.499,0 -36.749,0 -61.012,0z" />
          <path stroke="null" id="svg_264" fill="#E8B0FF" d="m224.256,96.4755c13.663,0 20.259,0 33.922,0c12.249,0 20.73,2.5946 25.676,7.7835c3.298,3.538 4.947,8.491 5.183,14.624c0.235,-7.548 -1.414,-13.209 -5.183,-17.218c-4.711,-5.1895 -13.191,-7.784 -25.676,-7.784c-13.663,0 -20.259,0 -33.922,0c-5.418,0 -8.245,-1.6511 -8.716,-5.1891c-0.706,5.1891 2.12,7.7836 8.716,7.7836z" />
          <path stroke="null" id="svg_265" fill="#E8B0FF" d="m237.919,145.772c3.063,0 5.889,-0.707 8.009,-2.359c2.12,-1.651 3.534,-3.538 4.241,-6.132c1.413,-6.133 2.826,-11.793 4.24,-17.926c-0.236,0 -0.471,0 -0.707,0c-1.178,4.481 -2.356,10.142 -3.769,15.331c-0.707,2.595 -2.12,4.718 -4.24,6.133c-2.12,1.651 -4.947,2.359 -8.009,2.359c-24.735,0 -49.705,-1.416 -74.439,-2.595c-3.063,9.199 -4.947,13.916 -8.245,23.115c0.236,0 0.707,0 0.942,0c2.827,-7.548 4.476,-12.265 7.538,-20.52c24.735,1.179 49.705,2.594 74.439,2.594z" />
          <path stroke="null" id="svg_266" fill="#E8B0FF" d="m427.314,79.9649c-0.471,-12.0292 -3.533,-20.5205 -9.187,-25.9454c-5.654,-5.425 -14.369,-8.0195 -26.619,-8.0195c-24.499,0 -48.762,0 -73.025,0c-6.361,39.8616 -12.485,79.487 -18.846,119.349c0.236,0 0.236,0 0.472,0c6.124,-38.918 12.249,-77.8363 18.374,-116.7545c24.263,0 48.762,0 73.025,0c12.25,0 20.965,2.5946 26.619,8.0195c5.654,5.425 8.716,14.1521 9.187,25.9455c0.236,6.8401 0.236,10.1423 0.471,16.7465c0,-0.7076 0,-1.651 0,-2.3586c-0.235,-6.6043 -0.471,-10.1423 -0.471,-16.9825z" />
          <path stroke="null" id="svg_267" fill="#E8B0FF" d="m393.628,97.1831c-0.235,6.8399 -4.24,10.3779 -11.778,10.3779l-38.633,0c0,0.944 -0.236,1.651 -0.236,2.595l38.633,0c7.774,0 11.779,-3.538 11.779,-10.3784c0.235,-1.4152 0.235,-2.5945 0.235,-2.5945z" />
          <path stroke="null" id="svg_268" fill="#E8B0FF" d="m514.238,141.526c0,0 -18.139,0 -18.374,0c0.235,0.944 0.235,1.651 0.235,2.595l18.374,0c0,-0.944 0,-1.651 -0.235,-2.595z" />
          <path stroke="null" id="svg_269" fill="#E8B0FF" d="m499.162,46c-24.97,0 -37.455,0 -62.661,0c0,0.9435 0,1.6511 0.236,2.5945c24.97,0 37.926,0 62.896,0c-0.236,-0.7076 -0.236,-1.4152 -0.471,-2.5945z" />
          <path stroke="null" id="svg_270" fill="#E8B0FF" d="m459.351,141.526c-7.067,0 -11.072,0 -18.139,0c0,0.944 0,1.887 0.236,2.595c7.303,0 11.072,0 18.374,0c-0.235,-0.944 -0.235,-1.887 -0.471,-2.595z" />
          <path stroke="null" id="svg_271" fill="#E8B0FF" d="m664.058,165.349c0.235,0 0.706,0 0.942,0c-14.605,-39.862 -29.446,-79.4874 -44.051,-119.349c-12.485,0 -18.845,0 -31.33,0c0.235,0.9435 0.471,1.6511 0.942,2.5945c12.014,0 18.139,0 30.388,0c14.37,38.9182 28.739,77.8365 43.109,116.7545z" />
          <path stroke="null" id="svg_272" fill="#E8B0FF" d="m613.411,121.478c-0.471,-1.416 -0.942,-2.595 -1.178,-4.01c-21.672,-22.8793 -44.286,-47.1737 -64.309,-71.468c-13.192,0 -19.788,0 -32.979,0c0.235,0.9435 0.235,1.6511 0.471,2.5945c12.956,0 19.552,0 32.508,0c20.494,24.7661 43.344,49.7681 65.487,72.8835z" />
        </g>
      </g>
      <defs stroke="null">
        <clipPath stroke="null" id="svg_242">
          <rect stroke="null" id="svg_273" fill="white" height="234.117" width="820" />
        </clipPath>
        <clipPath stroke="null" id="svg_241">
          <rect stroke="null" id="svg_274" x="155" y="46" fill="white" height="121" width="510" />
        </clipPath>
      </defs>
    </svg>
  );
}
function InitialSvg() {
  return (
    <>
      <svg y="359" x="62.40515" id="svg_231" fill="none" viewBox="0 0 238 103" height="103" width="238" xmlns="http://www.w3.org/2000/svg">
        <path id="svg_232" fill="url(#svg_204)" d="m187.659,22.4106l-126.0717,0l-35.1736,24.0322l0,7.1082l33.2541,31.93l131.2092,0l31.899,-31.93l0,-7.1082l-35.117,-24.0322z" />
        <path id="svg_233" fill="url(#svg_203)" d="m59.6678,85.4816l-33.2541,-31.9301l0,-7.1081l33.2541,31.9865l0,7.0517z" />
        <path id="svg_234" fill="url(#svg_202)" d="m190.877,85.4816l31.899,-31.9301l0,-7.1081l-31.899,31.9865l0,7.0517z" />
        <path id="svg_235" fill="url(#svg_201)" d="m190.877,78.4297l-131.2092,0l0,7.0517l131.2092,0l0,-7.0517z" />
        <path id="svg_236" fill="url(#svg_200)" d="m26.4137,53.5513l33.2541,31.9301l131.2092,0l31.899,-31.9301l-31.899,30.5197l-131.2092,0l-33.2541,-30.5197z" opacity="0.7" />
        <path id="svg_237" fill="url(#svg_199)" d="m26.4137,46.4433l34.9478,-24.0322l126.7495,0l26.31,18.2216l-26.592,-17.3754l-125.9029,-0.1128l-35.5124,23.2988z" />
        <path id="svg_238" fill="url(#svg_198)" d="m26.4137,46.4434l33.2541,31.9301l131.2092,0l31.899,-31.9301l-32.35,30.012l-130.5324,0l-33.4799,-30.012z" opacity="0.8" />
      </svg>
      <svg y="391" x="124.17371" id="svg_239" fill="none" viewBox="0 0 126 31" height="31" width="126" xmlns="http://www.w3.org/2000/svg">
        <g id="svg_240" clipPath="url(#svg_206)">
          <path id="svg_241" fill="#FCF8FF" d="m2.59126,23.7115c6.04655,0.2883 12.15064,0.5766 18.19724,0.6342c0.7486,0 1.4396,-0.173 1.9579,-0.5766c0.5183,-0.4036 0.8638,-0.8649 1.0366,-1.4991c0.3455,-1.4992 0.5182,-2.2488 0.9213,-3.7479c-4.4341,0 -6.6224,0 -10.9989,0c-3.34,0 -5.58587,-0.6343 -6.62242,-1.9605c-1.03655,-1.3261 -0.97896,-3.4596 0.11517,-6.3426c0.17276,-0.51889 0.28793,-0.74953 0.46069,-1.26847c1.09414,-2.94066 2.59136,-5.01641 4.37656,-6.34259c1.7852,-1.32618 4.2038,-2.0181 7.1407,-2.0181c5.9889,0 8.9834,0 14.9148,0c-0.4607,2.3064 -0.691,3.51726 -1.2093,5.82365c-5.5283,0 -8.2348,0 -13.7631,0c-1.7852,0 -2.9369,0.80724 -3.4552,2.47938c-0.1152,0.34596 -0.1727,0.51894 -0.2879,0.80724c-0.5183,1.67209 0.1152,2.47939 1.9579,2.47939c3.34,0 4.9524,0 8.2924,0c2.9945,0 5.0676,0.6342 6.2769,1.9028c1.1517,1.2685 1.5548,3.2866 1.0366,5.9966c-0.1728,0.8072 -0.2304,1.2685 -0.4031,2.0757c-0.5183,2.7101 -1.6124,4.7282 -3.4552,5.9967c-1.8428,1.2685 -4.4341,1.9028 -7.7741,1.9028c-1.7276,0 -3.5704,0 -5.5283,-0.0577c-1.9004,-0.0577 -2.8217,-0.1153 -4.6645,-0.173c-3.45516,-0.1153 -7.0255,-0.3459 -10.65343,-0.5766c0.86379,-2.1334 1.32448,-3.2866 2.13069,-5.5353z" />
          <path id="svg_242" fill="#FCF8FF" d="m40.4253,0.58984c5.9314,0 11.9204,0 17.8518,0c2.9944,0 5.1251,0.63426 6.5072,1.96044c1.3821,1.32618 2.1307,3.45959 2.2459,6.34259c0.0575,1.67213 0.0575,2.47933 0.1151,4.15153c0.1152,2.9406 -0.5758,5.0164 -2.0731,6.3426c-1.4972,1.3262 -3.9734,1.9604 -7.4286,1.9604c-4.6645,0 -7.0255,0 -11.7476,0c-0.3455,3.3443 -0.5182,5.0164 -0.8638,8.303c-3.6855,0 -5.5282,0 -9.2713,0c1.6124,-9.6292 3.1672,-19.316 4.6644,-29.06056zm18.5428,8.36069c0.0576,-1.67214 -0.8062,-2.47938 -2.5914,-2.47938c-3.5703,0 -5.2979,0 -8.8683,0c-0.4031,3.69025 -0.5758,5.47765 -0.9213,9.16795c3.7431,0 5.6434,0 9.3865,0c1.9004,0 2.8217,-0.8073 2.8793,-2.4794c0.0576,-1.7298 0.0576,-2.537 0.1152,-4.20917z" />
          <path id="svg_243" fill="#FCF8FF" d="m89.2584,29.7657c-6.1617,0 -12.3234,0 -18.4851,0c-0.1152,-2.3064 -0.1728,-3.5172 -0.288,-5.8236c1.7852,0 2.649,0 4.4342,0c-0.4607,-5.8237 -0.8638,-11.6473 -1.3245,-17.47099c-1.6124,0 -2.4186,0 -3.9735,0c-0.1151,-2.3064 -0.1727,-3.51725 -0.2879,-5.82365c6.1042,0 9.1562,0 15.3179,0c0.3456,2.3064 0.5759,3.51725 0.9214,5.82365c-1.6124,0 -2.4186,0 -3.9734,0c0.7486,5.82369 1.4972,11.64729 2.3034,17.47099c1.7852,0 2.649,0 4.4342,0c0.3455,2.3064 0.5758,3.5172 0.9213,5.8236z" />
          <path id="svg_244" fill="#FCF8FF" d="m125.192,29.7658c-3.916,0 -5.874,0 -9.732,0c-5.643,-5.7084 -11.229,-11.4167 -16.5271,-17.471c1.3821,5.8237 2.8221,11.6473 4.2041,17.471c-3.6858,0 -5.5286,0 -9.2717,0c-1.7852,-9.7446 -3.5703,-19.4314 -5.4131,-29.17596c3.2248,0 4.8372,0 8.0621,0c5.0097,6.05429 10.3077,11.82026 15.7207,17.47096c-1.842,-5.8237 -3.685,-11.64731 -5.528,-17.47096c3.052,0 4.607,0 7.659,0c3.628,9.74456 7.256,19.43136 10.826,29.17596z" />
          <path id="svg_245" fill="#E8B0FF" d="m19.2336,0.64697c-2.9945,0 -5.3555,0.63426 -7.1983,1.96044c-1.8427,1.32618 -3.33997,3.45959 -4.37652,6.34259c-0.17276,0.51894 -0.28793,0.74958 -0.46069,1.2685c-0.63344,1.6722 -0.86379,3.056 -0.8062,4.1515c0.05758,-1.0378 0.34552,-2.191 0.8062,-3.5172c0.17276,-0.519 0.28793,-0.7496 0.46069,-1.26854c1.09414,-2.94066 2.59142,-5.01641 4.37652,-6.34259c1.8428,-1.32618 4.2038,-1.96044 7.1983,-1.96044c5.9314,0 8.9259,0 14.7996,0c0.0576,-0.23064 0.0576,-0.40362 0.1152,-0.63426c-5.9889,0 -8.9834,0 -14.9148,0z" />
          <path id="svg_246" fill="#E8B0FF" d="m17.4485,12.9287c3.34,0 4.9524,0 8.2924,0c2.9945,0 5.0676,0.6342 6.2769,1.9027c0.8062,0.8649 1.2093,2.0758 1.2669,3.5749c0.0576,-1.8451 -0.3455,-3.2289 -1.2669,-4.2091c-1.1517,-1.2685 -3.2248,-1.9028 -6.2769,-1.9028c-3.34,0 -4.9524,0 -8.2924,0c-1.3245,0 -2.0155,-0.4036 -2.1307,-1.2685c-0.1728,1.2685 0.5183,1.9028 2.1307,1.9028z" />
          <path id="svg_247" fill="#E8B0FF" d="m20.7885,24.9794c0.7486,0 1.4396,-0.173 1.9579,-0.5766c0.5183,-0.4036 0.8638,-0.8649 1.0365,-1.4992c0.3456,-1.4991 0.6911,-2.883 1.0366,-4.3821c-0.0576,0 -0.1152,0 -0.1728,0c-0.2879,1.0955 -0.5758,2.4794 -0.9213,3.7479c-0.1728,0.6342 -0.5183,1.1532 -1.0366,1.4991c-0.5183,0.4037 -1.2093,0.5766 -1.9579,0.5766c-6.0466,0 -12.15071,-0.3459 -18.19726,-0.6342c-0.74862,2.2487 -1.20931,3.4019 -2.01551,5.6506c0.05758,0 0.17276,0 0.23034,0c0.69104,-1.8451 1.09414,-2.9983 1.84276,-5.0164c6.04655,0.2883 12.15067,0.6343 18.19727,0.6343z" />
          <path id="svg_248" fill="#E8B0FF" d="m67.0877,8.89238c-0.1151,-2.94065 -0.8638,-5.01641 -2.2458,-6.34259c-1.3821,-1.32618 -3.5128,-1.96044 -6.5073,-1.96044c-5.9889,0 -11.9203,0 -17.8517,0c-1.5548,9.74455 -3.052,19.43135 -4.6069,29.17595c0.0576,0 0.0576,0 0.1152,0c1.4972,-9.5139 2.9945,-19.0278 4.4917,-28.54169c5.9314,0 11.9204,0 17.8517,0c2.9945,0 5.1252,0.63426 6.5073,1.96044c1.382,1.32618 2.1307,3.4596 2.2458,6.34259c0.0576,1.67216 0.0576,2.47936 0.1152,4.09386c0,-0.173 0,-0.4036 0,-0.5766c-0.0576,-1.6145 -0.1152,-2.4794 -0.1152,-4.15152z" />
          <path id="svg_249" fill="#E8B0FF" d="m58.853,13.1016c-0.0576,1.6721 -1.0366,2.537 -2.8794,2.537l-9.4441,0c0,0.2306 -0.0576,0.4036 -0.0576,0.6343l9.4442,0c1.9003,0 2.8793,-0.8649 2.8793,-2.5371c0.0575,-0.3459 0.0576,-0.6342 0.0576,-0.6342z" />
          <path id="svg_250" fill="#E8B0FF" d="m88.3371,23.9414c0,0 -4.4342,0 -4.4918,0c0.0576,0.2306 0.0576,0.4036 0.0576,0.6343l4.4917,0c0,-0.2307 0,-0.4037 -0.0575,-0.6343z" />
          <path id="svg_251" fill="#E8B0FF" d="m84.6515,0.58935c-6.1041,0 -9.1562,0 -15.3179,0c0,0.23065 0,0.40363 0.0576,0.63426c6.1041,0 9.2714,0 15.3755,0c-0.0576,-0.17298 -0.0576,-0.34595 -0.1152,-0.63426z" />
          <path id="svg_252" fill="#E8B0FF" d="m74.9195,23.9414c-1.7276,0 -2.7066,0 -4.4341,0c0,0.2306 0,0.4613 0.0575,0.6343c1.7852,0 2.7066,0 4.4918,0c-0.0576,-0.2307 -0.0576,-0.4613 -0.1152,-0.6343z" />
          <path id="svg_253" fill="#E8B0FF" d="m124.962,29.7653c0.057,0 0.173,0 0.23,0c-3.57,-9.7446 -7.198,-19.4314 -10.768,-29.17594c-3.053,-0.00001 -4.607,-0.00001 -7.659,-0.00001c0.057,0.23065 0.115,0.40363 0.23,0.63426c2.937,0 4.434,0 7.429,0c3.512,9.51389 7.025,19.02779 10.538,28.54169z" />
          <path id="svg_254" fill="#E8B0FF" d="m112.581,19.0405c-0.115,-0.3459 -0.231,-0.6342 -0.288,-0.9802c-5.298,-5.593 -10.826,-11.53197 -15.7212,-17.47094c-3.2248,-0.00001 -4.8372,-0.00001 -8.062,-0.00001c0.0576,0.23065 0.0576,0.40363 0.1151,0.63426c3.1673,0 4.7797,0 7.9469,0c5.0102,6.05429 10.5962,12.16629 16.0092,17.81689z" />
        </g>
        <defs>
          <clipPath id="svg_206">
            <rect id="svg_255" x="0.51825" y="0.58838" fill="white" height="29.5795" width="124.674" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
