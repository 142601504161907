// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetVersionResponse,
  ISettingsStore,
} from '../../../types';
import {
  getSettings,
  getVersion,
} from './asyncActions';
import { SettingsResponse } from './types';

// Types

const initialState: ISettingsStore = {
  version: null,
  settings: null,
  loading: true,
  error: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getVersion.fulfilled,
        (state, action: PayloadAction<GetVersionResponse>) => {
          state.version = action.payload.version;
          state.error = null;
        },
      )
      .addCase(
        getSettings.fulfilled,
        (state, action: PayloadAction<SettingsResponse>) => {
          state.settings = action.payload;
          state.error = null;
          state.loading = false;
        },
      )
      .addCase(
        getSettings.pending,
        (state) => {
          state.loading = true;
        },
      )
      .addCase(
        getVersion.pending,
        (state) => {
          state.loading = true;
        },
      );
  },
});

export const SettingsReducer = settingsSlice.reducer;
