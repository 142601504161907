// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ILeaderboardStore, TopPlayersResponse,
} from '../../../types';
import { getTopPlayers } from './asyncActions';

// Types

const initialState: ILeaderboardStore = {
  leaderboard: null,
  error: null,
  loading: true,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getTopPlayers.fulfilled,
        (state, action: PayloadAction<TopPlayersResponse>) => {
          state.leaderboard = action.payload;
          state.error = null;
          state.loading = false;
        },
      );
  },
});

export const LeaderboardReducer = leaderboardSlice.reducer;
