import { useEffect } from 'react';
import { CountDownT, useCountDown } from './useCoundown';
import { useUserEnergy } from './useUserEnergy';

export type EnergyRecoveryTimerProps = {
  energyUnit?: number
}
export type EnergyRecoveryTimerT = {
  shouldStartTimer: boolean
  countdown: CountDownT
}
export const useEnergyRecoveryTimer = ({ energyUnit }: EnergyRecoveryTimerProps): EnergyRecoveryTimerT => {
  const {
    energyRecoveryPerSecond, maxEnergy, userEnergy,
  } = useUserEnergy();
  function calculateEnergyToNextSection(currentEnergy: number, sections = 5): number {
    const sectionCapacity = maxEnergy / sections;
    const currentSection = Math.floor(currentEnergy / sectionCapacity);
    const nextSectionThreshold = sectionCapacity * (currentSection + 1);

    if (currentEnergy >= maxEnergy) {
      return 0;
    }
    return nextSectionThreshold - currentEnergy;
  }

  const missingEnergyForSection = energyUnit || calculateEnergyToNextSection(userEnergy);
  const totalSecondsForFullRecovery = energyRecoveryPerSecond > 0 ? missingEnergyForSection / energyRecoveryPerSecond : 0;
  const shouldStartTimer = userEnergy < maxEnergy;

  const countdown = useCountDown(shouldStartTimer ? Math.round(totalSecondsForFullRecovery) : undefined);

  useEffect(() => {
    if (shouldStartTimer) {
      countdown.restart(Math.round(totalSecondsForFullRecovery));
    }
    if (countdown.isFinished) {
      countdown.restart(Math.round(totalSecondsForFullRecovery));
    }
  }, [maxEnergy, energyRecoveryPerSecond, countdown.isFinished]);
  return {
    countdown, shouldStartTimer,
  };
};
