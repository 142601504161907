import React, {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { ReactComponent as Info } from '../../assets/svg/info.svg';
import ProgressBar from '../../Components/ProgressBar';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import Button from '../../Components/Button';
import LeaderboardUserCard from '../../Components/LeaderboardUserCard';
import { LeaguesUnion, ProfileResponseT } from '../../types';
import { useAppSelector } from '../../store';
import LeaguesModal from './LeaguesModal';
import { SettingsResponse } from '../../store/reducers/settings/types';
import LeagueIcon from '../../Components/LeagueIcon';
import { formatNumbers, getNextLeagueCoins, numberWithCommas } from '../../helpers';

function Leaderboard() {
  const {
    leaderboard: topPlayers,
  } = useAppSelector(({ leaderboard }) => leaderboard);
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const leaguesList: LeaguesUnion[] = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Challenger', 'Legendary', 'Master'];
  const currentLeague: LeaguesUnion = userData?.league.name as LeaguesUnion;
  const nextLeague: LeaguesUnion = leaguesList[leaguesList.indexOf(currentLeague) + 1];
  const statsRef = useRef<HTMLDivElement>(null);

  const [userCardVisible, setUserCardVisible] = useState<boolean>(false);
  const [activeLeague, setActiveLeague] = useState<LeaguesUnion>(currentLeague);
  const [openLeaguesModal, setOpenLeaguesModal] = useState<boolean>(false);
  const goldFrom = useMemo(() => userSettings?.leagues[userSettings?.leagues?.findIndex((league) => league.name === activeLeague)].from, [userSettings, userData, activeLeague]);
  const goldToNextLeague: number = useMemo(() => getNextLeagueCoins((userSettings as SettingsResponse).leagues, (userData as ProfileResponseT).league.name), [userSettings, userData]);

  useEffect(() => {
    handleUserCardPosition();
  }, [statsRef?.current, activeLeague]);

  const getStarBg = (league: LeaguesUnion) => {
    switch (league) {
      case 'Bronze':
        return 'before:bg-leaderboardBronzeBg';
      case 'Silver':
        return 'before:bg-leaderboardSilverBg';
      case 'Gold':
        return 'before:bg-leaderboardGoldBg';
      case 'Platinum':
        return 'before:bg-leaderboardPlatinumBg';
      case 'Diamond':
        return 'before:bg-leaderboardDiamondBg';
      case 'Challenger':
        return 'before:bg-leaderboardChallengerBg';
      case 'Legendary':
        return 'before:bg-leaderboardLegendaryBg';
      case 'Master':
        return 'before:bg-leaderboardMasterBg';
      default:
        return 'before:bg-leaderboardBronzeBg';
    }
  };

  const handleUserCardPosition = () => {
    const statsBottomPosition = statsRef?.current?.getBoundingClientRect().bottom;
    const menu = document.getElementById('bottom_navigation_menu');
    const menuTopPosition = menu?.getBoundingClientRect().top;
    const userCardPosition = document.getElementById(`leader_board_user_card_${userData?.telegram_id}`)?.getBoundingClientRect();

    if (statsBottomPosition && menuTopPosition && userCardPosition) {
      const condition = userCardPosition?.bottom < (menuTopPosition + 15)
          && userCardPosition?.bottom > (statsBottomPosition);
      setUserCardVisible(!condition);
    } else {
      setUserCardVisible(false);
    }
  };

  const handleScroll = () => {
    handleUserCardPosition();
  };

  return (
    <div className="absolute h-screen w-screen  bg-no-repeat  top-0">
      <div className="mt-12">
        <div className={`mt-[14.3vh] flex flex-col items-center before:content-[''] 
             ${getStarBg((userData as ProfileResponseT)?.league.name)} 
             before:w-screen before:left-0 before:bg-centerCenter before:h-[40vh] before:absolute before:bg-no-repeat before:top-[1.8rem] before:z-[1]`}
        >
          <div className="z-[1] h-fit flex flex-col items-center">
            <div className="text-gray-2 text-xs ml-[-.25rem] pb-[.8rem]">Your rank</div>
            <div className="h-[5rem] max-w-[5.625rem]">
              <LeagueIcon league={(userData as ProfileResponseT)?.league.name} className="w-[5rem]" />
            </div>
            <div className="text-white mt-[.8rem] ml-[-.15rem] text-22 font-bold">
              {currentLeague}
            </div>
          </div>
          <div className="mt-[.7rem] pb-[.4rem] w-full px-4" ref={statsRef}>
            <div className="max-w-[450px] mx-auto my-0">
              <div>
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <div className="h-[20px] max-w-[25px]">
                      <LeagueIcon league={currentLeague} />
                    </div>
                    <span className="text-14 font-bold">{currentLeague}</span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <span className="text-14 font-bold">{nextLeague}</span>
                    <div className="h-[20px] max-w-[25px]">
                      {nextLeague ? (<LeagueIcon league={nextLeague} />) : ''}
                    </div>
                  </div>
                </div>
                <div className="mt-[.3rem]">
                  <ProgressBar currentValue={userData?.total_earned_coins || 0} goal={goldToNextLeague} />
                </div>
                <div className="flex justify-center items-center mt-2">
                  <span className="text-14 pr-1">
                    {formatNumbers(userData?.total_earned_coins || 0, 2)}
                  </span>
                  <span className="text-14">
                    <Coin width=".875rem" height=".875rem" />
                  </span>
                  <span className="text-gray-2 mx-2 text-14">/</span>
                  <span className="text-14 pr-1">
                    {nextLeague ? numberWithCommas(goldToNextLeague) : <span className="text-xl"> &#8734;</span>}
                  </span>
                  <span className="text-14">
                    <Coin width=".875rem" height=".875rem" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 pt-4 overflow-y-auto h-[60vh]" onScroll={handleScroll}>
            <div className="py-[.2rem] bg-black-light-bg rounded-10 flex justify-between items-start gap-[1px]">
              {userSettings?.leagues.map((league) => (
                <div
                  onClick={() => setActiveLeague(league.name)}
                  className={`py-[.125rem] flex flex-col text-sm min-w-[42px] max-w-[50px] items-start rounded-10 text-gray-300 ${activeLeague === league.name && 'bg-black-modal-bg text-white'}`}
                  key={league.name}
                >
                  <div className="p-0 my-0 mx-auto h-[20px] w-[28px]">
                    {['Bronze', 'Silver', 'Gold'].includes(league.name)
                      ? <LeagueIcon className="w-[30px] h-[25px] mt-[.1875rem]" league={league.name} />
                      : <LeagueIcon className="w-[30px] h-[23px] mt-[.23rem]" league={league.name} />}

                  </div>
                  <div className="text-center w-full truncate text-[10px] pt-[.375rem]">
                    {league.name}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between pt-3">
              <div className="flex flex-col items-baseline">
                <div className="text-lg font-bold">
                  {activeLeague}
                </div>
                <div className="flex items-center gap-2 text-gray-2 text-sm">
                  from
                  {' '}
                  {numberWithCommas(goldFrom || 0)}
                  <Coin width="14px" />
                </div>
              </div>
              <Button
                onClick={() => setOpenLeaguesModal(true)}
                className="rounded-10 focus:outline-none transform active:scale-75 transition-transform"
                pureClass
              >
                <Info height="24px" width="24px" />
              </Button>
            </div>
            <div
              className="mt-4 flex flex-col gap-[.7rem] mb-[11rem]"
            >
              {topPlayers?.[activeLeague].map((leader) => (
                <LeaderboardUserCard
                  id={`leader_board_user_card_${leader.telegram_id}`}
                  key={leader.telegram_id}
                  {...leader}
                />
              ))}
            </div>
          </div>

          <LeaguesModal
            open={openLeaguesModal}
            onClose={() => setOpenLeaguesModal(false)}
            leagues={(userSettings as SettingsResponse)?.leagues}
          />

          {userData
          && (
          <div className="py-2 px-4 absolute z-[4] w-full bottom-[6.3rem]">
            {userCardVisible
            && (
            <LeaderboardUserCard
              username={userData?.username || ''}
              league={userData?.league}
              total_earned_coins={userData.total_earned_coins}
              isUser
            />
            )}
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default Leaderboard;
