import { UseUserEnergyT } from '../../../hooks/useUserEnergy';
import { CountDownT } from '../../../hooks/useCoundown';

type MiddleScreenProps = {
  countdown: CountDownT
  userEnergy: UseUserEnergyT['userEnergy']
  maxEnergy: UseUserEnergyT['maxEnergy']
}
function MiddleScreen({ countdown, userEnergy, maxEnergy }: MiddleScreenProps) {
  return (
    <div className="font-Russo text-[9px]" id="slot-middle-screen">
      {(userEnergy === maxEnergy) ? null
        : (
          <div>
            1
            {' '}
            <span className="text-[#83F3F1]">
              Spin in
            </span>
            {' '}
            <span>
              {countdown.formatedMinutes}
              :
              {countdown.formatedSecond}
            </span>
          </div>
        )}
    </div>
  );
}
export default MiddleScreen;
