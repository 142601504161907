function SecondLayer() {
  return (
    <svg
      style={{
        width: '100%',
        height: '100%',
      }}
      className="w-full h-auto max-w-full"
      y="165"
      x="-1.44956"
      preserveAspectRatio="xMidYMid meet"
      // viewBox="0 0 1534 911"
      viewBox="0 0 1534 911"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_316_3543" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="1535" height="913">
        <path d="M1534.67 0.970703H0.202637V912H1534.67V0.970703Z" fill="white" />
      </mask>
      <g mask="url(#mask0_316_3543)">
        <path d="M1534.91 182.82H0.252441V575.67H1534.91V182.82Z" fill="url(#paint0_linear_316_3543)" />
        <path d="M1577.48 581.154C1576.48 581.154 1575.5 580.713 1574.84 579.832L1282.59 190.433C1281.49 188.989 1281.78 186.908 1283.25 185.831C1284.7 184.729 1286.75 185.023 1287.85 186.492L1580.1 575.891C1581.2 577.335 1580.91 579.416 1579.44 580.493C1578.85 580.933 1578.17 581.154 1577.46 581.154H1577.48Z" fill="url(#paint1_linear_316_3543)" />
        <path d="M-42.8536 581.153C-43.539 581.153 -44.2244 580.932 -44.8364 580.492C-46.2806 579.39 -46.5743 577.334 -45.4973 575.89L246.748 186.491C247.85 185.022 249.906 184.753 251.35 185.83C252.794 186.931 253.088 188.987 252.011 190.432L-40.2345 579.831C-40.8709 580.688 -41.8745 581.153 -42.8781 581.153H-42.8536Z" fill="url(#paint2_linear_316_3543)" />
        <path d="M-71.64 259.094C-72.6436 259.094 -73.6227 258.653 -74.2591 257.796C-75.3607 256.352 -75.0669 254.296 -73.6227 253.194L21.7933 181.865C23.2375 180.763 25.2937 181.057 26.3952 182.501C27.4967 183.946 27.203 186.002 25.7588 187.103L-69.6328 258.433C-70.2202 258.873 -70.9301 259.094 -71.6155 259.094H-71.64Z" fill="url(#paint3_linear_316_3543)" />
        <path d="M1600.61 250.771C1599.93 250.771 1599.24 250.551 1598.66 250.11L1508.87 186.467C1507.42 185.39 1507.11 183.309 1508.21 181.865C1509.29 180.396 1511.37 180.103 1512.81 181.204L1602.6 244.847C1604.04 245.924 1604.36 248.005 1603.26 249.449C1602.62 250.306 1601.62 250.771 1600.61 250.771Z" fill="url(#paint4_linear_316_3543)" />
        <path d="M471.237 252.066L349.348 576.693L355.465 578.992L477.357 254.362L471.237 252.066Z" fill="url(#paint5_linear_316_3543)" />
        <path d="M769.781 233.362L763.245 233.402L765.365 580.153L771.901 580.114L769.781 233.362Z" fill="url(#paint6_linear_316_3543)" />
        <path d="M1068.13 252.103L1062.02 254.402L1183.97 579.009L1190.08 576.711L1068.13 252.103Z" fill="url(#paint7_linear_316_3543)" />
        <path d="M1535.18 575.547H-3.44385V602.914H1535.28L1535.18 575.547Z" fill="url(#paint8_linear_316_3543)" />
        <path d="M1535.03 602.695H-1.92725L120.464 720.753H1414.41L1535.03 602.695Z" fill="url(#paint9_radial_316_3543)" />
        <path d="M1341.12 892.124H193.141L118.042 720.752H1414.55L1341.12 892.124Z" fill="url(#paint10_radial_316_3543)" />
        <path d="M1341.12 892.124H1000.97L952.014 720.752H1414.55L1341.12 892.124Z" fill="url(#paint11_radial_316_3543)" />
        <path d="M193.141 892.124H533.29L582.246 720.752H118.042L193.141 892.124Z" fill="url(#paint12_radial_316_3543)" />
        <path d="M-1.92725 602.695L118.04 720.41H516.276L630.149 602.45L-1.92725 602.695Z" fill="url(#paint13_radial_316_3543)" />
        <path d="M1535.04 602.695L1415.07 720.41H1016.83L902.96 602.45L1535.04 602.695Z" fill="url(#paint14_radial_316_3543)" />
        <g filter="url(#filter0_d_316_3543)">
          <g filter="url(#filter1_di_316_3543)">
            <path d="M1370.27 657.182H164.599C162.567 657.182 160.927 655.542 160.927 653.511C160.927 651.479 162.567 649.839 164.599 649.839H1370.27C1372.3 649.839 1373.94 651.479 1373.94 653.511C1373.94 655.542 1372.3 657.182 1370.27 657.182Z" fill="#5CDCFF" />
          </g>
        </g>
        <path d="M318.078 803.44C318.078 803.44 361.429 760.089 373.521 747.997H1161.35C1173.44 760.089 1204.7 791.347 1216.8 803.44H318.078Z" fill="url(#paint15_radial_316_3543)" />
      </g>
      <defs>
        <filter id="filter0_d_316_3543" x="152.927" y="641.839" width="1229.02" height="23.3433" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.905882 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_316_3543" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_316_3543" result="shape" />
        </filter>
        <filter id="filter1_di_316_3543" x="156.927" y="649.839" width="1221.02" height="15.3433" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_316_3543" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_316_3543" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_316_3543" />
        </filter>
        <linearGradient id="paint0_linear_316_3543" x1="767.595" y1="575.67" x2="767.595" y2="182.82" gradientUnits="userSpaceOnUse">
          <stop stopColor="#12E7FA" />
          <stop offset="0.09" stopColor="#0BC0F9" />
          <stop offset="0.18" stopColor="#06A2F9" />
          <stop offset="0.22" stopColor="#0597F9" />
          <stop offset="0.32" stopColor="#048BEE" />
          <stop offset="0.49" stopColor="#046DD2" />
          <stop offset="0.72" stopColor="#033DA5" />
          <stop offset="0.92" stopColor="#020D78" />
        </linearGradient>
        <linearGradient id="paint1_linear_316_3543" x1="1281.93" y1="383.15" x2="1580.76" y2="383.15" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1D3CDD" stopOpacity="0" />
          <stop offset="0.22" stopColor="#3379E5" stopOpacity="0.35" />
          <stop offset="0.42" stopColor="#44A9EB" stopOpacity="0.63" />
          <stop offset="0.58" stopColor="#51CCF0" stopOpacity="0.83" />
          <stop offset="0.71" stopColor="#59E1F2" stopOpacity="0.95" />
          <stop offset="0.78" stopColor="#5CEAF4" />
          <stop offset="0.81" stopColor="#67EBF5" />
          <stop offset="0.87" stopColor="#85F1F7" />
          <stop offset="0.95" stopColor="#B6F9FC" />
          <stop offset="1" stopColor="#D4FFFF" />
        </linearGradient>
        <linearGradient id="paint2_linear_316_3543" x1="-46.1582" y1="383.149" x2="252.672" y2="383.149" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4FFFF" />
          <stop offset="0.05" stopColor="#B6F9FC" />
          <stop offset="0.13" stopColor="#85F1F7" />
          <stop offset="0.19" stopColor="#67EBF5" />
          <stop offset="0.22" stopColor="#5CEAF4" />
          <stop offset="0.29" stopColor="#59E1F2" stopOpacity="0.95" />
          <stop offset="0.42" stopColor="#51CCF0" stopOpacity="0.83" />
          <stop offset="0.58" stopColor="#44A9EB" stopOpacity="0.63" />
          <stop offset="0.78" stopColor="#3379E5" stopOpacity="0.35" />
          <stop offset="1" stopColor="#1D3CDD" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_316_3543" x1="-74.92" y1="220.149" x2="27.0806" y2="220.149" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5CEAF4" />
          <stop offset="0.09" stopColor="#59E1F2" stopOpacity="0.95" />
          <stop offset="0.25" stopColor="#51CCF0" stopOpacity="0.83" />
          <stop offset="0.46" stopColor="#44A9EB" stopOpacity="0.63" />
          <stop offset="0.71" stopColor="#3379E5" stopOpacity="0.35" />
          <stop offset="1" stopColor="#1D3CDD" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint4_linear_316_3543" x1="1507.55" y1="215.669" x2="1603.92" y2="215.669" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1D3CDD" stopOpacity="0" />
          <stop offset="0.29" stopColor="#3379E5" stopOpacity="0.35" />
          <stop offset="0.54" stopColor="#44A9EB" stopOpacity="0.63" />
          <stop offset="0.75" stopColor="#51CCF0" stopOpacity="0.83" />
          <stop offset="0.91" stopColor="#59E1F2" stopOpacity="0.95" />
          <stop offset="1" stopColor="#5CEAF4" />
        </linearGradient>
        <linearGradient id="paint5_linear_316_3543" x1="349.285" y1="415.553" x2="477.33" y2="415.553" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4FFFF" />
          <stop offset="0.05" stopColor="#B6F9FC" />
          <stop offset="0.13" stopColor="#85F1F7" />
          <stop offset="0.19" stopColor="#67EBF5" />
          <stop offset="0.22" stopColor="#5CEAF4" />
          <stop offset="0.29" stopColor="#59E1F2" stopOpacity="0.95" />
          <stop offset="0.42" stopColor="#51CCF0" stopOpacity="0.83" />
          <stop offset="0.58" stopColor="#44A9EB" stopOpacity="0.63" />
          <stop offset="0.78" stopColor="#3379E5" stopOpacity="0.35" />
          <stop offset="1" stopColor="#1D3CDD" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint6_linear_316_3543" x1="707.79" y1="429.686" x2="827.388" y2="383.943" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4FFFF" />
          <stop offset="0.05" stopColor="#B6F9FC" />
          <stop offset="0.13" stopColor="#85F1F7" />
          <stop offset="0.19" stopColor="#67EBF5" />
          <stop offset="0.22" stopColor="#5CEAF4" />
          <stop offset="0.29" stopColor="#59E1F2" stopOpacity="0.95" />
          <stop offset="0.42" stopColor="#51CCF0" stopOpacity="0.83" />
          <stop offset="0.58" stopColor="#44A9EB" stopOpacity="0.63" />
          <stop offset="0.78" stopColor="#3379E5" stopOpacity="0.35" />
          <stop offset="1" stopColor="#1D3CDD" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint7_linear_316_3543" x1="1062.02" y1="415.566" x2="1190.07" y2="415.566" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1D3CDD" stopOpacity="0" />
          <stop offset="0.22" stopColor="#3379E5" stopOpacity="0.35" />
          <stop offset="0.42" stopColor="#44A9EB" stopOpacity="0.63" />
          <stop offset="0.58" stopColor="#51CCF0" stopOpacity="0.83" />
          <stop offset="0.71" stopColor="#59E1F2" stopOpacity="0.95" />
          <stop offset="0.78" stopColor="#5CEAF4" />
          <stop offset="0.81" stopColor="#67EBF5" />
          <stop offset="0.87" stopColor="#85F1F7" />
          <stop offset="0.95" stopColor="#B6F9FC" />
          <stop offset="1" stopColor="#D4FFFF" />
        </linearGradient>
        <linearGradient id="paint8_linear_316_3543" x1="-3.44385" y1="589.23" x2="1535.28" y2="589.23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0597F9" />
          <stop offset="0.23" stopColor="#12E7FA" />
          <stop offset="0.24" stopColor="#14E7FA" />
          <stop offset="0.3" stopColor="#4DEEFB" />
          <stop offset="0.35" stopColor="#7DF4FC" />
          <stop offset="0.41" stopColor="#A1F8FD" />
          <stop offset="0.46" stopColor="#BCFCFE" />
          <stop offset="0.51" stopColor="#CCFEFE" />
          <stop offset="0.54" stopColor="#D2FFFF" />
          <stop offset="0.58" stopColor="#CDFEFE" />
          <stop offset="0.62" stopColor="#C0FCFE" />
          <stop offset="0.67" stopColor="#ABFAFD" />
          <stop offset="0.71" stopColor="#8DF6FD" />
          <stop offset="0.76" stopColor="#66F1FC" />
          <stop offset="0.8" stopColor="#38EBFB" />
          <stop offset="0.84" stopColor="#12E7FA" />
          <stop offset="0.87" stopColor="#10DEF9" />
          <stop offset="0.92" stopColor="#0DC8F9" />
          <stop offset="0.98" stopColor="#07A4F9" />
          <stop offset="1" stopColor="#0597F9" />
        </linearGradient>
        <radialGradient id="paint9_radial_316_3543" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(766.542 605.346) scale(928.091 250.583)">
          <stop stopColor="#1E429C" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <radialGradient id="paint10_radial_316_3543" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(734.819 755.903) scale(1282.09 384.628)">
          <stop stopColor="#1E429C" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <radialGradient id="paint11_radial_316_3543" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1448.36 826.594) rotate(13.47) scale(137.127 525.197)">
          <stop stopColor="#9E3CF8" stopOpacity="0.8" />
          <stop offset="0.14" stopColor="#7661F8" stopOpacity="0.6" />
          <stop offset="0.28" stopColor="#5283F8" stopOpacity="0.42" />
          <stop offset="0.43" stopColor="#349FF8" stopOpacity="0.27" />
          <stop offset="0.58" stopColor="#1DB5F8" stopOpacity="0.15" />
          <stop offset="0.72" stopColor="#0DC5F8" stopOpacity="0.07" />
          <stop offset="0.86" stopColor="#03CEF8" stopOpacity="0.02" />
          <stop offset="1" stopColor="#00D2F8" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint12_radial_316_3543" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.934 803.315) rotate(168.84) scale(174.407 413.346)">
          <stop stopColor="#9E3CF8" />
          <stop offset="0.06" stopColor="#8B4DF8" stopOpacity="0.89" />
          <stop offset="0.21" stopColor="#676FF8" stopOpacity="0.65" />
          <stop offset="0.35" stopColor="#478DF8" stopOpacity="0.45" />
          <stop offset="0.49" stopColor="#2DA6F8" stopOpacity="0.29" />
          <stop offset="0.63" stopColor="#19B9F8" stopOpacity="0.16" />
          <stop offset="0.76" stopColor="#0BC7F8" stopOpacity="0.07" />
          <stop offset="0.89" stopColor="#02CFF8" stopOpacity="0.02" />
          <stop offset="1" stopColor="#00D2F8" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint13_radial_316_3543" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(63.4583 695.207) rotate(-134.1) scale(747.564 321.453)">
          <stop stopColor="#9E3CF8" stopOpacity="0.8" />
          <stop offset="0.14" stopColor="#7661F8" stopOpacity="0.6" />
          <stop offset="0.28" stopColor="#5283F8" stopOpacity="0.42" />
          <stop offset="0.43" stopColor="#349FF8" stopOpacity="0.27" />
          <stop offset="0.58" stopColor="#1DB5F8" stopOpacity="0.15" />
          <stop offset="0.72" stopColor="#0DC5F8" stopOpacity="0.07" />
          <stop offset="0.86" stopColor="#03CEF8" stopOpacity="0.02" />
          <stop offset="1" stopColor="#00D2F8" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint14_radial_316_3543" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1506.74 699.31) rotate(-45.9) scale(489.784 210.607)">
          <stop stopColor="#9E3CF8" stopOpacity="0.8" />
          <stop offset="0.14" stopColor="#7661F8" stopOpacity="0.6" />
          <stop offset="0.28" stopColor="#5283F8" stopOpacity="0.42" />
          <stop offset="0.43" stopColor="#349FF8" stopOpacity="0.27" />
          <stop offset="0.58" stopColor="#1DB5F8" stopOpacity="0.15" />
          <stop offset="0.72" stopColor="#0DC5F8" stopOpacity="0.07" />
          <stop offset="0.86" stopColor="#03CEF8" stopOpacity="0.02" />
          <stop offset="1" stopColor="#00D2F8" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint15_radial_316_3543" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(764.793 778.036) scale(594.323 50.5808)">
          <stop stopColor="#1E429C" />
          <stop offset="0.25" stopColor="#173787" />
          <stop offset="0.76" stopColor="#081D53" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
      </defs>
    </svg>
  // <g transform="matrix(1 0 0 1.14106 0 -53.1682)" stroke="null" id="svg_283">
  //   <svg stroke="null" y="376" x="-1.44956" id="svg_208" fill="none" viewBox="0 0 375 101" height="101" width="375" xmlns="http://www.w3.org/2000/svg">
  //     <g stroke="null" id="svg_209" clipPath="ursl(#svg_178)">
  //       <path stroke="null" id="svg_210" fill="url(#svg_188)" d="m376.956,0.92334l-376.95588,0l0,96.39826l376.95588,0l0,-96.39826z" />
  //       <path stroke="null" id="svg_211" fill="url(#svg_187)" d="m87.2183,98.1782l-1.4841,-0.5704l29.9668,-79.6854l1.484,0.5704l-29.9667,79.6854z" />
  //       <path stroke="null" id="svg_212" fill="url(#svg_186)" d="m189.505,98.4065l-1.598,0.0571l-0.514,-85.1614l1.599,0l0.513,85.1043z" />
  //       <path stroke="null" id="svg_213" fill="url(#svg_185)" d="m290.765,98.1782l-29.967,-79.6854l1.484,-0.5704l29.967,79.6854l-1.484,0.5704z" />
  //       <path stroke="null" id="svg_214" fill="url(#svg_184)" d="m376.956,82.6059l-60.676,-80.76923c-0.286,-0.34224 -0.799,-0.45632 -1.142,-0.17112c-0.342,0.2852 -0.456,0.79857 -0.171,1.14081l61.989,82.53744l0,-2.7379z" />
  //       <path stroke="null" id="svg_215" fill="url(#svg_182)" d="m372.047,0.92383l-1.769,0c-0.115,0.34224 0,0.68448 0.285,0.91264l6.393,4.56324l0,-1.99642l-4.909,-3.47946z" />
  //       <path stroke="null" id="svg_216" fill="url(#svg_181)" d="m6.45022,0.92383l-1.48407,0l-5.02303,3.76467l0,1.99641l6.33586,-4.73435c0.34248,-0.22817 0.39956,-0.68449 0.17124,-1.02673z" />
  //       <path stroke="null" id="svg_217" fill="url(#svg_180)" d="m61.7036,1.66555c-0.3425,-0.2852 -0.8562,-0.17112 -1.1416,0.17112l-60.56182,80.59813l0,2.6809l61.81752,-82.30934c0.2854,-0.39928 0.2283,-0.85561 -0.1141,-1.14081z" />
  //       <path stroke="null" id="svg_218" fill="url(#svg_179)" d="m376.956,97.3208l-376.95594,0l0,2.8522l376.95594,0l0,-2.8522z" />
  //     </g>
  //     <defs stroke="null">
  //       <clipPath stroke="null" id="svg_178">
  //         <rect stroke="null" id="svg_219" x="0" y="0.92139" fill="white" height="99.2503" width="376.956" />
  //       </clipPath>
  //     </defs>
  //   </svg>
  // </g>
  );
}

export default SecondLayer;
