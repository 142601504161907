const Pages = {
  home: '/',
  friends: '/friends',
  quests: '/quests',
  leaderbord: '/leaderbord',
  store: '/store',
  testField: '/test-field',
} as const;

export default Pages;
