import { useEffect, useState } from 'react';

export type CountDownT = {
  hours: number
  minutes: number
  seconds: number
  formatedSecond: string
  formatedMinutes: string
  restart: (vla: number) => void
  isFinished: boolean
}
export const useCountDown = (initialSeconds: number | undefined, increasing = false): CountDownT => {
  const [counter, setCounter] = useState<number | undefined>(initialSeconds);
  useEffect(() => {
    if (!counter || counter === 0) {
      return () => null;
    }
    const timer = setTimeout(() => {
      if (increasing) {
        setCounter(counter + 1);
      } else {
        setCounter(counter - 1);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [counter, initialSeconds]);
  const hours = counter ? Math.floor(counter / 3600) : 0;
  const minutes = counter ? Math.floor((counter % 3600) / 60) : 0;
  const seconds = counter ? counter % 60 : 0;
  const formatedSecond = seconds < 10 ? `0${seconds}` : seconds.toString();
  const formatedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  const isFinished = (!seconds && !minutes && !hours) || seconds < 0;
  const restart = (val: number) => {
    setCounter(val);
  };
  return {
    hours, minutes, seconds, formatedSecond, formatedMinutes, restart, isFinished,
  };
};
