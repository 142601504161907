type CoinsSvgProps ={
  multiplier: number | null
}
function CoinsSvg({ multiplier }: CoinsSvgProps) {
  return (
    <svg width="231" height="100" viewBox="0 0 231 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1698_169247)">
        <g clipPath="url(#clip1_1698_169247)">
          <path d="M176.595 51.113L182.867 56.0975C183.58 56.719 184.083 57.5336 184.369 58.4523L187.785 68.54C188.232 69.8499 188.199 71.3267 187.662 72.6145L182.802 84.5196C182.267 85.852 181.267 86.9034 180.011 87.4809L171.913 91.252C171.092 91.6656 170.165 91.8185 169.266 91.7459L161.81 91.1837L176.595 51.113Z" fill="url(#paint0_linear_1698_169247)" />
          <path d="M176.305 50.9612L164.922 47.8528C163.863 47.3891 162.659 47.4268 161.622 47.9446L151.123 53.846C150.217 54.3102 149.468 55.121 149.087 56.0854L144.812 66.7441C144.431 67.7085 144.418 68.8264 144.755 69.831L147.897 82.4397C148.287 83.5747 149.107 84.4569 150.211 84.9176L160.538 90.8686C161.597 91.3322 162.801 91.2946 163.838 90.7768L175.727 84.9589C176.634 84.4947 177.386 83.7284 177.763 82.7195L183.098 69.2627C183.479 68.2984 183.492 67.1805 183.155 66.1758L178.619 53.4391C178.226 52.2596 177.406 51.3774 176.305 50.9612Z" fill="url(#paint1_linear_1698_169247)" />
          <path d="M172.33 54.8973L163.907 52.6139C163.124 52.2654 162.234 52.3263 161.455 52.6924L153.673 57.1569C152.983 57.5169 152.441 58.0901 152.134 58.826L148.925 66.7756C148.618 67.5115 148.628 68.315 148.858 69.0589L151.151 78.4192C151.432 79.249 152.058 79.921 152.839 80.225L160.467 84.618C161.251 84.9666 162.14 84.9057 162.919 84.5396L171.736 80.1831C172.427 79.8231 172.968 79.2498 173.275 78.5139L177.279 68.4547C177.586 67.7187 177.575 66.9152 177.346 66.1713L174.017 56.7031C173.695 55.9209 173.113 55.2458 172.33 54.8973Z" fill="#F08F3B" />
          <mask
            id="mask0_1698_169247"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="148"
            y="52"
            width="30"
            height="33"
          >
            <path d="M172.33 54.8973L163.907 52.6139C163.124 52.2654 162.234 52.3263 161.455 52.6924L153.673 57.1569C152.983 57.5169 152.441 58.0901 152.134 58.826L148.925 66.7756C148.618 67.5115 148.628 68.315 148.858 69.0589L151.151 78.4192C151.432 79.249 152.058 79.921 152.839 80.225L160.467 84.618C161.251 84.9666 162.14 84.9057 162.919 84.5396L171.736 80.1831C172.427 79.8231 172.968 79.2498 173.275 78.5139L177.279 68.4547C177.586 67.7187 177.575 66.9152 177.346 66.1713L174.017 56.7031C173.695 55.9209 173.113 55.2458 172.33 54.8973Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1698_169247)">
            <path d="M174.49 55.7772L166.068 53.4938C165.284 53.1453 164.395 53.2062 163.616 53.5723L155.834 58.0367C155.143 58.3967 154.602 58.97 154.295 59.7059L151.085 67.6554C150.778 68.3914 150.788 69.1949 151.018 69.9388L153.312 79.2991C153.592 80.1288 154.219 80.8009 154.999 81.1049L162.628 85.4979C163.411 85.8465 164.301 85.7856 165.08 85.4195L173.897 81.0629C174.587 80.7029 175.129 80.1297 175.436 79.3938L179.439 69.3345C179.746 68.5986 179.736 67.795 179.506 67.0512L176.177 57.583C175.897 56.7532 175.273 56.1257 174.49 55.7772Z" fill="#FFA42F" />
          </g>
          <path d="M161.916 78.3529L158.611 77.73L170.514 61.0089L173.588 65.4454C173.835 65.786 173.995 66.1771 174.026 66.6219C174.14 67.6417 173.915 69.5784 171.767 72.1382C170.32 73.8458 167.347 76.1046 165.028 77.4697C164.216 78.0167 162.82 78.5144 161.916 78.3529Z" fill="#F08F3B" />
          <path d="M159.376 58.8669C165.424 56.4871 172.445 61.1451 172.692 65.4176C173.008 71.3387 161.843 79.2517 158.335 77.6151C155.519 76.2886 153.414 61.1962 159.376 58.8669Z" fill="url(#paint2_linear_1698_169247)" />
          <path d="M160.352 62.6868C159.736 62.8183 159.223 63.1662 158.99 63.6736C158.638 64.4126 158.368 65.6822 159.092 67.7774C160.367 71.3987 162.799 67.7471 163.057 66.2996C163.435 63.995 162.602 62.2647 160.352 62.6868Z" fill="#E17E3B" />
          <mask
            id="mask1_1698_169247"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="158"
            y="62"
            width="6"
            height="8"
          >
            <path d="M160.352 62.6868C159.736 62.8183 159.223 63.1662 158.99 63.6736C158.638 64.4126 158.368 65.6822 159.092 67.7774C160.367 71.3987 162.799 67.7471 163.057 66.2996C163.435 63.995 162.602 62.2647 160.352 62.6868Z" fill="white" />
          </mask>
          <g mask="url(#mask1_1698_169247)">
            <path d="M161.023 63.3558C160.407 63.4873 159.894 63.8352 159.661 64.3426C159.31 65.0816 159.039 66.3512 159.763 68.4464C161.039 72.0677 163.47 68.4162 163.728 66.9687C164.104 64.6195 163.318 62.9307 161.023 63.3558Z" fill="#F8902C" />
          </g>
          <path d="M169.823 67.893C169.887 68.8269 169.503 69.7468 168.689 70.2494C168.001 70.6538 167 71.0352 165.354 71.1478C161.04 71.4429 163.898 67.4941 165.314 66.6377C167.544 65.2786 169.602 65.3166 169.823 67.893Z" fill="#E27A36" />
          <mask
            id="mask2_1698_169247"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="163"
            y="65"
            width="7"
            height="7"
          >
            <path d="M169.823 67.893C169.887 68.8269 169.503 69.7468 168.689 70.2494C168.001 70.6538 167 71.0352 165.354 71.1478C161.04 71.4429 163.898 67.4941 165.314 66.6377C167.544 65.2786 169.602 65.3166 169.823 67.893Z" fill="white" />
          </mask>
          <g mask="url(#mask2_1698_169247)">
            <path d="M170.317 68.5746C170.38 69.5086 169.997 70.4285 169.182 70.931C168.495 71.3355 167.493 71.7168 165.848 71.8294C161.534 72.1246 164.391 68.1758 165.807 67.3193C168.038 65.9603 170.051 66.0012 170.317 68.5746Z" fill="#F8902C" />
          </g>
        </g>
        <g clipPath="url(#clip2_1698_169247)">
          <path d="M53.7873 13.2405L57.7885 13.4327C58.2593 13.4769 58.7011 13.6609 59.0855 13.9502L63.417 17.0478C63.9809 17.449 64.3987 18.0581 64.5565 18.7378L66.0592 24.9891C66.2313 25.686 66.1322 26.4048 65.7908 27.006L63.6048 30.9007C63.3924 31.3082 63.0604 31.6411 62.6745 31.874L59.4839 33.8224L53.7873 13.2405Z" fill="url(#paint3_linear_1698_169247)" />
          <path d="M53.6253 13.2637L48.0972 15.325C47.5318 15.4461 47.0542 15.8128 46.7844 16.3257L44.2457 21.7862C44.0132 22.2392 43.9459 22.787 44.0729 23.2896L45.4492 28.8643C45.5762 29.3669 45.8973 29.8244 46.3273 30.1339L51.2835 34.3349C51.7733 34.6817 52.3637 34.8004 52.9463 34.6651L58.8753 34.0661C59.4408 33.945 59.9184 33.5783 60.1882 33.0654L63.3159 27.2328C63.5484 26.7798 63.6298 26.2492 63.4887 25.7294L61.7256 18.7096C61.5986 18.207 61.2775 17.7494 60.8475 17.4399L55.2881 13.5939C54.7841 13.23 54.1937 13.1112 53.6253 13.2637Z" fill="url(#paint4_linear_1698_169247)" />
          <path d="M53.1607 16.0217L49.0753 17.5537C48.6555 17.6409 48.3122 17.9253 48.1028 18.3014L46.2473 22.3854C46.0722 22.7331 46.0197 23.1239 46.1098 23.5123L47.1279 27.6761C47.2181 28.0645 47.4568 28.3876 47.7672 28.6226L51.4309 31.7525C51.7869 32.0075 52.2375 32.0973 52.643 31.9929L57.022 31.5491C57.4418 31.4619 57.7851 31.1775 57.9945 30.8014L60.3017 26.459C60.4768 26.1113 60.5293 25.7205 60.4391 25.3322L59.1275 20.0802C59.0373 19.6918 58.7985 19.3686 58.4882 19.1337L54.3728 16.2622C54.0139 16.0385 53.5804 15.9345 53.1607 16.0217Z" fill="#F08F3B" />
          <mask
            id="mask3_1698_169247"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="46"
            y="15"
            width="15"
            height="18"
          >
            <path d="M53.1607 16.0217L49.0753 17.5537C48.6555 17.6409 48.3122 17.9253 48.1028 18.3014L46.2473 22.3854C46.0722 22.7331 46.0197 23.1239 46.1098 23.5123L47.1279 27.6761C47.2181 28.0645 47.4568 28.3876 47.7672 28.6226L51.4309 31.7525C51.7869 32.0075 52.2375 32.0973 52.643 31.9929L57.022 31.5491C57.4418 31.4619 57.7851 31.1775 57.9945 30.8014L60.3017 26.459C60.4768 26.1113 60.5293 25.7205 60.4391 25.3322L59.1275 20.0802C59.0373 19.6918 58.7985 19.3686 58.4882 19.1337L54.3728 16.2622C54.0139 16.0385 53.5804 15.9345 53.1607 16.0217Z" fill="white" />
          </mask>
          <g mask="url(#mask3_1698_169247)">
            <path d="M54.2945 15.7483L50.209 17.2802C49.7893 17.3675 49.446 17.6519 49.2366 18.0279L47.3811 22.112C47.206 22.4596 47.1534 22.8505 47.2436 23.2388L48.2617 27.4027C48.3518 27.791 48.5906 28.1142 48.901 28.3491L52.5647 31.4791C52.9206 31.7341 53.3713 31.8239 53.7768 31.7195L58.1558 31.2757C58.5756 31.1884 58.9189 30.9041 59.1283 30.528L61.4355 26.1856C61.6106 25.8379 61.6631 25.4471 61.5729 25.0587L60.2613 19.8067C60.1711 19.4184 59.9323 19.0952 59.6219 18.8603L55.5066 15.9887C55.1506 15.7337 54.7142 15.6611 54.2945 15.7483Z" fill="#FFA42F" />
          </g>
          <path d="M55.7808 28.5833L54.2577 29.2951L54.2078 19.0327L56.7507 19.9362C56.9503 20.0025 57.1298 20.1143 57.272 20.2859C57.6162 20.6665 58.0903 21.5183 57.9655 23.1846C57.8766 24.3001 57.3292 26.085 56.7862 27.3163C56.6165 27.7752 56.1952 28.3847 55.7808 28.5833Z" fill="#F08F3B" />
          <path d="M49.0612 21.4149C50.8214 18.6832 55.0313 18.5243 56.3791 20.1863C58.2358 22.4976 56.0138 28.9692 54.1121 29.3289C52.5816 29.6127 47.3211 24.1009 49.0612 21.4149Z" fill="url(#paint5_linear_1698_169247)" />
          <path d="M50.5728 22.6807C50.361 22.9141 50.2545 23.2049 50.3079 23.479C50.381 23.8816 50.6418 24.4759 51.5474 25.115C53.1223 26.2126 53.0431 24.0206 52.7255 23.3576C52.2063 22.3116 51.3629 21.8526 50.5728 22.6807Z" fill="#E17E3B" />
          <mask
            id="mask4_1698_169247"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="50"
            y="22"
            width="3"
            height="4"
          >
            <path d="M50.5728 22.6807C50.361 22.9141 50.2545 23.2049 50.3079 23.479C50.381 23.8816 50.6418 24.4759 51.5474 25.115C53.1223 26.2126 53.0431 24.0206 52.7255 23.3576C52.2063 22.3116 51.3629 21.8526 50.5728 22.6807Z" fill="white" />
          </mask>
          <g mask="url(#mask4_1698_169247)">
            <path d="M51.0405 22.7561C50.8287 22.9895 50.7222 23.2803 50.7756 23.5544C50.8487 23.957 51.1095 24.5513 52.0151 25.1904C53.59 26.288 53.5108 24.0961 53.1932 23.4331C52.6598 22.3699 51.8478 21.9137 51.0405 22.7561Z" fill="#F8902C" />
          </g>
          <path d="M55.937 22.0288C56.2355 22.3892 56.3483 22.8747 56.1643 23.3165C56.0035 23.6813 55.7082 24.1285 55.0731 24.6546C53.4082 26.0338 53.4153 23.5967 53.74 22.8357C54.2487 21.6328 55.0951 21.0474 55.937 22.0288Z" fill="#E27A36" />
          <mask
            id="mask5_1698_169247"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="53"
            y="21"
            width="4"
            height="5"
          >
            <path d="M55.937 22.0288C56.2355 22.3892 56.3483 22.8747 56.1643 23.3165C56.0035 23.6813 55.7082 24.1285 55.0731 24.6546C53.4082 26.0338 53.4153 23.5967 53.74 22.8357C54.2487 21.6328 55.0951 21.0474 55.937 22.0288Z" fill="white" />
          </mask>
          <g mask="url(#mask5_1698_169247)">
            <path d="M56.3361 22.1611C56.6347 22.5215 56.7474 23.007 56.5635 23.4488C56.4026 23.8136 56.1074 24.2608 55.4723 24.7869C53.8074 26.1661 53.8145 23.729 54.1392 22.968C54.6478 21.7652 55.4771 21.194 56.3361 22.1611Z" fill="#F8902C" />
          </g>
        </g>
        <path d="M122.722 5.38869L138.618 10.4775C140.465 11.163 142.045 12.3797 143.28 13.9588L157.373 31.097C159.209 33.3198 160.234 36.2216 160.136 39.1273L159.443 65.9076C159.384 68.8977 158.209 71.684 156.187 73.7353L143.189 87.053C141.895 88.4639 140.2 89.4458 138.395 89.9669L123.453 94.3677L122.722 5.38869Z" fill="url(#paint6_linear_1698_169247)" />
        <path d="M122.045 5.30647L97.5731 7.64127C95.167 7.51875 92.8491 8.47965 91.2103 10.253L75.104 29.4917C73.6797 31.0645 72.8184 33.1964 72.7873 35.3563L72.3148 59.2768C72.2837 61.4366 73.0828 63.6244 74.4797 65.3337L89.8963 87.5863C91.4938 89.5101 93.7419 90.6246 96.2324 90.7084L120.741 94.6914C123.147 94.8139 125.465 93.853 127.104 92.0797L145.982 71.9784C147.406 70.4057 148.306 68.3581 148.299 66.1139L148.773 35.96C148.804 33.8002 148.005 31.6124 146.608 29.9031L128.381 8.42862C126.745 6.42042 124.497 5.30586 122.045 5.30647Z" fill="url(#paint7_linear_1698_169247)" />
        <path d="M117.201 15.9063L99.1063 17.6661C97.3228 17.5645 95.6345 18.3389 94.3861 19.6266L82.5139 34.0627C81.4343 35.2731 80.8015 36.7893 80.7456 38.4497L80.3526 56.3056C80.2967 57.966 80.9091 59.5242 81.905 60.8044L93.2748 77.3523C94.4324 78.7626 96.1493 79.6099 97.8941 79.6271L115.997 82.5634C117.78 82.6649 119.469 81.8906 120.717 80.6029L134.686 65.614C135.765 64.4037 136.398 62.8874 136.454 61.227L136.839 38.675C136.895 37.0146 136.282 35.4564 135.286 34.1762L121.82 18.1811C120.617 16.8939 118.984 16.0079 117.201 15.9063Z" fill="#F08F3B" />
        <mask
          id="mask6_1698_169247"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="80"
          y="15"
          width="57"
          height="68"
        >
          <path d="M117.201 15.9063L99.1063 17.6661C97.3228 17.5645 95.6345 18.3389 94.3861 19.6266L82.5139 34.0627C81.4343 35.2731 80.8015 36.7893 80.7456 38.4497L80.3526 56.3056C80.2967 57.966 80.9091 59.5242 81.905 60.8044L93.2748 77.3523C94.4324 78.7626 96.1493 79.6099 97.8941 79.6271L115.997 82.5634C117.78 82.6649 119.469 81.8906 120.717 80.6029L134.686 65.614C135.765 64.4037 136.398 62.8874 136.454 61.227L136.839 38.675C136.895 37.0146 136.282 35.4564 135.286 34.1762L121.82 18.1811C120.617 16.8939 118.984 16.0079 117.201 15.9063Z" fill="white" />
        </mask>
        <g mask="url(#mask6_1698_169247)">
          <path d="M122.058 16.0283L103.964 17.7882C102.18 17.6866 100.492 18.4609 99.2435 19.7487L87.3714 34.1848C86.2917 35.3951 85.6589 36.9114 85.603 38.5718L85.21 56.4277C85.1541 58.088 85.7666 59.6462 86.7624 60.9264L98.1322 77.4743C99.2898 78.8847 101.007 79.732 102.751 79.7492L120.854 82.6854C122.638 82.787 124.326 82.0127 125.575 80.7249L139.543 65.7361C140.623 64.5257 141.256 63.0094 141.311 61.349L141.696 38.797C141.752 37.1366 141.14 35.5785 140.144 34.2983L126.678 18.3031C125.52 16.8928 123.842 16.1299 122.058 16.0283Z" fill="#FFA42F" />
        </g>
        <path d="M114.199 69.2874L107.302 70.5098L118.168 29.153L127.428 35.5318C128.16 36.0136 128.762 36.6572 129.149 37.5013C130.124 39.4042 131.113 43.3437 128.814 49.9154C127.253 54.3093 123.125 60.9025 119.612 65.2724C118.434 66.9365 116.081 68.9352 114.199 69.2874Z" fill="#F08F3B" />
        <path d="M94.8854 33.1914C104.916 24.0956 122.03 27.996 125.662 36.1386C130.643 47.4432 114.721 71.0931 106.679 70.49C100.213 69.9816 84.9853 42.1252 94.8854 33.1914Z" fill="url(#paint8_linear_1698_169247)" />
        <path d="M99.6039 39.9145C98.4995 40.6254 97.7575 41.6809 97.6769 42.8417C97.5366 44.5408 97.9453 47.2142 100.901 50.7628C106.056 56.8788 108.101 47.9715 107.538 44.9605C106.576 40.1907 103.677 37.4336 99.6039 39.9145Z" fill="#E17E3B" />
        <mask
          id="mask7_1698_169247"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="97"
          y="38"
          width="11"
          height="15"
        >
          <path d="M99.6039 39.9145C98.4995 40.6254 97.7575 41.6809 97.6769 42.8417C97.5366 44.5408 97.9453 47.2142 100.901 50.7628C106.056 56.8788 108.101 47.9715 107.538 44.9605C106.576 40.1907 103.677 37.4336 99.6039 39.9145Z" fill="white" />
        </mask>
        <g mask="url(#mask7_1698_169247)">
          <path d="M101.405 40.7237C100.301 41.4346 99.5586 42.4901 99.4779 43.6509C99.3377 45.35 99.7464 48.0234 102.702 51.572C107.857 57.688 109.902 48.7807 109.339 45.7697C108.338 40.9155 105.562 38.204 101.405 40.7237Z" fill="#F8902C" />
        </g>
        <path d="M121.897 43.0765C122.71 44.8492 122.64 46.9246 121.423 48.5044C120.382 49.7991 118.712 51.2805 115.588 52.713C107.4 56.4684 110.057 46.6674 112.184 43.9548C115.529 39.6624 119.567 38.2191 121.897 43.0765Z" fill="#E27A36" />
        <mask
          id="mask8_1698_169247"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="110"
          y="40"
          width="13"
          height="14"
        >
          <path d="M121.897 43.0765C122.71 44.8492 122.64 46.9246 121.423 48.5044C120.382 49.7991 118.712 51.2805 115.588 52.713C107.4 56.4684 110.057 46.6674 112.184 43.9548C115.529 39.6624 119.567 38.2191 121.897 43.0765Z" fill="white" />
        </mask>
        <g mask="url(#mask8_1698_169247)">
          <path d="M123.36 44.0408C124.173 45.8135 124.104 47.889 122.887 49.4687C121.846 50.7635 120.175 52.2449 117.052 53.6774C108.864 57.4328 111.521 47.6318 113.648 44.9191C116.993 40.6267 120.946 39.2222 123.36 44.0408Z" fill="#F8902C" />
        </g>
        <rect x="92" y="76" width="47" height="24" rx="4" fill="url(#paint9_linear_1698_169247)" />
        <foreignObject x="0" y="76" width="100%" height="100%">
          <div className="text-[#874D04]">
            {multiplier || 0}
            x
          </div>
        </foreignObject>
      </g>
      <defs>
        <linearGradient id="paint0_linear_1698_169247" x1="171.536" y1="84.27" x2="183.424" y2="61.9029" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#E48818" />
          <stop offset="0.4383" stopColor="#E48A19" />
          <stop offset="0.5891" stopColor="#E6911E" />
          <stop offset="0.6966" stopColor="#E99C27" />
          <stop offset="0.783" stopColor="#ECAD34" />
          <stop offset="0.8296" stopColor="#EFBA3D" />
          <stop offset="0.843" stopColor="#EEB839" />
          <stop offset="0.8845" stopColor="#ECB632" />
          <stop offset="1" stopColor="#EBB530" />
        </linearGradient>
        <linearGradient id="paint1_linear_1698_169247" x1="147.026" y1="82.055" x2="177.755" y2="58.2913" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FFBF20" />
          <stop offset="0.4599" stopColor="#FABF36" />
          <stop offset="0.7859" stopColor="#FAD64D" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <linearGradient id="paint2_linear_1698_169247" x1="155.538" y1="67.7512" x2="172.553" y2="68.5539" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FACA4C" />
          <stop offset="0.3908" stopColor="#FACD4E" />
          <stop offset="0.7089" stopColor="#FAD855" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <linearGradient id="paint3_linear_1698_169247" x1="61.4135" y1="28.1765" x2="59.7093" y2="15.6267" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#E48818" />
          <stop offset="0.4383" stopColor="#E48A19" />
          <stop offset="0.5891" stopColor="#E6911E" />
          <stop offset="0.6966" stopColor="#E99C27" />
          <stop offset="0.783" stopColor="#ECAD34" />
          <stop offset="0.8296" stopColor="#EFBA3D" />
          <stop offset="0.843" stopColor="#EEB839" />
          <stop offset="0.8845" stopColor="#ECB632" />
          <stop offset="1" stopColor="#EBB530" />
        </linearGradient>
        <linearGradient id="paint4_linear_1698_169247" x1="50.8176" y1="34.4331" x2="56.3537" y2="15.8159" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FFBF20" />
          <stop offset="0.4599" stopColor="#FABF36" />
          <stop offset="0.7859" stopColor="#FAD64D" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <linearGradient id="paint5_linear_1698_169247" x1="50.0972" y1="26.1414" x2="57.2381" y2="21.5001" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FACA4C" />
          <stop offset="0.3908" stopColor="#FACD4E" />
          <stop offset="0.7089" stopColor="#FAD855" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <linearGradient id="paint6_linear_1698_169247" x1="137.308" y1="73.726" x2="143.983" y2="21.3789" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#E48818" />
          <stop offset="0.4383" stopColor="#E48A19" />
          <stop offset="0.5891" stopColor="#E6911E" />
          <stop offset="0.6966" stopColor="#E99C27" />
          <stop offset="0.783" stopColor="#ECAD34" />
          <stop offset="0.8296" stopColor="#EFBA3D" />
          <stop offset="0.843" stopColor="#EEB839" />
          <stop offset="0.8845" stopColor="#ECB632" />
          <stop offset="1" stopColor="#EBB530" />
        </linearGradient>
        <linearGradient id="paint7_linear_1698_169247" x1="87.9155" y1="87.4788" x2="130.274" y2="18.5206" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FFBF20" />
          <stop offset="0.4599" stopColor="#FABF36" />
          <stop offset="0.7859" stopColor="#FAD64D" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <linearGradient id="paint8_linear_1698_169247" x1="93.9577" y1="53.3315" x2="127.703" y2="42.3524" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FACA4C" />
          <stop offset="0.3908" stopColor="#FACD4E" />
          <stop offset="0.7089" stopColor="#FAD855" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <linearGradient id="paint9_linear_1698_169247" x1="115.5" y1="76" x2="115.5" y2="100" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD1A" />
          <stop offset="1" stopColor="#E8A512" />
        </linearGradient>
        <clipPath id="clip0_1698_169247">
          <rect width="230" height="100" fill="white" transform="translate(0.5)" />
        </clipPath>
        <clipPath id="clip1_1698_169247">
          <rect width="44" height="44" fill="white" transform="translate(153.208 41.6382) rotate(20.7234)" />
        </clipPath>
        <clipPath id="clip2_1698_169247">
          <rect width="22" height="22" fill="white" transform="translate(41.5278 16.2217) rotate(-15)" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default CoinsSvg;
