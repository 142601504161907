import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { formatNumbers, getNextLeagueCoins, numberWithCommas } from '../../helpers';
import EnergyInfo from './EnergyInfo';
import LeagueIcon from '../LeagueIcon';
import { ProfileResponseT } from '../../types';
import { SettingsResponse } from '../../store/reducers/settings/types';
import Pages from '../../Router/pages';

function Header() {
  const navigate = useNavigate();
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const goldToNextLeague: number = useMemo(() => getNextLeagueCoins((userSettings as SettingsResponse).leagues, (userData as ProfileResponseT).league.name), [userSettings, userData]);
  const handleLeagueClick = () => {
    navigate(Pages.leaderbord);
  };
  return (
    <div id="header" className="py-2 flex justify-between items-end pr-4 pl-4 text-white text-xs z-[3] relative">
      <div className="flex flex-col items-start gap-2" onClick={handleLeagueClick}>
        <div className="flex items-center gap-[0.45rem]">
          <div className="w-[20px] h-[20px]">
            <LeagueIcon league={(userData as ProfileResponseT)?.league.name} />
          </div>
          <div>
            <div className="flex">
              {userData?.league.name}
              <Arrow />
            </div>
            <div className="flex text-[10px]">
              {formatNumbers(userData?.total_earned_coins || 0, 2)}
              <span className="text-gray-2 mx-1">/</span>
              {formatNumbers(goldToNextLeague)}
              <Coin stroke="none" className="ml-2 w-3" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-[2.8rem]">
        <EnergyInfo />
        <div className="bg-[#FFD9001A] bg-opacity-10 rounded-10 p-2 h-[34px] font-bold flex gap-[0.3rem] items-center">
          <div className="tracking-wider">
            {numberWithCommas(userData?.coins || 0)}
          </div>
          <Coin />
        </div>
      </div>
    </div>
  );
}
export default Header;
