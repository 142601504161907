import { ReactComponent as CoinSVG } from '../../../assets/images/coin-size.svg';
import { Purchase, ShopItemT } from '../../../types';
import ShopItemButtons from '../ShopButtons';
import { getMinutesFromSeconds } from '../../../helpers';
import StoreItemBgImage from '../StoreItemBgImage';

type StoreItemProps = {
  item: ShopItemT
  onBuy: (item: ShopItemT) => void
  onSelectSkin: (item: ShopItemT) => void
  activeItemIds: number[]
  activeTemporaryItems: Purchase[]
}
function StoreItem({
  item, onBuy, onSelectSkin, activeItemIds, activeTemporaryItems,
}: StoreItemProps) {
  const nextLevel = [...item.levels || []].sort((level) => level.level).find((level) => !level.purchased_at);
  const disabledBusterButton = activeTemporaryItems.some((purchaserd) => purchaserd.expires_at && item.levels?.map((el) => el.id).includes(purchaserd.store_item_level.id));
  const levelData = nextLevel || item.levels?.[0];
  const [mainDescriptionText, listPart] = item.description.split(':');
  const listItems = (listPart || '')
    .replace(/[[\]]/g, '')
    .split(',')
    .map((str) => str.trim());
  return (
    <div className="bg-black-light-bg py-3 px-2 rounded-10 flex gap-4 mx-2">
      <div className={`bg-black-main rounded-10 p-2 ${StoreItemBgImage({ categoryName: item.category.name }).bg} bg-[length:140px] bg-no-repeat min-h-[130px] min-w-[90px] bg-centerCenter`}>
        <div className="w-full h-full flex flex-col relative">
          <img src={StoreItemBgImage({ categoryName: item.category.name }).icon} alt="icon" className="h-[70px] m-auto" />
          {item.category.name === 'Boosters'
          && (
          <>
            <div className="text-2xl flex text-center gap-[1px] mx-auto absolute top-[40%] left-[30%]">
              x
              {levelData?.effects[0].value}
            </div>
            <div className="text-sm flex text-center gap-[1px] mx-auto">
              {getMinutesFromSeconds(levelData?.duration_seconds || 0)}
              {' '}
              min
            </div>
          </>
          )}
          {item.category.name === 'Passive'
          && (
          <div className="text-sm flex text-center gap-[1px] mx-auto">
            2x
          </div>
          )}
        </div>
      </div>
      <div className="flex-col flex justify-between w-full">
        <div>
          <div className="text-sm">{item.name}</div>
          <div className="text-xs text-gray-2 mt-1">{mainDescriptionText}</div>
          {listItems[0].length
            ? (
              <ul>
                {listItems.map((lis) => (
                  <li key={lis}>
                    {'· '}
                    {lis}
                  </li>
                ))}
              </ul>
            )
            : null}
        </div>
        <div className="w-full">
          <div className="text-sm flex items-end gap-2">
            {item?.levels
                && (
                <>
                  <span className="text-xs text-gray-300">Price</span>
                  <span className="">{item?.levels[0].price}</span>
                </>
                )}
            <CoinSVG />
          </div>
          <ShopItemButtons onBuy={onBuy} item={item} onSelectSkin={() => onSelectSkin(item)} activeItemIds={activeItemIds} disabled={disabledBusterButton} />
        </div>
      </div>
    </div>
  );
}

export default StoreItem;
