import ProgressBar from '../../ProgressBar';
import { ReactComponent as Energy } from '../../../assets/images/energy-22x22.svg';
import { useEnergyContext } from '../../../providers/EnergyProvider';

function EnergyInfo() {
  const { energyRecoveryTimer, userEnergy: userEnergyContext } = useEnergyContext();
  const { maxEnergy, userEnergy } = userEnergyContext;
  const {
    shouldStartTimer,
  } = energyRecoveryTimer({});
  return (
    <div className="flex items-center gap-[0.4rem]">
      <Energy />
      <div>
        <div className="flex text-[10px] gap-1 pl-[3px]">
          <div>
            {Math.floor(userEnergy || 0)}
            <span className="text-gray-2 mx-1">/</span>
            {maxEnergy}
          </div>
          <div>
            {shouldStartTimer && <EnergyTimer />}
          </div>
        </div>
        <div className="w-fit m-auto">
          <ProgressBar
            size="small"
            goal={maxEnergy || 100}
            currentValue={userEnergy || 0}
            elementWidth="13px"
            type="energy"
            hideLine
          />
        </div>
      </div>
    </div>
  );
}

export default EnergyInfo;

function EnergyTimer() {
  const { energyRecoveryTimer } = useEnergyContext();
  const { countdown } = energyRecoveryTimer({});
  return (
    <div className="bg-white bg-opacity-15 rounded-5 px-1 mt-[0.1rem] leading-08 text-[10px] min-w-[32px]">
      {countdown.formatedMinutes}
      :
      {countdown.formatedSecond}
    </div>
  );
}
