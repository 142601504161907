import React, { useEffect, useState } from 'react';
import { ReactComponent as CoinSize } from '../../assets/images/coin-size.svg';
import { ReactComponent as Info } from '../../assets/svg/info.svg';

import { QuestT } from '../../types';
import TwitterIco from '../../assets/images/socials/twitter.png';
import TelegramIco from '../../assets/images/socials/telegram.png';
import YouTubeIco from '../../assets/images/socials/youtube.png';
import ProgressBar from '../ProgressBar';
import Button from '../Button';
import { getSecondsBetweenDates } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store';
import { finishQuest } from '../../store/reducers/quests/asyncActions';
import {
  CheckButton, ClaimedButton, CompleteTheTaskButton, ValidatingButton,
} from '../QuestButtons';

type QuestProps = {
    quest: QuestT
    onStart?: () => void
    onCheck: () => void
    onInfoClick: (quest: QuestT) => void
    isValid: boolean
    referralsInvited: number
}
function Quest({
  quest, onStart, onCheck, onInfoClick, isValid, referralsInvited,
}: QuestProps) {
  const dispatch = useAppDispatch();
  const isSocial = quest?.type === 'social';
  const isReferral = quest?.type === 'referral';
  const [validating, setValidating] = useState<boolean>(false);
  const { settings: appSettings } = useAppSelector(({ settings }) => settings);
  const [checkDisable, setCheckDisable] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCheckDisable(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [checkDisable]);

  const getQuestIco = () => {
    switch (quest.icon) {
      case 'telegram':
        return TelegramIco;
      case 'twitter':
        return TwitterIco;
      case 'youtube':
        return YouTubeIco;
      default:
        return TelegramIco;
    }
  };

  const getValidationTimeMilliseconds = () => {
    if (quest.progress?.started_at) {
      const validationTime = appSettings?.quest_fake_validation_timeout || (5 * 60);
      return (validationTime - getSecondsBetweenDates(new Date(quest.progress?.started_at), new Date())) * 1000;
    }
    return 0;
  };

  const onAutomaticCheck = async () => {
    const resp = await dispatch(finishQuest({ id: quest.id }));
    if (!resp?.error) {
      setValidating(false);
    }
  };
  const handleCheck = () => {
    if (quest.type === 'referral') {
      setCheckDisable(true);
    }
    if (quest.type === 'social' && quest.icon !== 'telegram') {
      const time = getValidationTimeMilliseconds();
      setValidating(true);
      if (time > 0) {
        setTimeout(() => {
          onAutomaticCheck();
        }, time);
      } else {
        setValidating(false);
        onCheck();
      }
    } else {
      onCheck();
    }
  };
  return (
    <div className={`bg-black-light-bg ${isSocial ? 'px-3 pt-5 pb-3' : 'px-3 py-2'} rounded-10 mt-2`}>
      <div className="flex justify-between">
        <div className="flex gap-3 items-center">
          {isSocial
          && (
          <div className="h-6 w-6">
            <img src={getQuestIco()} alt="social" className="h-full rounded object-contain" />
          </div>
          )}
          <div className="flex flex-col items-start gap-2 line-normal font-bold text-[14px]">
            <div>{quest?.name}</div>
            <div className="flex gap-1 items-center">
              <span className="text-xs text-gray-2 font-normal mr-1 line-normal">Reward</span>
              <span className="text-sm line-normal">
                {quest?.rewards}
              </span>
              <CoinSize width={18} />
            </div>
          </div>
        </div>
        {isReferral
        && (
        <div className="text-end min-w-[110px]">
          <span className="text-xs text-gray-2">Progress</span>
          <ProgressBar size="small" goal={quest.goal} currentValue={referralsInvited} />
          <span className="text-xs text-gray-2">
            {referralsInvited > quest.goal ? quest.goal : referralsInvited}
            /
            {quest.goal}
          </span>
        </div>
        )}
      </div>
      <div className={`flex gap-2 ${isSocial ? 'mt-[1.35rem]' : 'mt-4'}`}>
        {!isValid
        && (
        <div className="text-start text-xs min-w-[200px] text-red-error">
          The task was not completed.
          <br />
          {quest.type === 'referral' ? 'The goal was not achieved' : 'Try again in 2 minutes.'}
        </div>
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        {quest.progress?.completed_at
          ? (
            <ClaimedButton />
          )
        // eslint-disable-next-line no-nested-ternary
          : (quest.progress?.started_at || quest.type === 'referral')
            ? !validating
              ? (
                <CheckButton onClick={handleCheck} checkDisable={checkDisable} />
              )
              : (
                <ValidatingButton />
              )

            : (
              <CompleteTheTaskButton onClick={onStart} />
            )}
        <Button
          className="bg-gray-3 w-[36px] h-[36px] py-[9px] px-[9px] rounded-10 focus:outline-none transform active:scale-75 transition-transform"
          pureClass
          onClick={() => onInfoClick(quest)}
        >
          <Info />
        </Button>
      </div>
    </div>
  );
}

export default Quest;
