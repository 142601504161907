import { useEffect, useState } from 'react';

function BottomPart() {
  const [width, setWidth] = useState(388);
  useEffect(() => {
    const menu = document.getElementById('app-menu-list');
    if (menu) {
      const boundaries = menu.getBoundingClientRect();
      setWidth(boundaries.width + 20);
    }
  }, []);
  return (
    <svg
      // viewBox="-55 0 1590 342"
      viewBox="20 0 1390 342"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      // className="w-full"
      width={width}
      height={100}
    >
      <path d="M1386.23 29.517H1084.32C1074.65 19.8405 1064.86 10.0479 1059.46 4.64111H377.034C372.303 9.37496 362.005 19.678 352.171 29.517H50.2837C33.9786 45.8302 24.8403 54.973 8.53516 71.2862V311.622L50.2837 353.391H1386.23C1402.54 337.078 1411.68 327.935 1427.98 311.622V71.2862C1411.68 54.973 1402.54 45.8302 1386.23 29.517Z" fill="url(#paint0_radial_316_3508)" />
      <path d="M1386.23 29.517H1084.32C1074.65 19.8405 1064.86 10.0479 1059.46 4.64111H377.034C372.303 9.37496 362.005 19.678 352.171 29.517H50.2837L8.53516 71.2862V311.622C24.8403 327.935 33.9786 337.078 50.2837 353.391H1386.23C1402.54 337.078 1411.68 327.935 1427.98 311.622V71.2862L1386.23 29.517Z" fill="url(#paint1_radial_316_3508)" />
      <g filter="url(#filter0_d_316_3508)">
        <path d="M375.411 52.6757C375.411 52.6757 385.407 42.6511 400.228 27.8462H1036.24C1046.7 38.3117 1050.6 42.2102 1061.06 52.6757H375.411Z" fill="#5CDCFF" />
      </g>
      <g filter="url(#filter1_d_316_3508)">
        <path d="M1404.79 296.213C1395.65 304.66 1391.59 308.86 1370.21 330.255H66.2871C44.9027 308.86 53.09 317.609 31.7056 296.213" stroke="#6B2FDD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g filter="url(#filter2_d_316_3508)">
        <path d="M1104.69 52.6523H1370.21C1391.59 74.0475 1383.4 65.9025 1404.79 87.2976" stroke="#7C47FC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g filter="url(#filter3_d_316_3508)">
        <path d="M31.729 87.5065C53.1135 66.1113 44.9261 74.0475 66.3106 52.6523H331.459" stroke="#7C47FC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <filter id="filter0_d_316_3508" x="367.411" y="19.8462" width="701.647" height="40.8296" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.905882 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_316_3508" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_316_3508" result="shape" />
        </filter>
        <filter id="filter1_d_316_3508" x="22.2056" y="286.713" width="1392.08" height="53.042" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.584314 0 0 0 0 0 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_316_3508" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_316_3508" result="shape" />
        </filter>
        <filter id="filter2_d_316_3508" x="1095.19" y="43.1523" width="319.101" height="53.645" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.584314 0 0 0 0 0 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_316_3508" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_316_3508" result="shape" />
        </filter>
        <filter id="filter3_d_316_3508" x="22.229" y="43.1523" width="318.73" height="53.854" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.584314 0 0 0 0 0 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_316_3508" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_316_3508" result="shape" />
        </filter>
        <radialGradient id="paint0_radial_316_3508" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(616.763 125.706) scale(962.277 375.475)">
          <stop stopColor="#1E429C" />
          <stop offset="0.18" stopColor="#1C3F97" />
          <stop offset="0.39" stopColor="#183889" />
          <stop offset="0.61" stopColor="#112D72" />
          <stop offset="0.84" stopColor="#081D52" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <radialGradient id="paint1_radial_316_3508" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(742.24 -85.1729) scale(750.868 142.736)">
          <stop stopColor="#C4EBFF" />
          <stop offset="0.1" stopColor="#C2EAFE" stopOpacity="0.99" />
          <stop offset="0.19" stopColor="#BEE7FD" stopOpacity="0.95" />
          <stop offset="0.27" stopColor="#B6E4FB" stopOpacity="0.89" />
          <stop offset="0.34" stopColor="#ACDEF9" stopOpacity="0.8" />
          <stop offset="0.41" stopColor="#A1D9F7" stopOpacity="0.7" />
          <stop offset="0.44" stopColor="#98D5F6" stopOpacity="0.66" />
          <stop offset="0.83" stopColor="#3AB0ED" stopOpacity="0.19" />
          <stop offset="1" stopColor="#15A2EA" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
export default BottomPart;
