import React, {
  useEffect, useRef, useState,
} from 'react';
import { ReactComponent as AutopilotSvg } from '../../assets/images/autopilot.svg';
import { useAppDispatch, useAppSelector } from '../../store';
import { changeGame, getActiveSlotEffects, getSlotMachineSpinData } from '../../store/reducers/aviator/asyncActions';
import { AutopilotT, SlotMachineSpinResponse } from '../../types';
import { updateProfileCoins, updateProfileEnergy, updateUserData } from '../../store/reducers/profile/asyncActions';
import Machine from './Machine/Machine';
import eventEmitter, { emitGameEvent, GameEvents } from '../../pages/Home/eventEmiter';
import MultiplierText from './MultiplierText';
import { useEnergyContext } from '../../providers/EnergyProvider';
import Drawer from '../Drawer';
import AutopilotModal from './AutopilotModal';
import { getSecondsBetweenDates } from '../../helpers';
import { useCountDown } from '../../hooks/useCoundown';

function SlotMachineScreen() {
  const dispatch = useAppDispatch();
  const { userEnergy } = useEnergyContext();
  const reduxStateRef = useRef<SlotMachineSpinResponse | null>(null);
  const { autopilot } = useAppSelector(
    ({ shop }) => shop,
  );
  const { slotMachineSpin, earnedEffects } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  reduxStateRef.current = slotMachineSpin;
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [autopilotModalOpen, setAutopilotModalOpen] = useState<boolean>(false);
  let endGameTimeout: ReturnType<typeof setTimeout> | null = null;
  const spinTimeout: ReturnType<typeof setTimeout> | null = null;
  useEffect(() => () => {
    if (endGameTimeout) {
      clearTimeout(endGameTimeout);
    }
    if (spinTimeout) {
      clearTimeout(spinTimeout);
    }
  }, []);
  const endGame = (spinData: SlotMachineSpinResponse) => {
    const spinEffects = spinData?.effects;
    if (spinEffects?.length) {
      const spinEffect = spinEffects[0];
      const energy = (userEnergy.maxEnergy / 100) * spinEffect.amount; // calculate % of regenerated energy
      switch (spinEffect.name) {
        case 'regenerate_energy':
          eventEmitter.emit(GameEvents.OVERLAY_SHOW_ENERGY_ANIMATION);
          eventEmitter.emit(GameEvents.SLOT_HIDE_MIDDLE);
          dispatch(updateProfileEnergy({ energy, replace: true }));
          break;
        case 'reward_multiplier':
          eventEmitter.emit(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, spinEffect.amount);
          eventEmitter.emit(GameEvents.SLOT_HIDE_MIDDLE);
          break;
        case 'bonus':
          dispatch(updateProfileCoins({ coins: spinEffect.amount, replace: false }));
          break;
        case 'aviator':
          eventEmitter.emit(GameEvents.OVERLAY_GALAXY_TRANSITION);
          endGameTimeout = setTimeout(() => {
            dispatch(changeGame('aviator'));
          }, 1000 * 2.4);
          break;
        default:
          break;
      }
    }
    if (spinEffects?.[0]?.name !== 'aviator') {
      setIsSpinning(false);
    }
    dispatch(getActiveSlotEffects());
  };
  const spinReels = async () => {
    if (userData && userData?.energy >= 10) {
      setIsSpinning(true);
      const resp = await dispatch(getSlotMachineSpinData());
      if (resp?.payload) {
        dispatch(updateUserData((resp.payload as SlotMachineSpinResponse).user));
        emitGameEvent({ event: GameEvents.SLOT_START_SPIN, targetSlots: (resp.payload as SlotMachineSpinResponse).slots.map((slot) => slot.type) });
      }
    }
  };

  useEffect(() => {
    dispatch(getActiveSlotEffects());
    const handleStopEvent = () => {
      if (reduxStateRef.current) {
        endGame(reduxStateRef.current);
      }
    };
    eventEmitter.on(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    return () => {
      eventEmitter.off(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    };
  }, []);
  useEffect(() => {
    const startAnimation = () => {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 50);
    };
    eventEmitter.on(GameEvents.ENERGY_HIT_SCREEN, startAnimation);
    return () => {
      eventEmitter.off(GameEvents.ENERGY_HIT_SCREEN, startAnimation);
    };
  }, []);

  const rewardMultipler = earnedEffects?.find((el) => el.effect.name === 'reward_multiplier');
  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsAnimating(false);
    }, 500);
  }, [isAnimating]);
  useEffect(() => {
    dispatch(getActiveSlotEffects());
  }, []);
  return (
    <div className="bg-slotBg bg-cover absolute top-0 w-screen h-screen flex flex-col items-center justify-end">
      <div className="w-full h-fit fixed bottom-5">
        <MultiplierText rewardMultipler={rewardMultipler} />
        {/* <motion.div */}
        {/*  className="box" */}
        {/*  animate={{ y: isAnimating ? 2 : 0 }} */}
        {/*  transition={{ */}
        {/*    type: 'spring', */}
        {/*    stiffness: 1000, */}
        {/*    damping: 20, */}
        {/*  }} */}
        {/* > */}
        {/* <AnimatedBox isAnimating={isAnimating}> */}
        <div style={{
          position: 'relative',
          transform: 'none',
          // transform: isAnimating ? 'translateY(2px)' : 'none',
          // transition: 'transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
        }}
        >
          <Machine
            onSpin={() => {
              spinReels();
              setIsAnimating(true);
            }}
            isSpinning={isSpinning}
          />
        </div>
        {/* </AnimatedBox> */}
        {/* </motion.div> */}
      </div>
      {autopilot
      && (
      <div
        className="absolute top-10 left-2 p-1 rounded"
        style={{ background: 'rgba(140, 64, 243, 0.1)' }}
        onClick={() => setAutopilotModalOpen(true)}
      >
        <AutopilotSvg width={32} height={32} />
        <div className="relative">
          <AviatorTimer autopilot={autopilot} />
        </div>
      </div>
      )}
      <Drawer
        side="bottom"
        open={autopilotModalOpen}
        onClose={() => setAutopilotModalOpen(false)}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col overflow-y-scroll h-fit shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#4344C1] to-[#2D2EA0] z-[9] relative stroke-gray-10"
      >
        <AutopilotModal
          autopilot={autopilot}
          onClose={() => setAutopilotModalOpen(false)}
        />
      </Drawer>
    </div>
  );
}

export default SlotMachineScreen;

type AviatorTimerProps = {
  autopilot: AutopilotT | null
}
function AviatorTimer({ autopilot }: AviatorTimerProps) {
  const secondsFromStart = getSecondsBetweenDates(new Date(), new Date(autopilot?.end_at || ''));
  const {
    formatedSecond, formatedMinutes, isFinished,
  } = useCountDown(secondsFromStart, false);
  if (isFinished) {
    return (
      <div
        className="rounded-10 px-3 text-[10px] absolute left-[-5px] text-[#701E00]"
        style={{
          background: 'linear-gradient(180deg, #EFAF00 0%, #E47513 100%)',
        }}
      >
        Claim
      </div>
    );
  }
  return (
    <div className="bg-gradient-to-b from-[#6433A7] to-[#4C2088] rounded-10 px-3 text-[10px] absolute left-[-5px]">
      <span>
        {formatedMinutes}
      </span>
      :
      <span>
        {formatedSecond}
      </span>
    </div>
  );
}

type AnimatedBoxProps = {
  children: JSX.Element
  isAnimating: boolean
}
function AnimatedBox({ children, isAnimating }: AnimatedBoxProps) {
  return (
    <div
      className="box"
      style={{
        transform: isAnimating ? 'translateY(2px)' : 'none',
        transition: 'transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
      }}
    >
      {children}
    </div>
  );
}
