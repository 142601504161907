import React from 'react';
import Drawer from '../../../Components/Drawer';
import { ReactComponent as Coin } from '../../../assets/images/coin-size-26x26.svg';
import Button from '../../../Components/Button';
import { LeaguesSetting, SettingsResponse } from '../../../store/reducers/settings/types';
import LeagueIcon from '../../../Components/LeagueIcon';
import { numberWithCommas } from '../../../helpers';

type LeaguesModalProps = {
  leagues: SettingsResponse['leagues']
  open: boolean
  onClose: () => void
}
function LeaguesModal({
  leagues, onClose, open,
}: LeaguesModalProps) {
  return (
    <Drawer
      side="bottom"
      open={open}
      onClose={onClose}
      hideClose
      wrapperClassName="flex flex-col overflow-y-scroll h-screen shadow-xl bg-black-modal-bg z-[9] relative stroke-gray-10 text-start stroke-[none]"
    >
      <div className="mt-4 px-4">
        <div className="flex justify-between text-start">
          <div className="text-lg font-bold pt-1 ml-[-1px]"> About ranks </div>
          <div>
            <Button className="bg-black-light-bg px-3 py-2 rounded-10" pureClass onClick={onClose}>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5625 4.0625L14.4375 14.9375M14.4375 4.0625L3.5625 14.9375" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-4 stroke-[none]">
          {leagues.map((league) => <LeagueCard name={league.name} from={league.from} key={league.name} />)}
        </div>
      </div>
    </Drawer>
  );
}
export default LeaguesModal;

function LeagueCard({ name, from }: LeaguesSetting) {
  return (
    <div className="flex justify-between items-center bg-black-light-bg pr-4 pl-3 pt-[.95rem] pb-4 rounded-10 ">
      <div>
        <span className="text-lg">{name}</span>
        <div className="flex items-center gap-2 text-gray-2 text-sm">
          from
          {' '}
          {numberWithCommas(from)}
          <Coin width=".9rem" height=".9rem" />
        </div>
      </div>
      <div className="max-h-[3.1rem] max-w-[3.1rem] border-none">
        <LeagueIcon league={name} />
      </div>
    </div>
  );
}
