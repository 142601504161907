// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { axiosInstance } from '../../index';
import { TReferralsResponse } from './types';
// Types

export const getReferrals = createAsyncThunk<
TReferralsResponse
>(
  'referrals/all',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('/referrals');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
